import React, { useEffect, useRef, useState } from "react";
import ".//taxation.css";
import risk from "./image/risk.jpeg";
import risk1 from "./image/risk1.jpeg";
import risk2 from "./image/risk2.jpeg";
import bgImage from "../Home/Images/homepage2.jpeg";
import bgImage1 from "../Home/Images/homepage3.jpg";
import bgImage2 from "../Home/Images/homepage4.jpg";
import bgImage3 from "../Home/Images/homepage5.jpg";
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Query from "../Components/Query/Query";
const Taxation = () => {
  const [image, setImage] = useState("");
  const [count, setCount] = useState(0);
  const navigate = useNavigate();
  const scollToRef = useRef();
  useEffect(() => {
    scollToRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);

  const Imagesdata = [
    {
      images: bgImage,
      id: 1,
    },
    {
      images: bgImage1,
      id: 2,
    },
    {
      images: bgImage2,
      id: 3,
    },
    {
      images: bgImage3,
      id: 4,
    },
  ];
  useEffect(() => {
    setImage(Imagesdata[0].images);
  }, []);
  // Function is called everytime increment button is clicked
  const handleClick1 = () => {
    // Counter state is incremented
    if (count < 3) {
      setImage(Imagesdata[count + 1]?.images || bgImage);
      setCount(count + 1);
    } else {
      setCount(0);
      setImage(Imagesdata[0]?.images);
    }
  };

  // Function is called everytime decrement button is clicked
  const handleClick2 = () => {
    // Counter state is decremented
    if (count > 0) {
      setImage(Imagesdata[count - 1].images);
      setCount(count - 1);
    } else {
      setCount(3);
      setImage(Imagesdata[3].images);
    }
  };
  return (
    <>
      <section className="corporate-sec">
        <div
          className={`${"info"}`}
          style={{ backgroundImage: `url(${image})` }}
        >
          <h1 className="typo5">Risk Advisory Services</h1>
          <div className="rightIcon">
            <div className="rightIconImageBox" onClick={handleClick1}>
              <MdArrowForwardIos color="#ffffff" />
            </div>
          </div>
          <div className="leftIcon" onClick={handleClick2}>
            <div className="leftIconImageBox">
              <MdArrowBackIos color="#ffffff" />
            </div>
          </div>
        </div>
        <div className="corporate-sec-1  ">
          <div className="corporate-img">
            <div class="hexagon">
              <img src={risk} width="200" height="200" />
            </div>
            <div className="nextConatainer">
              <div class="hexagon1">
                <img src={risk1} width="200" height="200" />
              </div>
            </div>
            <div class="hexagon2">
              <img src={risk2} width="200" height="200" />
            </div>

            {/* <img src="./CorporateImage/img1.jpeg" alt="img" width={500} /> */}
          </div>
          <div className="info-paragraphs">
            <h3 ref={scollToRef} className="audit-header">
              RISK ASSURANCE
            </h3>
            <p className="text">
              Risk assurance services encompass a range of strategies,
              processes, and techniques aimed at identifying, assessing,
              mitigating, and monitoring risks that may impact an organization's
              objectives. PRISH aims to provide a range of services from
              proactively identifying process risks to mitigating and monitoring
              them by controls in place to outperform in the dynamic business
              environment.
            </p>
            <br />
            <h4
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/internal-audit")}
            >
              Internal Audit
            </h4>
            <p className="text">
              Internal auditing is a vital function within organizations that
              helps to enhance governance, risk management, and control
              processes.
            </p>
            <p className="text">
              Conducting an independent assessment, objective assurance and
              consulting activity designed to add value and improve an
              organization's operational and financial controls as per the best
              industry practices.
              <button
                onClick={() => navigate("/internal-audit")}
                className="read-more-btn-1"
              >
                Read more
              </button>
            </p>
            <br />
            <h4
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/standard-operating")}
            >
              Standard Operating Procedures (SOPs)
            </h4>
            <p className="text">
              This would include a comprehensive range of services including
              preparation and development of detailed process SOPs with process
              flowcharts and narratives with Delegation of Authority (DOA)
              Matrix, review of established SOPs, implementation of established
              SOPs by providing employee trainings with an objective of overall
              organizational effectiveness.
              <button
                onClick={() => navigate("/standard-operating")}
                className="read-more-btn-1"
              >
                Read more
              </button>
            </p>

            {/*  */}
            <h4
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/risk-control")}
            >
              Risk Control Matrix (RCM)
            </h4>
            <p className="text">
              Risk Control Matrix (RCM) is a valuable tool for organizations to
              systematically identify, assess, and manage risks, as well as
              evaluate the effectiveness of control measures in mitigating those
              risks. We will be providing a constant support system in
              developing, reviewing and monitoring RCM based on your business
              operations for different processes.
              <button
                onClick={() => navigate("/risk-control")}
                className="read-more-btn-1"
              >
                Read more
              </button>
            </p>
          </div>
        </div>
      </section>
      <Query />
    </>
  );
};

export default Taxation;
