import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import company from "./images/internal_audit1.jpeg";
import company1 from "./images/internal_audit2.jpeg";
import company2 from "./images/internal_audit3.jpeg";
import bgImage from "../Home/Images/homepage2.jpeg";
import bgImage1 from "../Home/Images/homepage3.jpg";
import bgImage2 from "../Home/Images/homepage4.jpg";
import bgImage3 from "../Home/Images/homepage5.jpg";
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md";
import Query from "../Components/Query/Query";

const InternalAudit = () => {
  const [image, setImage] = useState("");
  const [count, setCount] = useState(0);
  const scollToRef = useRef();
  useEffect(() => {
    scollToRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);
  const Imagesdata = [
    {
      images: bgImage,
      id: 1,
    },
    {
      images: bgImage1,
      id: 2,
    },
    {
      images: bgImage2,
      id: 3,
    },
    {
      images: bgImage3,
      id: 4,
    },
  ];
  useEffect(() => {
    setImage(Imagesdata[0].images);
  }, []);
  // Function is called everytime increment button is clicked
  const handleClick1 = () => {
    // Counter state is incremented
    if (count < 3) {
      setImage(Imagesdata[count + 1]?.images || bgImage);
      setCount(count + 1);
    } else {
      setCount(0);
      setImage(Imagesdata[0]?.images);
    }
  };

  // Function is called everytime decrement button is clicked
  const handleClick2 = () => {
    // Counter state is decremented
    if (count > 0) {
      setImage(Imagesdata[count - 1].images);
      setCount(count - 1);
    } else {
      setCount(3);
      setImage(Imagesdata[3].images);
    }
  };
  return (
    <>
      <section className="corporate-sec">
        <div
          className={`${"info"}`}
          style={{ backgroundImage: `url(${image})` }}
        >
          <h1 className="typo5">Internal Audit</h1>
          <div className="rightIcon">
            <div className="rightIconImageBox" onClick={handleClick1}>
              <MdArrowForwardIos color="#ffffff" />
            </div>
          </div>
          <div className="leftIcon" onClick={handleClick2}>
            <div className="leftIconImageBox">
              <MdArrowBackIos color="#ffffff" />
            </div>
          </div>
        </div>
        <div className="corporate-sec-1  ">
          <div className="corporate-img">
            <div class="hexagon">
              <img src={company} width="200" height="200" />
            </div>
            <div className="nextConatainer">
              <div class="hexagon1">
                <img src={company1} width="200" height="200" />
              </div>
            </div>
            <div class="hexagon2">
              <img src={company2} width="200" height="200" />
            </div>

            {/* <img src="./CorporateImage/img1.jpeg" alt="img" width={500} /> */}
          </div>
          <div className="info-paragraphs">
            <h3 ref={scollToRef} className="audit-header">
              Internal Audit
            </h3>
            <p className="text">
              Internal auditing is a vital function within organizations that
              helps to enhance governance, risk management, and control
              processes.Conducting an independent assessment, objective
              assurance, and consulting activity designed to add value and
              improve an organization's operational and financial controls as
              per the best industry practices.
            </p>
            <p className="text">
              Our experienced team of internal auditors works closely with
              clients to assess internal controls, identify areas for
              improvement, and provide valuable insights to support informed
              decision-making.
            </p>
            <p className="text">
              Our approach is to recognize that every organization is unique,
              with its own set of risks, challenges, and opportunities. Our
              approach to internal audit is tailored to meet the specific needs
              and objectives of each client. We focus on understanding your
              business, identifying key risks, and providing practical
              recommendations to enhance internal controls and processes.
            </p>
            <br />
            {/* <h4>
              Detailed accounting and management processes covered by us are as
              follows:
            </h4> */}
            <h3 className="submenu-header ">Why Choose PRISH ?</h3>
            <p className="submenu-sub-header ">
              Risk Assessment:{" "}
              <span className="text">
                We conduct comprehensive risk assessments to identify and
                prioritize key risks facing your organization.
              </span>
            </p>

            <p className="submenu-sub-header ">
              Internal Control Evaluation:{" "}
              <span className="text">
                Our auditors evaluate the design and effectiveness of internal
                controls to ensure they mitigate identified risks and support
                the achievement of organizational objectives.
              </span>{" "}
            </p>
            <p className="submenu-sub-header ">
              Process Improvement:{" "}
              <span className="text">
                We identify opportunities for process improvement and efficiency
                gains to help optimize your organization's operations.
              </span>
            </p>
            <p className="submenu-sub-header ">
              Compliance Assurance:{" "}
              <span className="text">
                Our internal audit services help ensure compliance with laws,
                regulations, and internal policies and procedures.
              </span>
            </p>
          </div>
        </div>
        <div className="accordian-section">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  What is an internal audit?
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  An internal audit is an independent, objective assurance and
                  consulting activity designed to add value and improve an
                  organization's operations. It helps organizations accomplish
                  their objectives by evaluating and improving the effectiveness
                  of risk management, control, and governance processes.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  Why is an internal audit necessary?
                </button>
              </h2>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  An internal audit is necessary to assure management and
                  stakeholders that risks are being managed effectively,
                  internal controls are adequate, and the organization is
                  operating efficiently and effectively.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  How does internal audit differ from external audit?
                </button>
              </h2>
              <div
                id="collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Internal audit is conducted by employees or outsourced to a
                  third-party firm hired by the organization. It focuses on
                  evaluating internal controls and processes to identify areas
                  for improvement. External audit, on the other hand, is
                  conducted by independent auditors to express an opinion on the
                  fairness and accuracy of the financial statements.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsefour"
                  aria-expanded="false"
                  aria-controls="collapsefour"
                >
                  What are the key objectives of internal audit?
                </button>
              </h2>
              <div
                id="collapsefour"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  The primary objectives of the internal audit include assessing
                  the effectiveness of risk management, control, and governance
                  processes, providing assurance to management and stakeholders,
                  and identifying opportunities for improvement.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsefive"
                  aria-expanded="false"
                  aria-controls="collapsefive"
                >
                  What are the benefits of internal audit?
                </button>
              </h2>
              <div
                id="collapsefive"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <p className="submenu-sub-header ">
                    Risk Management:
                    <span className="text">
                      {" "}
                      Identify and mitigate risks that could impact the
                      achievement of organizational objectives.
                    </span>
                  </p>{" "}
                  <p className="submenu-sub-header ">
                    Operational Efficiency:
                    <span className="text">
                      {" "}
                      Streamline processes and procedures to improve efficiency
                      and reduce costs.
                    </span>
                  </p>{" "}
                  <p className="submenu-sub-header ">
                    Compliance Assurance:
                    <span className="text">
                      {" "}
                      Ensure compliance with regulatory requirements and
                      internal policies.
                    </span>
                  </p>{" "}
                  <p className="submenu-sub-header ">
                    Strategic Insights:
                    <span className="text">
                      {" "}
                      Provide valuable insights to support informed
                      decision-making and strategic planning.
                    </span>
                  </p>{" "}
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSix"
                  aria-expanded="false"
                  aria-controls="collapseSix"
                >
                  How can internal audit help my organization?
                </button>
              </h2>
              <div
                id="collapseSix"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Internal audit helps organizations by providing independent
                  assurance that risks are being managed effectively, controls
                  are in place and working as intended, and operations are
                  efficient and effective. It also provides valuable insights
                  and recommendations for improvement.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSeven"
                  aria-expanded="false"
                  aria-controls="collapseSeven"
                >
                  How often should internal audit be conducted?
                </button>
              </h2>
              <div
                id="collapseSeven"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  The frequency of internal audit depends on factors such as the
                  size and complexity of the organization, the industry, and
                  regulatory requirements. It is typically conducted annually or
                  quarterly, but some organizations may choose to conduct audits
                  more frequently.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseEight"
                  aria-expanded="false"
                  aria-controls="collapseEight"
                >
                  What areas does internal audit cover?
                </button>
              </h2>
              <div
                id="collapseEight"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Internal audit covers a wide range of areas, including
                  financial reporting, operational processes, compliance with
                  laws and regulations, information technology, risk management,
                  and governance.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseNine"
                  aria-expanded="false"
                  aria-controls="collapseNine"
                >
                  Can internal audit help with risk management?
                </button>
              </h2>
              <div
                id="collapseNine"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Yes, internal audit plays a crucial role in risk management by
                  identifying and assessing risks, evaluating the effectiveness
                  of controls to mitigate those risks, and providing
                  recommendations to strengthen controls and reduce risk
                  exposure.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Query />
    </>
  );
};

export default InternalAudit;
