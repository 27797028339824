import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import company from "./images/strategic_planning1.jpeg";
import company1 from "./images/strategic_planning2.jpeg";
import company2 from "./images/strategic_planning4.jpeg";
import bgImage from "../Home/Images/homepage2.jpeg";
import bgImage1 from "../Home/Images/homepage3.jpg";
import bgImage2 from "../Home/Images/homepage4.jpg";
import bgImage3 from "../Home/Images/homepage5.jpg";
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md";
import Query from "../Components/Query/Query";

const StrategicPlanning = () => {
  const [image, setImage] = useState("");
  const [count, setCount] = useState(0);
  const scollToRef = useRef();
  useEffect(() => {
    scollToRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);
  const Imagesdata = [
    {
      images: bgImage,
      id: 1,
    },
    {
      images: bgImage1,
      id: 2,
    },
    {
      images: bgImage2,
      id: 3,
    },
    {
      images: bgImage3,
      id: 4,
    },
  ];
  useEffect(() => {
    setImage(Imagesdata[0].images);
  }, []);
  // Function is called everytime increment button is clicked
  const handleClick1 = () => {
    // Counter state is incremented
    if (count < 3) {
      setImage(Imagesdata[count + 1]?.images || bgImage);
      setCount(count + 1);
    } else {
      setCount(0);
      setImage(Imagesdata[0]?.images);
    }
  };

  // Function is called everytime decrement button is clicked
  const handleClick2 = () => {
    // Counter state is decremented
    if (count > 0) {
      setImage(Imagesdata[count - 1].images);
      setCount(count - 1);
    } else {
      setCount(3);
      setImage(Imagesdata[3].images);
    }
  };
  return (
    <>
      <section className="corporate-sec">
        <div
          className={`${"info"}`}
          style={{ backgroundImage: `url(${image})` }}
        >
          <h1 className="typo5">Stratagic Planning</h1>
          <div className="rightIcon">
            <div className="rightIconImageBox" onClick={handleClick1}>
              <MdArrowForwardIos color="#ffffff" />
            </div>
          </div>
          <div className="leftIcon" onClick={handleClick2}>
            <div className="leftIconImageBox">
              <MdArrowBackIos color="#ffffff" />
            </div>
          </div>
        </div>
        <div className="corporate-sec-1  ">
          <div className="corporate-img">
            <div class="hexagon">
              <img src={company} width="200" height="200" />
            </div>
            <div className="nextConatainer">
              <div class="hexagon1">
                <img src={company1} width="200" height="200" />
              </div>
            </div>
            <div class="hexagon2">
              <img src={company2} width="200" height="200" />
            </div>

            {/* <img src="./CorporateImage/img1.jpeg" alt="img" width={500} /> */}
          </div>
          <div className="info-paragraphs">
            <h3 ref={scollToRef} className="audit-header">
              Stratagic Planning
            </h3>
            <p className="text">
              Developing strategic plans for businesses to achieve long-term
              growth and competitiveness, identifying opportunities for
              expansion, diversification, or market entry, and implementing
              organizational changes to improve efficiency and effectiveness. It
              involves analysing the current state, setting goals, and
              developing strategies to achieve those goals effectively.
            </p>
            <p className="text">
              Our experienced team collaborates closely with clients to develop
              customized strategies that align with their vision, objectives,
              and market dynamics.
            </p>
            <p className="text">
              Our approach is to understand that strategic planning is critical
              for businesses to thrive in today's competitive environment which
              includes collaborative, data-driven, and focused on delivering
              tangible results. We work closely with clients to understand their
              unique challenges, opportunities, and aspirations, and develop
              practical strategies that drive growth and profitability.
            </p>

            <br />

            <h3 className="submenu-header ">Why Choose PRISH ?</h3>
            <p className="submenu-sub-header ">
              Vision and Mission Development:{" "}
              <span className="text">
                We help businesses articulate their vision and mission, defining
                their purpose and guiding principles.
              </span>
            </p>

            <p className="submenu-sub-header ">
              Market Analysis:
              <span className="text">
                We conduct thorough market analysis to identify trends,
                opportunities, and potential threats, helping businesses make
                informed strategic decisions.
              </span>{" "}
            </p>
            <p className="submenu-sub-header ">
              SWOT Analysis:{" "}
              <span className="text">
                We conduct a SWOT (Strengths, Weaknesses, Opportunities,
                Threats) analysis to assess internal capabilities and external
                factors affecting the business.
              </span>
            </p>
            <p className="submenu-sub-header ">
              Goal Setting:{" "}
              <span className="text">
                We work with clients to set clear, achievable goals and
                objectives that align with their vision and mission.
              </span>
            </p>

            <p className="submenu-sub-header ">
              Strategy Development:{" "}
              <span className="text">
                Based on the analysis and goal setting, we develop tailored
                strategies and action plans to achieve desired outcomes.
              </span>
            </p>
          </div>
        </div>
        <div className="accordian-section">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  What is strategic planning?
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Strategic planning is the process of defining an
                  organization's direction and making decisions on allocating
                  resources to pursue its objectives effectively.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  Why is strategic planning important for businesses?
                </button>
              </h2>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Strategic planning is important for businesses because it
                  provides a roadmap for achieving long-term goals, helps in
                  making informed decisions, and ensures alignment across the
                  organization.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  What are the key components of strategic planning?
                </button>
              </h2>
              <div
                id="collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Key components of strategic planning include defining the
                  vision and mission, conducting market analysis, setting goals
                  and objectives, developing strategies, and creating action
                  plans.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsefour"
                  aria-expanded="false"
                  aria-controls="collapsefour"
                >
                  What are the benefits of strategic planning for businesses?{" "}
                </button>
              </h2>
              <div
                id="collapsefour"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  The benefits of strategic planning for businesses include:
                  <ul>
                    <li>Clear direction and focus on long-term goals</li>
                    <li>Improved decision-making based on data and analysis</li>
                    <li>
                      Resource optimization and efficient allocation of
                      resources
                    </li>
                    <li>Adaptability to changing market conditions</li>
                    <li>Enhanced performance and profitability</li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsefive"
                  aria-expanded="false"
                  aria-controls="collapsefive"
                >
                  How often should strategic planning be reviewed and updated?
                </button>
              </h2>
              <div
                id="collapsefive"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Strategic planning should be reviewed and updated regularly to
                  ensure that it remains relevant and effective in guiding the
                  organization toward its goals. This could be annually or more
                  frequently depending on the business environment.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSix"
                  aria-expanded="false"
                  aria-controls="collapseSix"
                >
                  Can strategic planning help in overcoming challenges and
                  seizing opportunities?
                </button>
              </h2>
              <div
                id="collapseSix"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Yes, strategic planning helps businesses anticipate and
                  overcome challenges by providing a structured approach to
                  problem-solving and risk management. It also enables
                  businesses to identify and capitalize on opportunities for
                  growth and innovation.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSeven"
                  aria-expanded="false"
                  aria-controls="collapseSeven"
                >
                  Is strategic planning only for large businesses?
                </button>
              </h2>
              <div
                id="collapseSeven"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  No, strategic planning is beneficial for businesses of all
                  sizes. It helps small and medium-sized enterprises (SMEs) to
                  compete effectively, adapt to market changes, and achieve
                  sustainable growth.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseEight"
                  aria-expanded="false"
                  aria-controls="collapseEight"
                >
                  How does strategic planning contribute to organizational
                  alignment?{" "}
                </button>
              </h2>
              <div
                id="collapseEight"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Strategic planning ensures that all stakeholders in the
                  organization are aligned with the same goals and objectives,
                  promoting teamwork, collaboration, and a shared sense of
                  purpose.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseEight"
                  aria-expanded="false"
                  aria-controls="collapseEight"
                >
                  How can strategic planning improve the overall performance of
                  a business?
                </button>
              </h2>
              <div
                id="collapseEight"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Strategic planning improves the overall performance of a
                  business by providing a clear roadmap for success, ensuring
                  efficient resource allocation, fostering innovation and
                  adaptability, and driving continuous improvement.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Query />
    </>
  );
};

export default StrategicPlanning;
