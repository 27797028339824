import React, { useEffect, useState } from "react";
import "../Blog/blog.css";
import bgImage from "../Home/Images/homepage2.jpeg";
import bgImage1 from "../Home/Images/homepage3.jpg";
import bgImage2 from "../Home/Images/homepage4.jpg";
import bgImage3 from "../Home/Images/homepage5.jpg";
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md";

const Blog = () => {
  const [image, setImage] = useState("");
  const [count, setCount] = useState(0);

  const Imagesdata = [
    {
      images: bgImage,
      id: 1,
    },
    {
      images: bgImage1,
      id: 2,
    },
    {
      images: bgImage2,
      id: 3,
    },
    {
      images: bgImage3,
      id: 4,
    },
  ];
  useEffect(() => {
    setImage(Imagesdata[0].images);
  }, []);
  // Function is called everytime increment button is clicked
  const handleClick1 = () => {
    // Counter state is incremented
    if (count < 3) {
      setImage(Imagesdata[count + 1]?.images || bgImage);
      setCount(count + 1);
    } else {
      setCount(0);
      setImage(Imagesdata[0]?.images);
    }
  };

  // Function is called everytime decrement button is clicked
  const handleClick2 = () => {
    // Counter state is decremented
    if (count > 0) {
      setImage(Imagesdata[count - 1].images);
      setCount(count - 1);
    } else {
      setCount(3);
      setImage(Imagesdata[3].images);
    }
  };
  return (
    <>
      <div className={`${"info"}`} style={{ backgroundImage: `url(${image})` }}>
        <h1 className="typo">Blog</h1>
        <div className="rightIcon">
          <div className="rightIconImageBox" onClick={handleClick1}>
            <MdArrowForwardIos color="#ffffff" />
          </div>
        </div>
        <div className="leftIcon" onClick={handleClick2}>
          <div className="leftIconImageBox">
            <MdArrowBackIos color="#ffffff" />
          </div>
        </div>
      </div>
      <div>
        <h2>blog page</h2>
      </div>
    </>
  );
};

export default Blog;
