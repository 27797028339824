import React, { useEffect, useRef, useState } from "react";
import ".//corporate.css";
import audit from "./Images/system_audit1.jpeg";
import audit1 from "./Images/system_audit2.jpeg";
import audit3 from "./Images/system_audit3.jpeg";

import bgImage from "../Home/Images/homepage2.jpeg";
import bgImage1 from "../Home/Images/homepage3.jpg";
import bgImage2 from "../Home/Images/homepage4.jpg";
import bgImage3 from "../Home/Images/homepage5.jpg";
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Query from "../Components/Query/Query";

function SpecialAuditService() {
  const [image, setImage] = useState("");
  const [count, setCount] = useState(0);
  const scollToRef = useRef();
  useEffect(() => {
    scollToRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);
  const navigate = useNavigate();

  const Imagesdata = [
    {
      images: bgImage,
      id: 1,
    },
    {
      images: bgImage1,
      id: 2,
    },
    {
      images: bgImage2,
      id: 3,
    },
    {
      images: bgImage3,
      id: 4,
    },
  ];
  useEffect(() => {
    setImage(Imagesdata[0].images);
  }, []);
  // Function is called everytime increment button is clicked
  const handleClick1 = () => {
    // Counter state is incremented
    if (count < 3) {
      setImage(Imagesdata[count + 1]?.images || bgImage);
      setCount(count + 1);
    } else {
      setCount(0);
      setImage(Imagesdata[0]?.images);
    }
  };

  // Function is called everytime decrement button is clicked
  const handleClick2 = () => {
    // Counter state is decremented
    if (count > 0) {
      setImage(Imagesdata[count - 1].images);
      setCount(count - 1);
    } else {
      setCount(3);
      setImage(Imagesdata[3].images);
    }
  };
  return (
    <>
      <section className="corporate-sec">
        <div
          className={`${"info"}`}
          style={{ backgroundImage: `url(${image})` }}
        >
          <h1 className="typo5">Taxation Services</h1>
          <div className="rightIcon">
            <div className="rightIconImageBox" onClick={handleClick1}>
              <MdArrowForwardIos color="#ffffff" />
            </div>
          </div>
          <div className="leftIcon" onClick={handleClick2}>
            <div className="leftIconImageBox">
              <MdArrowBackIos color="#ffffff" />
            </div>
          </div>
        </div>
        <div className="corporate-sec-1  ">
          <div className="corporate-img">
            <div class="hexagon">
              <img src={audit3} width="200" height="200" />
            </div>
            <div className="nextConatainer">
              <div class="hexagon1">
                <img src={audit} width="200" height="200" />
              </div>
            </div>
            <div class="hexagon2">
              <img src={audit1} width="200" height="200" />
            </div>

            {/* <img src="./CorporateImage/img1.jpeg" alt="img" width={500} /> */}
          </div>
          <div className="info-paragraphs">
            <h3 ref={scollToRef} className="audit-header">
              Taxation Services
            </h3>
            <p className="text">
              Special audits referred to as an extraordinary audit, is a type of
              audit that is conducted in response to specific circumstances or
              events that warrant closer examination beyond the scope of regular
              audits. It typically involves a detailed examination of specific
              financial records, transactions, systems or procedures within an
              organization. Unlike regular audits, which are routine and cover
              broader aspects of financial reporting, special audits are
              targeted and focused on particular areas of concern or interest.
            </p>

            <br />
            <h4
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/gold-audit")}
            >
              Gold Audit
            </h4>
            <p className="text">
              "Gold Audit" typically refers to the process of auditing gold and
              jewelry traders or dealers to ensure compliance with relevant
              regulations and standards due to the significant role of the gold
              and jewelry industry in the country's economy.
              <button
                onClick={() => navigate("/gold-audit")}
                className="read-more-btn-1"
              >
                Read more
              </button>
            </p>
            <br />
            <h4
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/system-tax-audit")}
            >
              System Audit
            </h4>
            <p className="text">
              System audits are critical for identifying IT-related risks,
              enhancing security posture, improving operational efficiency, and
              ensuring compliance with regulatory requirements and industry best
              practices through mitigating IT risks, strengthening IT governance
              frameworks and safeguarding IT assets and data.
              <button
                onClick={() => navigate("/system-tax-audit")}
                className="read-more-btn-1"
              >
                Read more
              </button>
            </p>

            {/*  */}
            <h4
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/transaction-audit")}
            >
              Transaction Audit
            </h4>
            <p className="text">
              Transaction Audits will involve the examination and review of
              specific financial transactions within an organization to ensure
              accuracy, compliance, and integrity. In this, we will focus on
              verifying the completeness, validity, and appropriateness of
              individual transactions recorded in the organization's financial
              records.
              <button
                onClick={() => navigate("/transaction-audit")}
                className="read-more-btn-1"
              >
                Read more
              </button>
            </p>

            <h4
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/rera-audit")}
            >
              RERA Audit
            </h4>
            <p className="text">
              Real Estate Regulatory Authority (RERA) is responsible for
              regulating the real estate sector in UAE, especially in Dubai.
              RERA audits are conducted to ensure compliance with regulations,
              transparency and fairness in real estate transactions by promoting
              confidence and trust among investors, buyers and other
              stakeholders.
              <button
                onClick={() => navigate("/rera-audit")}
                className="read-more-btn-1"
              >
                Read more
              </button>
            </p>

            <h4
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/sales-tax-audit")}
            >
              Sales Tax Certification Audits
            </h4>
            <p className="text">
              Sales Certification refers to a process whereby companies obtain
              accreditation to engage in sales activities within specific
              industries. Obtaining sales certification demonstrates competence,
              professionalism, and compliance with regulatory standards,
              enhancing the credibility and trustworthiness of the
              organization's activities.
              <button
                onClick={() => navigate("/sales-tax-audit")}
                className="read-more-btn-1"
              >
                Read more
              </button>
            </p>
          </div>
        </div>
      </section>
      <Query />
    </>
  );
}

export default SpecialAuditService;
