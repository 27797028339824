import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import company from "./images/due_delignece1.jpeg";
import company1 from "./images/due_delignece2.jpeg";
import company2 from "./images/due_delignece3.jpeg";
import bgImage from "../Home/Images/homepage2.jpeg";
import bgImage1 from "../Home/Images/homepage3.jpg";
import bgImage2 from "../Home/Images/homepage4.jpg";
import bgImage3 from "../Home/Images/homepage5.jpg";
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md";
import Query from "../Components/Query/Query";

const DueDiligence = () => {
  const [image, setImage] = useState("");
  const [count, setCount] = useState(0);
  const scollToRef = useRef();
  useEffect(() => {
    scollToRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);
  const Imagesdata = [
    {
      images: bgImage,
      id: 1,
    },
    {
      images: bgImage1,
      id: 2,
    },
    {
      images: bgImage2,
      id: 3,
    },
    {
      images: bgImage3,
      id: 4,
    },
  ];
  useEffect(() => {
    setImage(Imagesdata[0].images);
  }, []);
  // Function is called everytime increment button is clicked
  const handleClick1 = () => {
    // Counter state is incremented
    if (count < 3) {
      setImage(Imagesdata[count + 1]?.images || bgImage);
      setCount(count + 1);
    } else {
      setCount(0);
      setImage(Imagesdata[0]?.images);
    }
  };

  // Function is called everytime decrement button is clicked
  const handleClick2 = () => {
    // Counter state is decremented
    if (count > 0) {
      setImage(Imagesdata[count - 1].images);
      setCount(count - 1);
    } else {
      setCount(3);
      setImage(Imagesdata[3].images);
    }
  };
  return (
    <>
      <section className="corporate-sec">
        <div
          className={`${"info"}`}
          style={{ backgroundImage: `url(${image})` }}
        >
          <h1 className="typo5">Due Diligence</h1>
          <div className="rightIcon">
            <div className="rightIconImageBox" onClick={handleClick1}>
              <MdArrowForwardIos color="#ffffff" />
            </div>
          </div>
          <div className="leftIcon" onClick={handleClick2}>
            <div className="leftIconImageBox">
              <MdArrowBackIos color="#ffffff" />
            </div>
          </div>
        </div>
        <div className="corporate-sec-1  ">
          <div className="corporate-img">
            <div class="hexagon">
              <img src={company} width="200" height="200" />
            </div>
            <div className="nextConatainer">
              <div class="hexagon1">
                <img src={company1} width="200" height="200" />
              </div>
            </div>
            <div class="hexagon2">
              <img src={company2} width="200" height="200" />
            </div>

            {/* <img src="./CorporateImage/img1.jpeg" alt="img" width={500} /> */}
          </div>
          <div className="info-paragraphs">
            <h3 ref={scollToRef} className="audit-header">
              Due Diligence
            </h3>
            <p className="text">
              Financial, tax, and legal due diligence services are essential
              components of the mergers and acquisitions (M&A) process,
              providing valuable insights and risk assessments to potential
              buyers or investors. Thus, businesses are inclined towards
              engaging experienced professionals and we at PRISH stand beyond
              our client'sexpectations in conducting thorough due diligence and
              achieving successful outcomes in M&A transactions. It helps
              businesses mitigate risks, make informed decisions, and ensure
              compliance with regulations.
            </p>
            <p className="text">
              Our experienced team conducts thorough investigations and
              assessments to provide valuable insights and recommendations
              tailored to our client's specific needs.
            </p>
            <p className="text">
              Our approach is to understand the importance of due diligence in
              protecting our clients' interests and ensuring the success of
              their business endeavors which is detail-oriented,
              compliance-focused, and designed to deliver high-quality due
              diligence reports that provide actionable insights.
            </p>
            <br />
            {/* <h4>
              Detailed accounting and management processes covered by us are as
              follows:
            </h4> */}
            <h3 className="submenu-header ">Why Choose PRISH ?</h3>
            <p className="submenu-sub-header ">
              Financial Due Diligence:{" "}
              <span className="text">
                We assess the financial health, performance, and risks of a
                business by reviewing financial statements, cash flow
                projections, and other relevant financial data.
              </span>
            </p>

            <p className="submenu-sub-header ">
              Legal Due Diligence:{" "}
              <span className="text">
                We review legal documents, contracts, and obligations to
                identify any legal risks or liabilities, including litigation,
                regulatory compliance, and contractual obligations.
              </span>{" "}
            </p>
            <p className="submenu-sub-header ">
              Commercial Due Diligence:{" "}
              <span className="text">
                We evaluate market dynamics, competitors, and growth
                opportunities to assess the commercial viability and potential
                risks of a business or investment opportunity.
              </span>
            </p>
            <p className="submenu-sub-header ">
              Vendor Due Diligence:{" "}
              <span className="text">
                We assess suppliers or vendors to ensure reliability, quality,
                and compliance with contractual obligations, regulatory
                requirements, and industry standards.
              </span>
            </p>

            <p className="submenu-sub-header ">
              Customer Due Diligence:{" "}
              <span className="text">
                We verify the identity and background of customers to prevent
                fraud, comply with regulations such as anti-money laundering
                (AML) laws, and assess creditworthiness.{" "}
              </span>
            </p>
          </div>
        </div>
        <div className="accordian-section">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  What is due diligence, and why is it important for businesses?
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Due diligence is the process of investigating and assessing
                  the background, financial status, legal standing, and other
                  relevant factors of a business, individual, or entity before
                  entering a transaction or business relationship. It is
                  important for businesses to mitigate risks, make informed
                  decisions, and ensure compliance with regulations.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  What types of due diligence services does PRISH Consultancy
                  offer?
                </button>
              </h2>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  PRISH Consultancy offers a range of due diligence services,
                  including financial due diligence, legal due diligence,
                  commercial due diligence, vendor due diligence, and customer
                  due diligence.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  When should businesses conduct due diligence?
                </button>
              </h2>
              <div
                id="collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Due diligence should be conducted before entering into any
                  significant business transaction, such as mergers and
                  acquisitions, partnerships, investments, contracts, or hiring
                  key personnel. It is also essential for ongoing monitoring and
                  compliance purposes.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsefour"
                  aria-expanded="false"
                  aria-controls="collapsefour"
                >
                  How does PRISH Consultancy perform due diligence?
                </button>
              </h2>
              <div
                id="collapsefour"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  PRISH Consultancy performs due diligence through a systematic
                  and thorough process that includes gathering and analyzing
                  relevant information, conducting interviews and site visits,
                  reviewing documentation, and providing comprehensive reports
                  with findings and recommendations.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsefive"
                  aria-expanded="false"
                  aria-controls="collapsefive"
                >
                  What are the benefits of outsourcing due diligence services to
                  PRISH Consultancy?
                </button>
              </h2>
              <div
                id="collapsefive"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Outsourcing due diligence services to PRISH Consultancy allows
                  businesses to access specialized expertise, save time and
                  resources, ensure thorough and unbiased assessments, and
                  receive actionable insights to support decision-making.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSix"
                  aria-expanded="false"
                  aria-controls="collapseSix"
                >
                  Can due diligence services help prevent fraud and financial
                  crime?
                </button>
              </h2>
              <div
                id="collapseSix"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Yes, due diligence services help businesses identify red
                  flags, irregularities, and potential risks of fraud or
                  financial crime, allowing them to take preventive measures and
                  make informed decisions to protect their interests.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSeven"
                  aria-expanded="false"
                  aria-controls="collapseSeven"
                >
                  How long does due diligence typically take?
                </button>
              </h2>
              <div
                id="collapseSeven"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  The timeframe for due diligence depends on the complexity of
                  the transaction, the scope of the investigation, and the
                  availability of information. PRISH Consultancy works
                  efficiently to complete due diligence projects within agreed
                  timelines.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseEight"
                  aria-expanded="false"
                  aria-controls="collapseEight"
                >
                  How can businesses ensure confidentiality and integrity during
                  the due diligence process?
                </button>
              </h2>
              <div
                id="collapseEight"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  PRISH Consultancy adheres to strict confidentiality and
                  ethical standards in all our engagements. We prioritize client
                  confidentiality and ensure that sensitive information is
                  handled securely and responsibly throughout the due diligence
                  process.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseNine"
                  aria-expanded="false"
                  aria-controls="collapseNine"
                >
                  Can PRISH Consultancy customize due diligence services based
                  on specific needs?
                </button>
              </h2>
              <div
                id="collapseNine"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Yes, PRISH Consultancy offers tailored due diligence services
                  to meet the unique requirements of each client and
                  transaction. We work closely with clients to understand their
                  objectives and develop customized solutions accordingly.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTen"
                  aria-expanded="false"
                  aria-controls="collapseTen"
                >
                  How do I get started with due diligence services from PRISH
                  Consultancy?
                </button>
              </h2>
              <div
                id="collapseTen"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  You can get started by contacting PRISH Consultancy for a
                  consultation to discuss your due diligence needs and
                  objectives. Our team will work with you to develop a tailored
                  plan to address your specific requirements.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Query />
    </>
  );
};

export default DueDiligence;
