import React, { useEffect, useRef, useState } from "react";
import "../About/about.css";
import { IoCheckmark } from "react-icons/io5";
import { FiSend } from "react-icons/fi";
import img from "../Images/img5.jpg";
import Img3 from "../Images/img3.jpg";
import solution from "../Home/Images/solution.jpg";
import personalized from "../Home/Images/personalized.jpg";
import client from "../Home/Images/client.jpg";
import commitment from "../Home/Images/commitment.jpg";
import integrety1 from "../Home/Images/integrety1.jpg";
import bgImage from "../Home/Images/homepage2.jpeg";
import bgImage1 from "../Home/Images/homepage3.jpg";
import bgImage2 from "../Home/Images/homepage4.jpg";
import bgImage3 from "../Home/Images/homepage5.jpg";
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md";

const About = () => {
  const [image, setImage] = useState("");
  const [count, setCount] = useState(0);

  const Imagesdata = [
    {
      images: bgImage,
      id: 1,
    },
    {
      images: bgImage1,
      id: 2,
    },
    {
      images: bgImage2,
      id: 3,
    },
    {
      images: bgImage3,
      id: 4,
    },
  ];
  const scollToRef = useRef();
  // const textRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    scollToRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);
  // useEffect(() => {
  //   const observer = new IntersectionObserver(
  //     ([entry]) => {
  //       if (entry.isIntersecting) {
  //         setIsVisible(true);
  //         observer.unobserve(textRef.current);
  //       }
  //     },
  //     {
  //       threshold: 0.5, // Adjust as needed
  //     }
  //   );

  //   if (textRef.current) {
  //     observer.observe(textRef.current);
  //   }

  //   return () => {
  //     if (textRef.current) {
  //       observer.unobserve(textRef.current);
  //     }
  //   };
  // }, []);
  useEffect(() => {
    setImage(Imagesdata[0].images);
  }, []);
  // Function is called everytime increment button is clicked
  const handleClick1 = () => {
    // Counter state is incremented
    if (count < 3) {
      setImage(Imagesdata[count + 1]?.images || bgImage);
      setCount(count + 1);
    } else {
      setCount(0);
      setImage(Imagesdata[0]?.images);
    }
  };

  // Function is called everytime decrement button is clicked
  const handleClick2 = () => {
    // Counter state is decremented
    if (count > 0) {
      setImage(Imagesdata[count - 1].images);
      setCount(count - 1);
    } else {
      setCount(3);
      setImage(Imagesdata[3].images);
    }
  };
  return (
    <>
      <section className="sec1">
        <div
          className={`${"info"}`}
          style={{ backgroundImage: `url(${image})` }}
        >
          <h1 className="typo2">About Us</h1>
          <div className="rightIcon">
            <div className="rightIconImageBox" onClick={handleClick1}>
              <MdArrowForwardIos color="#ffffff" />
            </div>
          </div>
          <div className="leftIcon" onClick={handleClick2}>
            <div className="leftIconImageBox">
              <MdArrowBackIos color="#ffffff" />
            </div>
          </div>
        </div>
      </section>
      <section className="sec2">
        <div className="row">
          <div className="about_page_section">
            <div style={{ flex: "0.5" }}>
              <div className="row-img">
                <img src={img} alt="img" width={550} />
              </div>
            </div>
            <div style={{ flex: "1.5" }}>
              <div className="row-info">
                <h2 ref={scollToRef}>About Us</h2>
                <h1>
                  One Stop Solution for all your Accounting and Business Needs
                </h1>
                <p className="text">
                  Welcome to PRISH Accounting and Auditing, your trusted partner
                  in navigating the complexities of financial management and
                  compliance. As a distinguished Chartered Accountant firm, we
                  specialize in delivering tailored solutions to meet your
                  business needs with precision and expertise in the field of{" "}
                  <b>Accounting, </b>
                  <b>
                    Auditing & Assurance, Taxation, Mergers & Acquisitions,
                    Management
                  </b>
                  <b>Consultancy and Company Formation services</b>.
                </p>
                <br />
                <p className="text">
                  At PRISH Accounting and Auditing, we understand the pivotal
                  role financial integrity plays in the success of your
                  endeavours. Our team of professionals brings forth a wealth of
                  experience and a commitment to excellence,ensuring that your
                  financial objectives are not only met but exceeded.
                </p>
                <br />
                <p className="text">
                  Whether you're a budding startup, an established corporation,
                  or an individual seeking personalized financial guidance, we
                  offer a comprehensive suite of services designed to optimize
                  your financial performance and foster sustainable growth.
                  <b>
                    {" "}
                    From auditing and taxation to strategic advisory and risk
                    management
                  </b>
                  , we provide holistic solutions tailored to your unique
                  circumstances.
                </p>
                <br />
                <p className="text">
                  Driven by a dedication to professionalism, integrity, and
                  innovation, we strive to provide you ‘More Than Just Advise’
                  by aiming to be your trusted advisors, empowering you to make
                  informed decisions and achieve your aspirations with
                  confidence. Experience the difference with PRISH. Let us be
                  the cornerstone of your financial success.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="more-info">
          <p className="text">
            Driven by a dedication to professionalism, integrity, and
            innovation, we strive to provide you ‘More Than Just Advise’ by
            aiming to be your trusted advisors, empowering you to make informed
            decisions and achieve your aspirations with confidence. Experience
            the difference with PRISH. Let us be the cornerstone of your
            financial success.
          </p>
        </div> */}
      </section>
      <section className="sec-1">
        <div className="about_content">
          <div className="about_para">
            <h1
              // ref={textRef}
              className={`about_heading-${isVisible ? "visible" : ""}`}
            >
              Why Choose PRISH?
            </h1>

            <p>
              Are you seeking financial guidance that goes beyond mere numbers?
              Look no further than PRISH Accounting and Auditing. We understand
              that selecting the right Chartered Accountants is crucial for the
              success and prosperity of your business. Here's why choosing PRISH
              can be the best decision for you?
            </p>
            <p>
              <b>P</b>erfection beyond imagination
            </p>
            <p>
              <b>R</b>eliability and Consistency in delivering our promises.
            </p>
            <p>
              <b>I</b>nnovative Solutions to meet business needs
            </p>
            <p>
              <b>S</b>olution Driven Approach to all your business challenges
            </p>
            <p>
              <b>H</b>ighly qualified and experienced professionals
            </p>
          </div>
          <div className="about_image">
            <img src={Img3} alt="img" />
          </div>
        </div>
      </section>

      {/* cards */}
      {/* <section className="sec-4 section4-box">
        <div className="core-header">
          <span className="span-text">
            <h1>C</h1>
            <h1>O</h1>
            <h1>R</h1>
            <h1>E</h1>
          </span>

          <span className="span-text">
            <h1>V</h1>
            <h1>A</h1>
            <h1>L</h1>
            <h1>U</h1>
            <h1>E</h1>
            <h1>S</h1>
          </span>
        </div>
        <div className="about-info-box">
          <div className="box">
            <div className="box-icons">
              <img className="about-core-img" src={solution} alt="image" />
            </div>
            <div className="box-content">
              <p>Customise Solutions</p>
            </div>
          </div>

          <div className="box">
            <div className="box-icons">
              <img className="about-core-img" src={personalized} alt="image" />
            </div>
            <div className="box-content">
              <p>Personalized Attention</p>
            </div>
          </div>

          <div className="box">
            <div className="box-icons">
              <img className="about-core-img" src={integrety1} alt="image" />
            </div>
            <div className="box-content">
              <p>Integrity and trust</p>
            </div>
          </div>

          <div className="box">
            <div className="box-icons">
              <img className="about-core-img" src={commitment} alt="image" />
            </div>
            <div className="box-content">
              <p>Commitment to Excellence</p>
            </div>
          </div>

          <div className="box">
            <div className="box-icons">
              <img className="about-core-img" src={client} alt="image" />
            </div>
            <div className="box-content">
              <p>Client Satisfaction</p>
            </div>
          </div>
        </div>
      </section> */}

      {/* end */}

      {/* <section className="sec-2">
        <h1 className="main-heading">Core Values</h1>
        <div className=" value-list">
          <div className="item">
            <span>
              <img src={solution} alt="image" />
            </span>
            <h1 className="heading-2">Customise Solutions</h1>
            <p>
              We put our clients intrests and treat them the way we'd like be
              treated.
            </p>
          </div>

          <div className="item">
            <span>
              <img src={personalized} alt="image" />
            </span>
            <h1 className="heading-2">Personalized Attention</h1>
            <p>
              We look fo the knowladge, skill and tools to become more efficient
              and improve our survices.
            </p>
          </div>

          <div className="item">
            <span>
              <img src={integrety1} alt="image" />
            </span>
            <h1 className="heading-2">Integrity and trust</h1>
            <p>
              we spend great work product to one another and lift each other up.
            </p>
          </div>

          <div className="item">
            <span>
              <img src={commitment} alt="image" />
            </span>
            <h1 className="heading-2">Commitment to Excellence</h1>
            <p>
              We know accounting evolves and small business can be messy but we
              make it heppend.
            </p>
          </div>

          <div className="item">
            <span>
              <img src={client} alt="image" />
            </span>
            <h1 className="heading-2">Client Satisfaction</h1>
            <p>
              We give clients clear and practicle advice, not accounting lessons
              or muddy answers.
            </p>
          </div>
        </div>
      </section> */}
      {/* <section className="sec3">
        <div className="container-about">
          <div className="-sec3-heading">
            <h1>why prish auditors.</h1>
          </div>
          <div className="sec3-para">
            <p className="text-sec3">
              At Prish Auditors, our team of audit specialists brings a wealth
              of knowledge and extensive experience to the auditing process. We
              are dedicated to upholding a standard of excellence, placing high
              audit quality at the core of our services. This commitment ensures
              that you can trust in the meticulous delivery of the audit
              process, adhering to stringent standards, agreed-upon timelines,
              and surpassing your expectations, providing you with the peace of
              mind you deserve.
            </p>
          </div>
        </div>
        <div className="key-points">
          <div className="key-points-info">
            <span className="icon-span">
              <IoCheckmark size={30} />
            </span>
            <p className="text">
              Service you never forget - Office in UAE & INDIA
            </p>
          </div>

          <div className="key-points-info">
            <span className="icon-span">
              <IoCheckmark size={30} />
            </span>
            <p className="text">Personalized attention & timely assistance</p>
          </div>

          <div className="key-points-info">
            <span className="icon-span">
              <IoCheckmark size={30} />
            </span>
            <p className="text">Highest quality of Professional Service</p>
          </div>

          <div className="key-points-info">
            <span className="icon-span">
              <IoCheckmark size={30} />
            </span>
            <p className="text">Innovative solutions to meet business needs</p>
          </div>

          <div className="key-points-info">
            <span className="icon-span">
              <IoCheckmark size={30} />
            </span>
            <p className="text">More than 250 happy clients over the period</p>
          </div>
        </div>
      </section> */}

      {/* <section className="sec4">
        <div className="mission-sec">
          <div className="statement">
            <h1>VISION AND MISSION STATEMENTS</h1>
            <p>
              Prish Auditors, has committed it's experirence ans skill to
              provide high quality professional services to the clients with the
              promise of keeping up highest standard of ethics and integrity
            </p>
          </div>
          <div className="sec4-info">
            <div className="sec4-content">
              <h1>Vision</h1>
              <p>
                Our vision is to lead the evolution of the financial services
                industry, setting new standards of excellence in transparency,
                integrity, and client satisfaction, while championing financial
                literacy and empowerment on a global scale. We vision to be
                premier partner for businesses worldwide, revolutionizing the
                way they approach finance
              </p>
              <h1>Mission</h1>
              <p>
                Our mission is to empower businesses with insightful financial
                solutions, guiding them towards sustainable growth, informed
                decision-making, and financial stability. Driven by a passion
                for financial empowerment, our mission is to partner with
                businesses of all sizes, providing them with the tools,
                expertise, and support they need to achieve their financial
                goals and aspirations.
              </p>
            </div>
          </div>
        </div>
      </section> */}
      <section className="sec4">
        <div className="mission-sec">
          <div class="ribbon">
            VISION AND MISSION STATEMENTS
            <i></i>
            <i></i>
            <i></i>
            <i></i>
          </div>

          <div className="statement">
            {/* <h1 className="about_heading">vision & mission statement</h1> */}
            <p>
              Prish Auditors, has committed it's experirence ans skill to
              provide high quality professional services to the clients with the
              promise of keeping up highest standard of ethics and integrity
            </p>
          </div>
          <div className="sec4-info">
            {/* <div className="icon">
              <FiSend size={40} color="#0a446c" />
            </div> */}
            <div className="sec4-content">
              <div class="arrow-pointer">
                <span>Vision</span>
                <div className="vision-content">
                  Our vision is to lead the evolution of the financial services
                  industry, setting new standards of excellence in transparency,
                  integrity, and client satisfaction, while championing
                  financial literacy and empowerment on a global scale. We
                  vision to be premier partner for businesses worldwide,
                  revolutionizing the way they approach finance
                </div>
              </div>

              <div class="arrow-pointer-left">
                <span>Mission</span>
                <div className="vision-content-left">
                  Our mission is to empower businesses with insightful financial
                  solutions, guiding them towards sustainable growth, informed
                  decision-making, and financial stability. Driven by a passion
                  for financial empowerment, our mission is to partner with
                  businesses of all sizes, providing them with the tools,
                  expertise, and support they need to achieve their financial
                  goals and aspirations.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section class="service-five">
        <div class="pattern-layer">
          <div
            class="pattern-layer "
            style={{
              backgroundImage: `url(
                'https://www.stuaham.com/public/images/shape/shape-766.png'
              )`,
            }}
          ></div>
        </div>

        <div class="auto-container mt-5">
          <div class="row clearfix">
            <div class="col-lg-3 col-md-6 col-sm-12 service-block">
              <div
                class="service-block-five wow slideInUp animated animated"
                data-wow-delay="00ms"
                data-wow-duration="1500ms"
                style={{
                  visibility: "visible",
                  animationDuration: "1500ms",
                  animationDelay: "0ms",
                  animationName: "slideInUp",
                }}
              >
                <div class="inner-box p_relative d_block b_radius_10 pt_35 pr_30 pb_10 pl_30 centred">
                  <div class="icon-box p_relative fs_45 lh_45 mb_25 tran_5">
                    <i class="fal fa-file-signature"></i>
                  </div>
                  <h3 class="d_block lh_30 fw_bold font_family_frank mb_20">
                    <a href="/auditing-assurance-services">
                      Audit &amp; Assurance
                    </a>
                  </h3>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 service-block">
              <div
                class="service-block-five wow slideInUp animated animated"
                data-wow-delay="200ms"
                data-wow-duration="1500ms"
                style={{
                  visibility: "visible",
                  animationDuration: "1500ms",
                  animationDelay: "200ms",
                  animationName: "slideInUp",
                }}
              >
                <div class="inner-box p_relative d_block b_radius_10 pt_35 pr_30 pb_10 pl_30 centred">
                  <div class="icon-box p_relative fs_45 lh_45 mb_25 tran_5">
                    <i class="fas fa-chart-pie-alt"></i>
                  </div>
                  <h3 class="d_block lh_30 fw_bold font_family_frank mb_20">
                    <a href="/risk-advisory-services">Risk Assurance</a>
                  </h3>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 service-block">
              <div
                class="service-block-five wow slideInUp animated animated"
                data-wow-delay="400ms"
                data-wow-duration="1500ms"
                style={{
                  visibility: "visible",
                  animationDuration: "1500ms",
                  animationDelay: "400ms",
                  animationName: "slideInUp",
                }}
              >
                <div class="inner-box p_relative d_block b_radius_10 pt_35 pr_30 pb_10 pl_30 centred">
                  <div class="icon-box p_relative fs_45 lh_45 mb_25 tran_5">
                    <i class="fas fa-hands-usd"></i>
                  </div>
                  <h3 class="d_block lh_30 fw_bold font_family_frank mb_20">
                    <a href="/accounting-outsourcing-services">
                      Accounting and Bookkeeping
                    </a>
                  </h3>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 service-block">
              <div
                class="service-block-five wow slideInUp animated animated"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
                style={{
                  visibility: "visible",
                  animationDuration: "1500ms",
                  animationDelay: "0ms",
                  animationName: "slideInUp",
                }}
              >
                <div class="inner-box p_relative d_block b_radius_10 pt_35 pr_30 pb_10 pl_30 centred">
                  <div class="icon-box p_relative fs_45 lh_45 mb_25 tran_5">
                    <i class="fas fa-user-chart"></i>
                  </div>
                  <h3 class="d_block lh_30 fw_bold font_family_frank mb_20">
                    <a href="/anti-money-laundering">
                      Anti Money Laundering (AML) Compliances
                    </a>
                  </h3>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 service-block">
              <div
                class="service-block-five wow slideInUp animated animated"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
                style={{
                  visibility: "visible",
                  animationDuration: "1500ms",
                  animationDelay: "0ms",
                  animationName: "slideInUp",
                }}
              >
                <div class="inner-box p_relative d_block b_radius_10 pt_35 pr_30 pb_10 pl_30 centred">
                  <div class="icon-box p_relative fs_45 lh_45 mb_25 tran_5">
                    <i class="fas fa-handshake"></i>
                  </div>
                  <h3 class="d_block lh_30 fw_bold font_family_frank mb_20">
                    <a href="/tax-services">Taxation</a>
                  </h3>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 service-block">
              <div
                class="service-block-five wow slideInUp animated animated"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
                style={{
                  visibility: "visible",
                  animationDuration: "1500ms",
                  animationDelay: "0ms",
                  animationName: "slideInUp",
                }}
              >
                <div class="inner-box p_relative d_block b_radius_10 pt_35 pr_30 pb_10 pl_30 centred">
                  <div class="icon-box p_relative fs_45 lh_45 mb_25 tran_5">
                    <i class="fas fa-calculator"></i>
                  </div>
                  <h3 class="d_block lh_30 fw_bold font_family_frank mb_20">
                    <a href="/compliance-services">Corporate Consultancy</a>
                  </h3>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 service-block">
              <div
                class="service-block-five wow slideInUp animated animated"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
                style={{
                  visibility: "visible",
                  animationDuration: "1500ms",
                  animationDelay: "0ms",
                  animationName: "slideInUp",
                }}
              >
                <div class="inner-box p_relative d_block b_radius_10 pt_35 pr_30 pb_30 pl_30 centred">
                  <div class="icon-box p_relative fs_45 lh_45 mb_25 tran_5">
                    <i class="far fa-laptop-code"></i>
                  </div>
                  <h3 class="d_block lh_30 fw_bold font_family_frank mb_20">
                    <a href="/company-incorporation-services">
                      Company Incorporation Services
                    </a>
                  </h3>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 service-block">
              <div
                class="service-block-five wow slideInUp animated animated"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
                style={{
                  visibility: "visible",
                  animationDuration: "1500ms",
                  animationDelay: "0ms",
                  animationName: "slideInUp",
                }}
              >
                <div class="inner-box p_relative d_block b_radius_10 pt_35 pr_30 pb_30 pl_30 centred">
                  <div class="icon-box p_relative fs_45 lh_45 mb_25 tran_5">
                    <i class="far fa-globe"></i>
                  </div>
                  <h3 class="d_block lh_30 fw_bold font_family_frank mb_20">
                    <a href="/merger-acquisitions">Mergers & Acquisitions</a>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
};

export default About;
