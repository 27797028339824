import React, { useEffect, useRef, useState } from "react";
import "../Reach/reach.css";
import { IoLocationSharp, IoCall, IoMail } from "react-icons/io5";
import bgImage from "../Home/Images/homepage2.jpeg";
import bgImage1 from "../Home/Images/homepage3.jpg";
import bgImage2 from "../Home/Images/homepage4.jpg";
import bgImage3 from "../Home/Images/homepage5.jpg";
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import Query from "../Components/Query/Query";
import emailjs from "@emailjs/browser";
import Swal from "sweetalert2";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";

const Reach = () => {
  const [image, setImage] = useState("");
  const [count, setCount] = useState(0);
  const scollToRef = useRef();
  useEffect(() => {
    scollToRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);
  const Imagesdata = [
    {
      images: bgImage,
      id: 1,
    },
    {
      images: bgImage1,
      id: 2,
    },
    {
      images: bgImage2,
      id: 3,
    },
    {
      images: bgImage3,
      id: 4,
    },
  ];
  useEffect(() => {
    setImage(Imagesdata[0].images);
  }, []);
  // Function is called everytime increment button is clicked
  const handleClick1 = () => {
    // Counter state is incremented
    if (count < 3) {
      setImage(Imagesdata[count + 1]?.images || bgImage);
      setCount(count + 1);
    } else {
      setCount(0);
      setImage(Imagesdata[0]?.images);
    }
  };

  // Function is called everytime decrement button is clicked
  const handleClick2 = () => {
    // Counter state is decremented
    if (count > 0) {
      setImage(Imagesdata[count - 1].images);
      setCount(count - 1);
    } else {
      setCount(3);
      setImage(Imagesdata[3].images);
    }
  };
  const handleChange = (e, isphone = false) => {
    if (isphone) {
      setPassword((prev) => {
        return {
          ...prev,
          phone: e,
        };
      });
    } else {
      const { name, value } = e.target;
      setPassword((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
      setErrors((prev) => {
        return { ...prev, [name]: "" };
      });
    }
  };
  const form = useRef();
  const [password, setPassword] = useState({
    user_name: "",
    phone: "",
    user_email: "",
    message: "",
  });
  const [errors, setErrors] = useState({
    user_name: "",
    phone: "",
    user_email: "",
    message: "",
  });
  const [disabled, isDisabled] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    const newErrors = {};
    const phoneRegex = /^\+?\d{1,3}\s?\d{6,14}$/;
    console.log(
      "form.currentform.current",
      form.current,
      !phoneRegex.test(password.phone.trim())
    );

    if (!password.user_name.trim()) {
      newErrors.user_name = "Name is required";
    }
    if (!password.phone.trim()) {
      newErrors.phone = "Phone is required";
    }
    if (!phoneRegex.test(password.phone.trim())) {
      newErrors.phone = "Please Enter Valid Phone Number";
    }
    if (!password.user_email.trim()) {
      newErrors.user_email = "Email is required";
    }
    if (!password.message.trim()) {
      newErrors.message = "Message is required";
    }
    setErrors(newErrors);
    isDisabled(true);

    emailjs
      .sendForm("service_cuef4a9", "template_2tvvgf7", form.current, {
        publicKey: "D1TUXJ99gyiQaetHB",
      })
      .then(
        () => {
          Swal.fire(
            "Success",
            "Form Submitted sucessfully, team will reach out to you shortly!",
            "success"
          );
          setPassword({
            user_name: "",
            phone: "",
            user_email: "",
            message: "",
          });
          isDisabled(false);
        },
        (error) => {
          Swal.fire(
            "Opsss...",
            "Error Occurred, Please fill the form again.",
            "error"
          );
          isDisabled(false);
        }
      );
  };
  return (
    <>
      <div className={`${"info"}`} style={{ backgroundImage: `url(${image})` }}>
        <h1 className="typo9">Reach Us</h1>
        <div className="rightIcon">
          <div className="rightIconImageBox" onClick={handleClick1}>
            <MdArrowForwardIos color="#ffffff" />
          </div>
        </div>
        <div className="leftIcon" onClick={handleClick2}>
          <div className="leftIconImageBox">
            <MdArrowBackIos color="#ffffff" />
          </div>
        </div>
      </div>
      <div className="contact-form">
        <h1 ref={scollToRef} className="contact-form-heading">
          Reach Us
        </h1>

        <div className="contact-container">
          <section class="ch-section container-fluid ch-contact ch-honeycomb-bg1">
            <div class="no-gutters ch-maxwd">
              <div class="col-12 col-sm-6 ch-content ch-grey-bg">
                <h2>Contact us for a Consultation</h2>
                <p>
                  We may use the information you provide to contact you now or
                  in the future and would help us understand better your
                  requirement. We aim to respond within 24 hours.
                </p>
                <ul>
                  <div className="reach_us_box">
                    <MdKeyboardDoubleArrowRight color="#0093D1" />
                    <a href="https://api.whatsapp.com/send/?phone=971567244122">
                      +971 567244122
                    </a>
                  </div>

                  <div className="reach_us_box">
                    <MdKeyboardDoubleArrowRight color="#0093D1" />
                    <a href="mailto:info@prishauditors.com">
                      info@prishauditors.com
                    </a>
                  </div>
                </ul>
              </div>
              <div class="col-12 col-sm-6 ch-data ch-blue-bg">
                <div class="ch-form">
                  <div
                    class="wpcf7 js"
                    id="wpcf7-f181-o1"
                    lang="en-US"
                    dir="ltr"
                  >
                    <div class="screen-reader-response">
                      <p
                        role="status"
                        aria-live="polite"
                        aria-atomic="true"
                      ></p>{" "}
                      <ul></ul>
                    </div>
                    <form
                      ref={form}
                      onSubmit={sendEmail}
                      class="wpcf7-form init"
                    >
                      <div class="ch-split">
                        <p>
                          <span
                            class="wpcf7-form-control-wrap"
                            data-name="fname"
                          >
                            <input
                              size="40"
                              class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                              aria-required="true"
                              aria-invalid="false"
                              placeholder="Name"
                              type="text"
                              name="user_name"
                              value={password.user_name}
                              onChange={handleChange}
                            />
                            <div style={{ color: "red", fontSize: "13px" }}>
                              {errors["user_name"]}
                            </div>
                          </span>
                        </p>
                      </div>
                      <div class="ch-phone">
                        <p>
                          <span
                            class="wpcf7-form-control-wrap"
                            data-name="phone"
                          >
                            <div className="phone-input">
                              <PhoneInput
                                defaultCountry="in"
                                placeholder={"Mobile no"}
                                name="phone"
                                onChange={(e) => handleChange(e, true)}
                                value={password.phone}
                              />
                            </div>
                            {/* <input
                              size="40"
                              class="wpcf7-form-control wpcf7-text wpcf7-tel wpcf7-validates-as-required wpcf7-validates-as-tel"
                              aria-required="true"
                              aria-invalid="false"
                              placeholder="Phone"
                              type="tel"
                              name="phone"
                              value={password.phone}
                              onChange={handleChange}
                            /> */}
                            <div style={{ color: "red", fontSize: "13px" }}>
                              {errors["phone"]}
                            </div>
                          </span>
                        </p>
                      </div>
                      <div class="ch-full">
                        <p>
                          <span
                            class="wpcf7-form-control-wrap"
                            data-name="email"
                          >
                            <input
                              size="40"
                              class="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email"
                              aria-required="true"
                              aria-invalid="false"
                              placeholder="Email"
                              pattern="\S+@\S+\.\S+"
                              type="email"
                              name="user_email"
                              value={password.user_email}
                              onChange={handleChange}
                            />{" "}
                            <div style={{ color: "red", fontSize: "13px" }}>
                              {errors["user_email"]}
                            </div>
                          </span>
                        </p>
                      </div>
                      <div class="ch-full">
                        <p>
                          <span
                            class="wpcf7-form-control-wrap"
                            data-name="message"
                          >
                            <textarea
                              cols="40"
                              rows="3"
                              class="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required"
                              aria-required="true"
                              aria-invalid="false"
                              placeholder="Message"
                              name="message"
                              value={password.message}
                              onChange={handleChange}
                            ></textarea>
                            <div style={{ color: "red", fontSize: "13px" }}>
                              {errors["message"]}
                            </div>
                          </span>
                        </p>
                      </div>
                      <div class="ch-split">
                        <p>
                          <input
                            class="wpcf7-form-control has-spinner wpcf7-submit"
                            type="submit"
                            value="Send"
                            // disabled={disabled}
                          />
                          <span class="wpcf7-spinner" />
                        </p>
                      </div>

                      <div
                        class="wpcf7-response-output"
                        aria-hidden="true"
                      ></div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <div className="contact-form-info">
            <div className="contact-box">
              <div className="contact-box-icons">
                <IoCall size={55} />
              </div>
              <div className="more-info">
                <p>Call Us at (+971 567244122)</p>
              </div>
            </div>

            <div className="contact-box">
              <div className="contact-box-icons">
                <IoMail size={55} />
              </div>
              <div className="more-info">
                <p>info@prishauditors.com</p>
              </div>
            </div>
          </div>

          <div className="reach-us-form">
            <form className="form" method="post" action="#">
              <div className="form-input">
                <input
                  className="contact-form-name  input-field"
                  type="text"
                  id="form-name"
                  placeholder="Enter your name"
                  required={true}
                />
                <input
                  className="contact-form-email input-field"
                  type="email"
                  id="form-email"
                  placeholder="Enter your Email"
                  required={true}
                />
                <input
                  className="contact-form-phone input-field"
                  type="number"
                  id="form-phone"
                  placeholder="Enter your phone number"
                  required={true}
                />
              </div>
              <div className="input-message">
                <textarea
                  className="contact-form-message"
                  rows={8}
                  cols={20}
                  placeholder="Leave A Message.........."
                ></textarea>
              </div>
              <div className="contact-form-btn">
                <button type="submit" className="submit-button">
                  Send message
                </button>
              </div>
            </form>
          </div> */}
        </div>
      </div>
      {/* <Query /> */}
    </>
  );
};

export default Reach;
