import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import mys3 from "./images/mys3.jpeg";
import mys1 from "./images/mys1.jpeg";
import mys2 from "./images/mys2.jpeg";
import bgImage from "../Home/Images/homepage2.jpeg";
import bgImage1 from "../Home/Images/homepage3.jpg";
import bgImage2 from "../Home/Images/homepage4.jpg";
import bgImage3 from "../Home/Images/homepage5.jpg";
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md";
import Query from "../Components/Query/Query";

const MysteryAudit = () => {
  const [image, setImage] = useState("");
  const [count, setCount] = useState(0);
  const scollToRef = useRef();
  useEffect(() => {
    scollToRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);
  const Imagesdata = [
    {
      images: bgImage,
      id: 1,
    },
    {
      images: bgImage1,
      id: 2,
    },
    {
      images: bgImage2,
      id: 3,
    },
    {
      images: bgImage3,
      id: 4,
    },
  ];
  useEffect(() => {
    setImage(Imagesdata[0].images);
  }, []);
  // Function is called everytime increment button is clicked
  const handleClick1 = () => {
    // Counter state is incremented
    if (count < 3) {
      setImage(Imagesdata[count + 1]?.images || bgImage);
      setCount(count + 1);
    } else {
      setCount(0);
      setImage(Imagesdata[0]?.images);
    }
  };

  // Function is called everytime decrement button is clicked
  const handleClick2 = () => {
    // Counter state is decremented
    if (count > 0) {
      setImage(Imagesdata[count - 1].images);
      setCount(count - 1);
    } else {
      setCount(3);
      setImage(Imagesdata[3].images);
    }
  };
  return (
    <>
      <section className="corporate-sec">
        <div
          className={`${"info"}`}
          style={{ backgroundImage: `url(${image})` }}
        >
          <h1 className="typo5">Mystery Audit</h1>
          <div className="rightIcon">
            <div className="rightIconImageBox" onClick={handleClick1}>
              <MdArrowForwardIos color="#ffffff" />
            </div>
          </div>
          <div className="leftIcon" onClick={handleClick2}>
            <div className="leftIconImageBox">
              <MdArrowBackIos color="#ffffff" />
            </div>
          </div>
        </div>
        <div className="corporate-sec-1  ">
          <div className="corporate-img">
            <div class="hexagon">
              <img src={mys3} width="200" height="200" />
            </div>
            <div className="nextConatainer">
              <div class="hexagon1">
                <img src={mys1} width="200" height="200" />
              </div>
            </div>
            <div class="hexagon2">
              <img src={mys2} width="200" height="200" />
            </div>

            {/* <img src="./CorporateImage/img1.jpeg" alt="img" width={500} /> */}
          </div>
          <div className="info-paragraphs">
            <h3 ref={scollToRef} className="audit-header">
              Mystery Audit
            </h3>
            <p className="text">
              Core assessments are conducted by us as an independent evaluator
              to measure and evaluate the quality of service, compliance with
              policies and procedures, and overall customer experience with your
              company’s brand.
            </p>
            <p>
              Mystery audit, also known as mystery shopping or secret shopping,
              involves sending trained evaluators to assess the quality of
              service and compliance with company standards from a customer's
              perspective. It provides unbiased feedback on various aspects of
              your business, including customer service, product quality, and
              adherence to operational procedures.
            </p>
            <p>
              Our professional mystery audit services are designed to provide
              valuable insights into the customer experience, operational
              efficiency, and compliance with service standards. Our mystery
              audits are conducted discreetly and objectively to assess various
              aspects of your business, helping you identify areas for
              improvement and enhance overall performance.
            </p>
            <br />
            {/* <h4>
              Detailed accounting and management processes covered by us are as
              follows:
            </h4> */}
            <h3 className="submenu-header ">Why Choose PRISH ?</h3>
            <p className="submenu-sub-header ">
              Objective Assessment:{" "}
              <span className="text">
                Our trained evaluators conduct anonymous visits or interactions
                with your business to provide unbiased assessments of customer
                service quality and compliance with operational standards.
              </span>
            </p>

            <p className="submenu-sub-header ">
              Comprehensive Reporting:
              <span className="text">
                We provide detailed reports and actionable insights based on the
                findings of our mystery audits, highlighting strengths,
                weaknesses, and areas for improvement.
              </span>{" "}
            </p>
            <p className="submenu-sub-header ">
              Customized Evaluation Criteria:
              <span className="text">
                We tailor our mystery audits to focus on specific aspects of
                your business, such as customer service, cleanliness, product
                knowledge, sales techniques, and adherence to policies and
                procedures.
              </span>
            </p>
            <p className="submenu-sub-header ">
              Benchmarking:
              <span className="text">
                Our mystery audit services can be used to benchmark performance
                across multiple locations or against industry standards, helping
                you identify best practices and areas for differentiation.
              </span>
            </p>
          </div>
        </div>
        <div className="accordian-section">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  What is a mystery audit?
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  A mystery audit, also known as mystery shopping or secret
                  shopping, is a method of evaluating the quality of service and
                  compliance with company standards by sending anonymous
                  evaluators to assess various aspects of a business from a
                  customer's perspective.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  Why is a mystery audit necessary?
                </button>
              </h2>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  A mystery audit is necessary to gain insights into the
                  customer experience, identify areas for improvement, ensure
                  compliance with operational standards, and maintain high
                  service quality.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  How does a mystery audit differ from other types of audits?
                </button>
              </h2>
              <div
                id="collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  While traditional audits focus on financial records and
                  compliance, mystery audits focus on the customer experience,
                  service quality, and adherence to operational procedures from
                  a customer's viewpoint.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsefour"
                  aria-expanded="false"
                  aria-controls="collapsefour"
                >
                  What are the key objectives of a mystery audit?
                </button>
              </h2>
              <div
                id="collapsefour"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  The primary objectives of a mystery audit are to assess
                  customer service quality, evaluate adherence to operational
                  standards, identify areas for improvement, and enhance overall
                  customer satisfaction.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsefive"
                  aria-expanded="false"
                  aria-controls="collapsefive"
                >
                  What are the benefits of a mystery audit?
                </button>
              </h2>
              <div
                id="collapsefive"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <p className="submenu-sub-header ">
                    Customer Experience Improvement:{" "}
                    <span className="text">
                      {" "}
                      Enhance the customer experience by identifying and
                      addressing gaps in service quality.
                    </span>
                  </p>{" "}
                  <p className="submenu-sub-header ">
                    Operational Efficiency:{" "}
                    <span className="text">
                      {" "}
                      Streamline processes and procedures to improve efficiency
                      and reduce costs.
                    </span>
                  </p>{" "}
                  <p className="submenu-sub-header ">
                    Employee Training and Development:{" "}
                    <span className="text">
                      {" "}
                      Provide targeted training and coaching based on feedback
                      from mystery audits to improve employee performance.
                    </span>
                  </p>{" "}
                  <p className="submenu-sub-header ">
                    Quality Control:{" "}
                    <span className="text">
                      {" "}
                      Ensure consistency in service delivery and maintain high
                      standards across all locations.
                    </span>
                  </p>
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSix"
                  aria-expanded="false"
                  aria-controls="collapseSix"
                >
                  How frequently should a mystery audit be conducted?
                </button>
              </h2>
              <div
                id="collapseSix"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  The frequency of mystery audits depends on the business's
                  needs, industry standards, and areas of concern. Some
                  businesses conduct mystery audits quarterly, while others may
                  do so monthly or annually.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSeven"
                  aria-expanded="false"
                  aria-controls="collapseSeven"
                >
                  What industries can benefit from mystery audit services?
                </button>
              </h2>
              <div
                id="collapseSeven"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Mystery audit services can benefit businesses in various
                  industries, including retail, hospitality, healthcare,
                  banking, automotive, and more, where customer service and
                  experience are critical.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseEight"
                  aria-expanded="false"
                  aria-controls="collapseEight"
                >
                  How can I use the findings from a mystery audit to improve my
                  business?
                </button>
              </h2>
              <div
                id="collapseEight"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  The findings from a mystery audit can be used to identify
                  specific areas for improvement, develop targeted training
                  programs for employees, implement process enhancements, and
                  measure progress over time.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Query />
    </>
  );
};

export default MysteryAudit;
