import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import company from "./images/company.jpeg";
import company1 from "./images/company1.jpeg";
import company2 from "./images/company2.jpeg";
import bgImage from "../Home/Images/homepage2.jpeg";
import bgImage1 from "../Home/Images/homepage3.jpg";
import bgImage2 from "../Home/Images/homepage4.jpg";
import bgImage3 from "../Home/Images/homepage5.jpg";
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md";
import Query from "../Components/Query/Query";

const Restructuring = () => {
  const [image, setImage] = useState("");
  const [count, setCount] = useState(0);
  const scollToRef = useRef();
  useEffect(() => {
    scollToRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);
  const Imagesdata = [
    {
      images: bgImage,
      id: 1,
    },
    {
      images: bgImage1,
      id: 2,
    },
    {
      images: bgImage2,
      id: 3,
    },
    {
      images: bgImage3,
      id: 4,
    },
  ];
  useEffect(() => {
    setImage(Imagesdata[0].images);
  }, []);
  // Function is called everytime increment button is clicked
  const handleClick1 = () => {
    // Counter state is incremented
    if (count < 3) {
      setImage(Imagesdata[count + 1]?.images || bgImage);
      setCount(count + 1);
    } else {
      setCount(0);
      setImage(Imagesdata[0]?.images);
    }
  };

  // Function is called everytime decrement button is clicked
  const handleClick2 = () => {
    // Counter state is decremented
    if (count > 0) {
      setImage(Imagesdata[count - 1].images);
      setCount(count - 1);
    } else {
      setCount(3);
      setImage(Imagesdata[3].images);
    }
  };

  return (
    <>
      <section className="corporate-sec">
        <div
          className={`${"info"}`}
          style={{ backgroundImage: `url(${image})` }}
        >
          <h1 className="typo5"> Restructuring and Reorganization</h1>
          <div className="rightIcon">
            <div className="rightIconImageBox" onClick={handleClick1}>
              <MdArrowForwardIos color="#ffffff" />
            </div>
          </div>
          <div className="leftIcon" onClick={handleClick2}>
            <div className="leftIconImageBox">
              <MdArrowBackIos color="#ffffff" />
            </div>
          </div>
        </div>
        <div className="corporate-sec-1  ">
          <div className="corporate-img">
            <div class="hexagon">
              <img src={company} width="200" height="200" />
            </div>
            <div className="nextConatainer">
              <div class="hexagon1">
                <img src={company1} width="200" height="200" />
              </div>
            </div>
            <div class="hexagon2">
              <img src={company2} width="200" height="200" />
            </div>

            {/* <img src="./CorporateImage/img1.jpeg" alt="img" width={500} /> */}
          </div>
          <div className="info-paragraphs">
            <h3 ref={scollToRef} className="audit-header">
              {" "}
              Restructuring and Reorganization services
            </h3>
            <p className="text">
              These are the essential services for businesses facing financial,
              operational, or strategic challenges to adapt, evolve, and thrive
              in dynamic and competitive markets. We have experienced
              restructuring professionals in our team who can help companies to
              navigate complex restructuring processes, unlock value, and
              position themselves for long-term success and sustainability. It
              is designed to help businesses adapt to changing market
              conditions, improve operational efficiency, and maximize value.
            </p>
            <p className="text">
              Our experienced team provides strategic guidance and hands-on
              support throughout the restructuring process to achieve
              sustainable results.
            </p>
            <p className="text">
              Our approach is to understand that every business is unique and
              tailor our approach to meet the specific needs and objectives of
              our clients. We are focusing on identifying opportunities for
              improvement, implementing strategic changes, and supporting our
              clients through every stage of the process.
            </p>
            <br />
            {/* <h4>
              Detailed accounting and management processes covered by us are as
              follows:
            </h4> */}
            <h3 className="submenu-header ">Why Choose PRISH ?</h3>
            <p className="submenu-sub-header ">
              Strategic Assessment:{" "}
              <span className="text">
                We conduct a comprehensive assessment of the business to
                identify areas for improvement and develop a strategic roadmap
                for restructuring and reorganization.
              </span>
            </p>

            <p className="submenu-sub-header ">
              Operational Review:{" "}
              <span className="text">
                We analyze existing processes, workflows, and resource
                allocation to identify inefficiencies and opportunities for
                optimization.
              </span>{" "}
            </p>
            <p className="submenu-sub-header ">
              Financial Analysis:{" "}
              <span className="text">
                We evaluate the financial performance of the business, including
                revenue, expenses, and cash flow, to identify areas of financial
                distress and develop solutions to improve profitability.
              </span>
            </p>
            <p className="submenu-sub-header ">
              Organizational Design:{" "}
              <span className="text">
                We work with our clients to redesign their organizational
                structure, roles, and responsibilities to align with strategic
                objectives and improve agility and efficiency.
              </span>
            </p>

            <p className="submenu-sub-header ">
              Change Management:{" "}
              <span className="text">
                We provide support and guidance to manage the transition
                process, including communication, training, and stakeholder
                engagement, to ensure smooth implementation and minimize
                disruption.
              </span>
            </p>
          </div>
        </div>
        <div className="accordian-section">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  What is restructuring and reorganization?
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Restructuring and reorganization involve making significant
                  changes to a company's organizational structure, processes,
                  and resources to improve performance, adapt to market
                  conditions, or address financial challenges.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  Why would a company need restructuring and reorganization?
                </button>
              </h2>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Companies may need restructuring and reorganization for
                  various reasons, such as:
                  <ul>
                    <li>Adapting to changes in the market or industry.</li>
                    <li>
                      Improving operational efficiency and reducing costs.
                    </li>
                    <li>Addressing financial challenges or distress.</li>
                    <li>
                      Aligning the organization with strategic objectives.
                    </li>
                    <li>
                      Integrating acquisitions or divesting non-core assets.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  What are the key benefits of restructuring and reorganization?
                </button>
              </h2>
              <div
                id="collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <p className="submenu-sub-header ">
                    Improved Efficiency:
                    <span className="text">
                      {" "}
                      Streamlining processes and reallocating resources can lead
                      to improved operational efficiency and cost savings.
                    </span>
                  </p>{" "}
                  <p className="submenu-sub-header ">
                    Enhanced Performance:
                    <span className="text">
                      {" "}
                      Restructuring can refocus the business on core strengths,
                      improving performance and competitiveness.
                    </span>
                  </p>{" "}
                  <p className="submenu-sub-header ">
                    Adaptability:
                    <span className="text">
                      {" "}
                      A well-designed organizational structure can make the
                      business more agile and responsive to market changes and
                      opportunities.
                    </span>
                  </p>{" "}
                  <p className="submenu-sub-header ">
                    Financial Stability:
                    <span className="text">
                      {" "}
                      Addressing financial challenges and optimizing financial
                      resources can improve cash flow and ensure long-term
                      financial stability.
                    </span>
                  </p>{" "}
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsefour"
                  aria-expanded="false"
                  aria-controls="collapsefour"
                >
                  What does the restructuring and reorganization process
                  involve?
                </button>
              </h2>
              <div
                id="collapsefour"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  The restructuring and reorganization process typically
                  include:
                  <ul>
                    <li>
                      Strategic assessment to identify areas for improvement.
                    </li>
                    <li>
                      Operational review to analyze processes, workflows, and
                      resource allocation.
                    </li>
                    <li>
                      Financial analysis to evaluate performance and identify
                      financial challenges.
                    </li>
                    <li>
                      Organizational design to redesign structure, roles, and
                      responsibilities.
                    </li>
                    <li>
                      Change management to manage the transition process and
                      ensure smooth implementation.
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsefive"
                  aria-expanded="false"
                  aria-controls="collapsefive"
                >
                  Can restructuring and reorganization services be customized to
                  meet our company's needs?
                </button>
              </h2>
              <div
                id="collapsefive"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Yes, these services can be customised to meet the unique needs
                  and objectives of each client. We understand that every
                  business is different, and we tailor our approach to ensure
                  maximum effectiveness and value.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSix"
                  aria-expanded="false"
                  aria-controls="collapseSix"
                >
                  How long does the restructuring and reorganization process
                  take?
                </button>
              </h2>
              <div
                id="collapseSix"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  The duration of the restructuring and reorganization process
                  depends on the size and complexity of the organization, the
                  extent of the changes needed, and other factors.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Query />
    </>
  );
};

export default Restructuring;
