import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import company from "./images/fixed_assets1.jpeg";
import company1 from "./images/fixed_assets2.jpeg";
import company2 from "./images/fixed_assets3.jpeg";
import bgImage from "../Home/Images/homepage2.jpeg";
import bgImage1 from "../Home/Images/homepage3.jpg";
import bgImage2 from "../Home/Images/homepage4.jpg";
import bgImage3 from "../Home/Images/homepage5.jpg";
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md";
import Query from "../Components/Query/Query";

const FixedAssetsManagement = () => {
  const [image, setImage] = useState("");
  const [count, setCount] = useState(0);
  const scollToRef = useRef();
  useEffect(() => {
    scollToRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);
  const Imagesdata = [
    {
      images: bgImage,
      id: 1,
    },
    {
      images: bgImage1,
      id: 2,
    },
    {
      images: bgImage2,
      id: 3,
    },
    {
      images: bgImage3,
      id: 4,
    },
  ];
  useEffect(() => {
    setImage(Imagesdata[0].images);
  }, []);
  // Function is called everytime increment button is clicked
  const handleClick1 = () => {
    // Counter state is incremented
    if (count < 3) {
      setImage(Imagesdata[count + 1]?.images || bgImage);
      setCount(count + 1);
    } else {
      setCount(0);
      setImage(Imagesdata[0]?.images);
    }
  };

  // Function is called everytime decrement button is clicked
  const handleClick2 = () => {
    // Counter state is decremented
    if (count > 0) {
      setImage(Imagesdata[count - 1].images);
      setCount(count - 1);
    } else {
      setCount(3);
      setImage(Imagesdata[3].images);
    }
  };
  return (
    <>
      <section className="corporate-sec">
        <div
          className={`${"info"}`}
          style={{ backgroundImage: `url(${image})` }}
        >
          <h1 className="typo5">Fixed Assets Management</h1>
          <div className="rightIcon">
            <div className="rightIconImageBox" onClick={handleClick1}>
              <MdArrowForwardIos color="#ffffff" />
            </div>
          </div>
          <div className="leftIcon" onClick={handleClick2}>
            <div className="leftIconImageBox">
              <MdArrowBackIos color="#ffffff" />
            </div>
          </div>
        </div>
        <div className="corporate-sec-1  ">
          <div className="corporate-img">
            <div class="hexagon">
              <img src={company} width="200" height="200" />
            </div>
            <div className="nextConatainer">
              <div class="hexagon1">
                <img src={company1} width="200" height="200" />
              </div>
            </div>
            <div class="hexagon2">
              <img src={company2} width="200" height="200" />
            </div>

            {/* <img src="./CorporateImage/img1.jpeg" alt="img" width={500} /> */}
          </div>
          <div className="info-paragraphs">
            <h3 ref={scollToRef} className="audit-header">
              Fixed Assets Management
            </h3>
            <p className="text">
              Management and optimization of a company's fixed assets throughout
              their lifecycle, from acquisition to disposal. Fixed assets SOP
              review, physical verification, accounting entries, and treatment
              for fixed assets are provided.It helps businesses efficiently
              track, manage, and optimize their fixed assets.
            </p>
            <p className="text">
              Our expert team provides tailored solutions to streamline
              processes, improve accuracy, and maximize the value of fixed
              assets throughout their lifecycle.
            </p>
            <p className="text">
              Our approach is to understand the importance of effective fixed
              assets management for businesses to optimize their operations and
              financial performance. It includes assessing each client's unique
              needs and challenges and providing customized solutions that drive
              efficiency and value.
            </p>

            <br />

            <h3 className="submenu-header ">Why Choose PRISH ?</h3>
            <p className="submenu-sub-header ">
              Asset Tracking and Identification:{" "}
              <span className="text">
                We assist businesses in accurately tracking and identifying all
                fixed assets, including tagging and labelling assets for easy
                identification.
              </span>
            </p>

            <p className="submenu-sub-header ">
              Maintenance and Depreciation Management:{" "}
              <span className="text">
                Our team helps businesses manage maintenance schedules,
                calculate depreciation, and ensure compliance with accounting
                standards such as GAAP or IFRS.
              </span>{" "}
            </p>
            <p className="submenu-sub-header ">
              Inventory and Valuation:{" "}
              <span className="text">
                We conduct regular inventories and valuations of fixed assets to
                ensure accurate financial reporting and compliance with
                regulatory requirements.
              </span>
            </p>
            <p className="submenu-sub-header ">
              Asset Disposal and Replacement:{" "}
              <span className="text">
                We provide guidance on asset disposal strategies, including
                sale, donation, or scrapping, and assist in planning for asset
                replacements to optimize the asset lifecycle.
              </span>
            </p>
          </div>
        </div>
        <div className="accordian-section">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  What are fixed assets?
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Fixed assets are long-term tangible assets that a business
                  owns and uses in its operations, such as buildings, machinery,
                  equipment, and vehicles.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  Why is fixed assets management important for businesses?
                </button>
              </h2>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Fixed assets management is important because it ensures
                  efficient utilization of assets, accurate financial reporting,
                  compliance with accounting standards, and optimization of
                  asset lifecycle.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  What does fixed assets management involve?
                </button>
              </h2>
              <div
                id="collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Fixed assets management involves tracking, maintaining, and
                  optimizing fixed assets throughout their lifecycle, including
                  acquisition, depreciation, maintenance, and disposal.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsefour"
                  aria-expanded="false"
                  aria-controls="collapsefour"
                >
                  What are the benefits of fixed assets management services?
                </button>
              </h2>
              <div
                id="collapsefour"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  The benefits of fixed assets management services include
                  <ul>
                    <li>Improved asset utilization and productivity</li>
                    <li>Accurate financial reporting and compliance</li>
                    <li>
                      Cost savings through optimized maintenance and replacement
                      strategies
                    </li>
                    <li>
                      Reduced risk of errors, penalties, and financial
                      misstatements
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsefive"
                  aria-expanded="false"
                  aria-controls="collapsefive"
                >
                  How does fixed assets management help in optimizing asset
                  lifecycle?
                </button>
              </h2>
              <div
                id="collapsefive"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Fixed assets management helps in optimizing asset lifecycle by
                  ensuring proper maintenance, timely replacements, and
                  efficient utilization, thereby maximizing the value of assets
                  over time.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSix"
                  aria-expanded="false"
                  aria-controls="collapseSix"
                >
                  Can fixed assets management services help in reducing costs?
                </button>
              </h2>
              <div
                id="collapseSix"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Yes, fixed assets management services can help in reducing
                  costs by identifying cost-saving opportunities, minimizing
                  asset downtime, and optimizing maintenance and replacement
                  schedules.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSeven"
                  aria-expanded="false"
                  aria-controls="collapseSeven"
                >
                  What types of businesses can benefit from fixed assets
                  management services?
                </button>
              </h2>
              <div
                id="collapseSeven"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Businesses across various industries, including manufacturing,
                  construction, healthcare, hospitality, and transportation, can
                  benefit from fixed assets management services.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseEight"
                  aria-expanded="false"
                  aria-controls="collapseEight"
                >
                  How often should fixed assets be evaluated and updated?
                </button>
              </h2>
              <div
                id="collapseEight"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Fixed assets should be evaluated and updated regularly to
                  ensure accurate financial reporting and compliance with
                  accounting standards. This could be done annually or more
                  frequently as required.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Query />
    </>
  );
};

export default FixedAssetsManagement;
