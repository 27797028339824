import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import company from "./images/company.jpeg";
import company1 from "./images/company1.jpeg";
import company2 from "./images/company2.jpeg";
import bgImage from "../Home/Images/homepage2.jpeg";
import bgImage1 from "../Home/Images/homepage3.jpg";
import bgImage2 from "../Home/Images/homepage4.jpg";
import bgImage3 from "../Home/Images/homepage5.jpg";
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md";
import Query from "../Components/Query/Query";

const ValuationAdvisory = () => {
  const [image, setImage] = useState("");
  const [count, setCount] = useState(0);
  const scollToRef = useRef();
  useEffect(() => {
    scollToRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);
  const Imagesdata = [
    {
      images: bgImage,
      id: 1,
    },
    {
      images: bgImage1,
      id: 2,
    },
    {
      images: bgImage2,
      id: 3,
    },
    {
      images: bgImage3,
      id: 4,
    },
  ];
  useEffect(() => {
    setImage(Imagesdata[0].images);
  }, []);
  // Function is called everytime increment button is clicked
  const handleClick1 = () => {
    // Counter state is incremented
    if (count < 3) {
      setImage(Imagesdata[count + 1]?.images || bgImage);
      setCount(count + 1);
    } else {
      setCount(0);
      setImage(Imagesdata[0]?.images);
    }
  };

  // Function is called everytime decrement button is clicked
  const handleClick2 = () => {
    // Counter state is decremented
    if (count > 0) {
      setImage(Imagesdata[count - 1].images);
      setCount(count - 1);
    } else {
      setCount(3);
      setImage(Imagesdata[3].images);
    }
  };
  return (
    <>
      <section className="corporate-sec">
        <div
          className={`${"info"}`}
          style={{ backgroundImage: `url(${image})` }}
        >
          <h1 className="typo5"> Valuation Advisory </h1>
          <div className="rightIcon">
            <div className="rightIconImageBox" onClick={handleClick1}>
              <MdArrowForwardIos color="#ffffff" />
            </div>
          </div>
          <div className="leftIcon" onClick={handleClick2}>
            <div className="leftIconImageBox">
              <MdArrowBackIos color="#ffffff" />
            </div>
          </div>
        </div>
        <div className="corporate-sec-1  ">
          <div className="corporate-img">
            <div class="hexagon">
              <img src={company} width="200" height="200" />
            </div>
            <div className="nextConatainer">
              <div class="hexagon1">
                <img src={company1} width="200" height="200" />
              </div>
            </div>
            <div class="hexagon2">
              <img src={company2} width="200" height="200" />
            </div>

            {/* <img src="./CorporateImage/img1.jpeg" alt="img" width={500} /> */}
          </div>
          <div className="info-paragraphs">
            <h3 ref={scollToRef} className="audit-header">
              {" "}
              Valuation Advisory Services
            </h3>
            <p className="text">
              Valuation Advisory services encompass a range of consulting and
              advisory services related to business and asset valuation. These
              services provided by us includes valuation feasibility studies,
              fairness opinions, transaction advisory, due diligence support,
              valuation modelling, sensitivity analysis, and expert witness
              testimony. We provide independent, objective assessments and
              recommendations to help our clients make informed decisions for
              mergers & acquisitions. It involves the estimation of the economic
              value of assets, securities, or businesses. This includes valuing
              tangible assets such as real estate or machinery, intangible
              assets such as patents or trademarks, and entire businesses for
              transactions, financial reporting, tax purposes, litigation
              support, and strategic planning.
            </p>
            <p className="text">
              Our experienced team offers expert guidance and analysis to help
              businesses determine the value of their assets, securities, and
              businesses for various purposes.
            </p>
            <p className="text">
              Our approach is to understand that accurate and reliable
              valuations are essential for making informed business decisions.
              Our valuation experts utilize industry-leading methodologies and
              tools to conduct thorough analyses and provide unbiased opinions
              on value.
            </p>
            <br />
            {/* <h4>
              Detailed accounting and management processes covered by us are as
              follows:
            </h4> */}
            <h3 className="submenu-header ">Why Choose PRISH ?</h3>
            <p className="submenu-sub-header ">
              Business Valuation:{" "}
              <span className="text">
                We determine the value of businesses for mergers and
                acquisitions, shareholder transactions, financial reporting, tax
                planning, and strategic decision-making.
              </span>
            </p>

            <p className="submenu-sub-header ">
              Asset Valuation:{" "}
              <span className="text">
                We assess the value of tangible and intangible assets, including
                real estate, machinery, equipment, intellectual property, and
                goodwill.
              </span>{" "}
            </p>
            <p className="submenu-sub-header ">
              Financial Reporting Valuation:{" "}
              <span className="text">
                We provide valuation services for financial reporting purposes,
                including fair value measurements, purchase price allocations,
                and impairment testing under accounting standards such as ASC
                820 (formerly FAS 157), ASC 805 (formerly SFAS 141R), and ASC
                350 (formerly FAS 142).
              </span>
            </p>
            <p className="submenu-sub-header ">
              Transaction Support:{" "}
              <span className="text">
                We offer valuation support for various transactions, including
                mergers, acquisitions, divestitures, joint ventures, and private
                equity investments.
              </span>
            </p>

            <p className="submenu-sub-header ">
              Litigation Support:{" "}
              <span className="text">
                We provide expert witness testimony and litigation support
                services for disputes involving valuation matters, including
                shareholder disputes, marital dissolution, economic damages, and
                bankruptcy proceedings.
              </span>
            </p>
          </div>
        </div>
        <div className="accordian-section">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  What are the approaches that can be adopted for business
                  valuation?
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <p className="submenu-sub-header ">
                    Asset-based valuation:
                    <span className="text">
                      {" "}
                      The asset-based valuation considers the basic business
                      assets in order to estimate the value of the overall
                      business enterprise. This technique is good for
                      corporations, but it will be challenging if applied to a
                      sole proprietorship, because, since the assets belong to
                      the same owner, it will be difficult to separate the same
                      for personal use and business purposes.
                    </span>
                  </p>{" "}
                  <p className="submenu-sub-header ">
                    Market-based valuation:
                    <span className="text">
                      {" "}
                      Under the Market-based valuation, the marketplace is
                      considered for the business value. It compares the value
                      of one’s business to another similar business which is
                      sold recently.
                    </span>
                  </p>{" "}
                  <p className="submenu-sub-header ">
                    Income-based valuation:
                    <span className="text">
                      {" "}
                      The Income-based valuation method is based on the economic
                      principle of expectation to determine the value of a
                      business, i.e. the real value of a business lies in
                      building wealth for the future. Based on this fact, an
                      evaluator ascertains the upcoming cash flow by examining
                      the records and assesses the upcoming financial risk.
                      <br />
                      All these approaches may bring different estimates of
                      value for the same asset at the same time. If you want to
                      know the real estimate, you have to use all these
                      approaches to reach a conclusion.
                    </span>
                  </p>{" "}
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  What are the various situation that lead to valuation of
                  business{" "}
                </button>
              </h2>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  A Businesses valuation is done based on various reasons, of
                  which some of the common reasons are discussed below: -
                  <p className="submenu-sub-header ">
                    Funding or financing:
                    <span className="text">
                      {" "}
                      An objective of your business will help you raise funds
                      for your business while negotiating with banks.
                    </span>
                  </p>{" "}
                  <p className="submenu-sub-header ">
                    Goodwill impairment:
                    <span className="text">
                      {" "}
                      Goodwill impairment happens when the purchase value is
                      greater than its implied fair value. A goodwill valuation
                      will help you understand the impairment loss of the
                      business.
                    </span>
                  </p>{" "}
                  <p className="submenu-sub-header ">
                    Buy or sell contracts:
                    <span className="text">
                      {" "}
                      A business valuation will make sure that you reach the
                      right decision on whether to sell your business or grow
                      your business through acquisition.
                    </span>
                  </p>{" "}
                  <p className="submenu-sub-header ">
                    Mergers or purchases:
                    <span className="text">
                      {" "}
                      At the time of buying or merging with another company, a
                      business valuation will help you determine if the price
                      you have to pay is a fair one or not.
                    </span>
                  </p>{" "}
                  <p className="submenu-sub-header ">
                    Litigation and ownership disputes:
                    <span className="text">
                      {" "}
                      If there is a court case against the value of the
                      business, it will be settled by the court based on the
                      valuation of the business.
                    </span>
                  </p>{" "}
                  <p className="submenu-sub-header ">
                    Shareholder oppression cases:
                    <span className="text">
                      {" "}
                      An independent valuation of the business is required for a
                      fair settlement of ownership issues once any one of the
                      owners decides to quit.
                    </span>
                  </p>{" "}
                  <p className="submenu-sub-header ">
                    Reorganization and bankruptcies:
                    <span className="text">
                      {" "}
                      A valuation report provides your company with
                      certification that can assist with creditor negotiations
                      and provide the basis for a financial restructuring plan
                      at the time of bankruptcy.
                    </span>
                  </p>{" "}
                  <p className="submenu-sub-header ">
                    Business planning:
                    <span className="text">
                      {" "}
                      Your company’s location is a major factor when it comes to
                      valuation because if you are in a prime location, it can
                      be a major advantage for your business.
                    </span>
                  </p>{" "}
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  What are the tools that can be used in business valutation?
                </button>
              </h2>
              <div
                id="collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <p className="submenu-sub-header ">
                    Ratio Analysis:
                    <span className="text">
                      {" "}
                      Ratio analysis is the assessment of line items in the
                      financial statements of a business. It includes GP Ratio,
                      NP Ratio, ROI, Liquidity Ratio, etc.
                    </span>
                  </p>{" "}
                  <p className="submenu-sub-header ">
                    Comparative financial ratio analysis:
                    <span className="text">
                      {" "}
                      A comparative analysis allows company owners to compare
                      their company's financial ratio information with that of a
                      challenging company.
                    </span>
                  </p>{" "}
                  <p className="submenu-sub-header ">
                    Analysis of going concern concept:
                    <span className="text">
                      {" "}
                      The going concern concept is the hypothesis that an entity
                      will remain in business for the predictable future.
                    </span>
                  </p>{" "}
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsefour"
                  aria-expanded="false"
                  aria-controls="collapsefour"
                >
                  What are the general valuation procedures that need to follow?
                </button>
              </h2>
              <div
                id="collapsefour"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <ul>
                    <li>Know the purpose of the action </li>
                    <li>Review historical and forecasted information</li>
                    <li>
                      Analyse balance sheets, income statements and cash flow
                    </li>
                    <li>
                      Conduct meetings with management and discuss company
                      history, operations, employees,
                    </li>
                    <li>customers and competitors and other relevant items</li>
                    <li>
                      Analyse and compare company performance to similar
                      businesses within the industry
                    </li>
                    <li>
                      Assess the budgets, leases, sales contracts, purchase
                      agreements, customer lists, local and national economies
                      and management capabilities.
                    </li>
                    <li>
                      Know the client and the client’s use of the valuation
                    </li>
                    <li>Fix the standard of value and its definition</li>
                    <li>
                      Determine whether discounts and premiums are to be
                      considered
                    </li>
                    <li>Collect information about the industry and economy</li>
                    <li>
                      Check all approaches of value and select the most
                      appropriate method
                    </li>
                    <li>
                      Put into practice the approaches to value through the
                      various methodologies
                    </li>
                    <li>
                      Ensure compliance with professional standards, if
                      pertinent
                    </li>
                    <li>
                      Provide authentic and accurate reports for getting your
                      financial decisions well-done.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Query />
    </>
  );
};

export default ValuationAdvisory;
