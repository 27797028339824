export const CorporateData = [
  {
    id: 1,
    image: "./CorporationImage/img1 (2).jpeg",
    heading: "Due Diligence",
    paragraph:
      "We will ensure comprehensive investigation and analysis of a company's financial records, operations, and other relevant factors conducted as part of a business transaction or investment.",
  },

  {
    id: 2,
    image: "./CorporationImage/img2 (2).jpeg",
    heading: "Fixed Assets Management",
    paragraph:
      "We will monitor, track, and optimize tangible assets such as property, equipment, and machinery to ensure efficient utilization and accurate financial reporting.",
  },

  {
    id: 3,
    image: "./CorporationImage/img3 (2).jpeg",
    heading: "Business Valuation",
    paragraph:
      "We use different methods and consider multiple factors to determine the economic value of a business or company.",
  },

  {
    id: 4,
    image: "./CorporationImage/img4 (2).jpeg",
    heading: "SOP Development & Creation",
    paragraph:
      "We will design and document standard operating procedures (SOPs) to streamline and formalize accounting processes and ensure consistency and compliance within an organization.",
  },
  {
    id: 3,
    image: "./CorporationImage/img1 (2).jpeg",
    heading: "Payroll Management",
    paragraph:
      "We will help in calculating, distributing, and recording your employee salaries, wages, bonuses, and deductions.",
  },
  {
    id: 3,
    image: "./CorporationImage/img2 (2).jpeg",
    heading: "ESR Compliance",
    paragraph:
      "We will ensure adherence to Economic Substance Regulations (ESR), ensuring that entities demonstrate sufficient substance in the jurisdiction where you operate.",
  },
];
