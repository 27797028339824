import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import company from "./images/technology_consulting1.jpeg";
import company1 from "./images/technology_consulting2.jpeg";
import company2 from "./images/technology_consulting3.jpeg";
import bgImage from "../Home/Images/homepage2.jpeg";
import bgImage1 from "../Home/Images/homepage3.jpg";
import bgImage2 from "../Home/Images/homepage4.jpg";
import bgImage3 from "../Home/Images/homepage5.jpg";
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md";
import Query from "../Components/Query/Query";

const TechnologyConsultingServices = () => {
  const [image, setImage] = useState("");
  const [count, setCount] = useState(0);
  const scollToRef = useRef();
  useEffect(() => {
    scollToRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);
  const Imagesdata = [
    {
      images: bgImage,
      id: 1,
    },
    {
      images: bgImage1,
      id: 2,
    },
    {
      images: bgImage2,
      id: 3,
    },
    {
      images: bgImage3,
      id: 4,
    },
  ];
  useEffect(() => {
    setImage(Imagesdata[0].images);
  }, []);
  // Function is called everytime increment button is clicked
  const handleClick1 = () => {
    // Counter state is incremented
    if (count < 3) {
      setImage(Imagesdata[count + 1]?.images || bgImage);
      setCount(count + 1);
    } else {
      setCount(0);
      setImage(Imagesdata[0]?.images);
    }
  };

  // Function is called everytime decrement button is clicked
  const handleClick2 = () => {
    // Counter state is decremented
    if (count > 0) {
      setImage(Imagesdata[count - 1].images);
      setCount(count - 1);
    } else {
      setCount(3);
      setImage(Imagesdata[3].images);
    }
  };
  return (
    <>
      <section className="corporate-sec">
        <div
          className={`${"info"}`}
          style={{ backgroundImage: `url(${image})` }}
        >
          <h1 className="typo5">Technology Consulting</h1>
          <div className="rightIcon">
            <div className="rightIconImageBox" onClick={handleClick1}>
              <MdArrowForwardIos color="#ffffff" />
            </div>
          </div>
          <div className="leftIcon" onClick={handleClick2}>
            <div className="leftIconImageBox">
              <MdArrowBackIos color="#ffffff" />
            </div>
          </div>
        </div>
        <div className="corporate-sec-1  ">
          <div className="corporate-img">
            <div class="hexagon">
              <img src={company} width="200" height="200" />
            </div>
            <div className="nextConatainer">
              <div class="hexagon1">
                <img src={company1} width="200" height="200" />
              </div>
            </div>
            <div class="hexagon2">
              <img src={company2} width="200" height="200" />
            </div>

            {/* <img src="./CorporateImage/img1.jpeg" alt="img" width={500} /> */}
          </div>
          <div className="info-paragraphs">
            <h3 ref={scollToRef} className="audit-header">
              Technology Consulting
            </h3>
            <p className="text">
              Digital transformation strategy development, Technology
              assessment, and implementation based on the business operations
              and requirements to ease the compliances. Our core experience in
              IT helps our clients to reduce extensive manual efforts and
              generate reports within a click of seconds. We offer comprehensive
              technology consulting services to help businesses leverage
              cutting-edge technologies, optimize their IT infrastructure, and
              drive digital transformation.
            </p>
            <p className="text">
              Our expert team provides tailored solutions to address the unique
              needs and challenges of each client, enabling them to stay ahead
              in today's rapidly evolving digital landscape.
            </p>
            <p className="text">
              Our approach is to understand that technology is a critical
              enabler of business success and it’s collaborative and
              results-oriented, focusing on delivering innovative solutions that
              drive business value and competitive advantage.
            </p>
            <br />
            {/* <h4>
              Detailed accounting and management processes covered by us are as
              follows:
            </h4> */}
            <h3 className="submenu-header ">Why Choose PRISH ?</h3>
            <p className="submenu-sub-header ">
              IT Strategy and Roadmap:{" "}
              <span className="text">
                We help businesses develop IT strategies and roadmaps aligned
                with their business goals, ensuring that technology investments
                support their long-term objectives.
              </span>
            </p>

            <p className="submenu-sub-header ">
              Digital Transformation:{" "}
              <span className="text">
                Our team assists businesses in embracing digital technologies
                and processes to enhance customer experience, streamline
                operations, and drive growth.
              </span>{" "}
            </p>
            <p className="submenu-sub-header ">
              Cybersecurity:{" "}
              <span className="text">
                We assess and strengthen cybersecurity posture through proactive
                risk assessments, security audits, and implementation of robust
                security controls and protocols.
              </span>
            </p>
            <p className="submenu-sub-header ">
              Cloud Computing:{" "}
              <span className="text">
                We guide businesses in migrating to the cloud, optimizing cloud
                infrastructure, and leveraging cloud services for scalability,
                agility, and cost-efficiency.
              </span>
            </p>
            <p className="submenu-sub-header ">
              Data Analytics and Business Intelligence:{" "}
              <span className="text">
                We help businesses harness the power of data analytics and
                business intelligence to gain valuable insights, make informed
                decisions, and drive innovation.
              </span>
            </p>
          </div>
        </div>
        <div className="accordian-section">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  What are technology consulting services?
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Technology consulting services involve providing expert advice
                  and support to businesses in leveraging technology to achieve
                  their strategic objectives, improve operations, and drive
                  growth.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  Why would a business need technology consulting service?
                </button>
              </h2>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Businesses may need technology consulting services to stay
                  competitive in the digital age, optimize their IT
                  infrastructure, implement new technologies, and address
                  specific challenges or opportunities.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  How can technology consulting services benefit my business?
                </button>
              </h2>
              <div
                id="collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  The benefits of technology consulting services include:
                  <ul>
                    <li>Improved efficiency and productivity</li>
                    <li>Enhanced customer experience</li>
                    <li>Increased security and compliance</li>
                    <li>Cost savings and return on investment (ROI)</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsefour"
                  aria-expanded="false"
                  aria-controls="collapsefour"
                >
                  How can technology consulting services help with digital
                  transformation?
                </button>
              </h2>
              <div
                id="collapsefour"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Technology consulting services help businesses embrace digital
                  technologies and processes to enhance customer experience,
                  streamline operations, and drive growth through digital
                  transformation initiatives.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsefive"
                  aria-expanded="false"
                  aria-controls="collapsefive"
                >
                  Can technology consulting services help with cybersecurity?
                </button>
              </h2>
              <div
                id="collapsefive"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Yes, technology consulting services can help businesses
                  strengthen their cybersecurity posture through proactive risk
                  assessments, security audits, and implementation of robust
                  security controls and protocols.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSix"
                  aria-expanded="false"
                  aria-controls="collapseSix"
                >
                  What role does cloud computing play in technology consulting
                  services?
                </button>
              </h2>
              <div
                id="collapseSix"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Cloud computing is a key focus area of technology consulting
                  services, enabling businesses to leverage scalable, agile, and
                  cost-effective cloud infrastructure and services for various
                  business needs.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSeven"
                  aria-expanded="false"
                  aria-controls="collapseSeven"
                >
                  How do technology consulting services contribute to cost
                  savings?
                </button>
              </h2>
              <div
                id="collapseSeven"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Technology consulting services contribute to cost savings
                  through optimization of IT infrastructure, automation of
                  processes, and strategic technology investments that deliver a
                  positive return on investment (ROI).
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseEight"
                  aria-expanded="false"
                  aria-controls="collapseEight"
                >
                  How can businesses measure the ROI of technology consulting
                  services?
                </button>
              </h2>
              <div
                id="collapseEight"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Businesses can measure the ROI of technology consulting
                  services by evaluating improvements in efficiency,
                  productivity, customer satisfaction, security posture, and
                  cost savings achieved through technology initiatives.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Query />
    </>
  );
};

export default TechnologyConsultingServices;
