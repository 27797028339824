import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from ".//Home/Home";
import About from ".//About/About";
import Team from ".//Team/Team";
import Blog from ".//Blog/Blog";
import Reach from ".//Reach/Reach";
import Nav from ".//Components/Header";
import Footer from ".//Components/Footer";
import Audit from ".//Audit/Audit";
import Taxation from ".//Taxation/Taxation";
import Bookkeeping from ".//Bookkeeping/Bookkeeping";
import Corporate from ".//CorporatePage/Corporate";
import Corporation from ".//CorporationPages/Corporation";
import CorporateAdvisory from ".//CorporateAdvisory/CorporateAdvisory";
import TaxServices from ".//TaxServices/TaxServices";
import BusinessServices from "./BusinessServices/BusinessServices";
import Incorporation from "./Incorporate/Incorporate";
import Merger from "./Merger/Merger";
import SatutoryAudit from "./InnerSubMenus/SatutaryAudit";
import ConcurrentAudit from "./InnerSubMenus/ConcurrentAudit";
import ForensicAudit from "./InnerSubMenus/Forensic";
import InventoryAudit from "./InnerSubMenus/InventoryAudit";
import MysteryAudit from "./InnerSubMenus/MysteryAudit";
import InternalAudit from "./InnerSubMenus/InternalAudit";
import StandardAudit from "./InnerSubMenus/StandardOperating";
import RiskControl from "./InnerSubMenus/RiskControl";
import Vat from "./InnerSubMenus/Vat";
import UaeTax from "./InnerSubMenus/UaeTax";
import TransferPricing from "./InnerSubMenus/TransferPricing";
import TaxResidency from "./InnerSubMenus/TaxResidency";
import DueDiligence from "./InnerSubMenus/DueDiligence";
import FinancialBudget from "./InnerSubMenus/FinancialBudget";
import StrategicPlanning from "./InnerSubMenus/StrategicPlanning";
import FixedAssetsManagement from "./InnerSubMenus/FixedAssetsManagement";
import TechnologyConsulting from "./InnerSubMenus/TechnologyConsulting";
import CfoServices from "./InnerSubMenus/CfoServices";
import TechnologyConsultingServices from "./InnerSubMenus/TechnologyConsultingServices";
import TaxationService from "./CorporatePage/TaxationService";
import GoldAudit from "./InnerSubMenus/GoldAudit";
import ReraAudit from "./InnerSubMenus/ReraAudit";
import SalesTaxAudit from "./InnerSubMenus/SalesTaxAudit";
import SystemTaxAudit from "./InnerSubMenus/SystemTaxAudit";
import TransactionAudit from "./InnerSubMenus/TransactionAudit";
import Restructuring from "./InnerSubMenus/Restructuring";
import ValuationAdvisory from "./InnerSubMenus/ValuationAdvisory";
import SpecialAuditService from "./CorporatePage/SpecialAudits";

const App = () => {
  return (
    <div>
      <BrowserRouter>
        <Nav />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/team" element={<Team />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/reach" element={<Reach />} />
          <Route path="/risk-advisory-services" element={<Taxation />} />
          <Route path="/auditing-assurance-services" element={<Corporate />} />
          <Route path="/anti-money-laundering" element={<BusinessServices />} />
          {/* <Route path="/tax-services" element={<TaxServices />} /> */}
          <Route
            path="/accounting-outsourcing-services"
            element={<Corporation />}
          />
          <Route path="/compliance-services" element={<Audit />} />
          <Route
            path="/company-incorporation-services"
            element={<Incorporation />}
          />
          <Route path="/merger-acquisitions" element={<Merger />} />

          <Route path="/technology-consulting" element={<Bookkeeping />} />
          <Route path="/corporate-advisory" element={<CorporateAdvisory />} />
          <Route path="/tax-services" element={<TaxationService />} />

          {/* Inner Pages */}
          <Route path="/satutory-audit" element={<SatutoryAudit />} />
          <Route path="/concurrent-audit" element={<ConcurrentAudit />} />
          <Route path="/forensic-audit" element={<ForensicAudit />} />
          <Route path="/inventory-audit" element={<InventoryAudit />} />
          <Route path="/mystery-audit" element={<MysteryAudit />} />
          <Route path="/internal-audit" element={<InternalAudit />} />
          <Route path="/standard-operating" element={<StandardAudit />} />
          <Route path="/risk-control" element={<RiskControl />} />
          <Route path="/value-added-tax" element={<Vat />} />
          <Route path="/uae-corporate-tax" element={<UaeTax />} />
          <Route path="/transfer-pricing" element={<TransferPricing />} />
          <Route path="/tax-residency-certificate" element={<TaxResidency />} />
          <Route path="/due-diligence" element={<DueDiligence />} />
          {/* Consultancy */}
          <Route path="/strategic-planning" element={<StrategicPlanning />} />
          <Route
            path="/financial-budgeting-forecasting"
            element={<FinancialBudget />}
          />
          <Route
            path="/fixed-assets-management"
            element={<FixedAssetsManagement />}
          />
          <Route
            path="/technology-consulting-services"
            element={<TechnologyConsultingServices />}
          />
          <Route path="/cfo-services" element={<CfoServices />} />
          <Route path="/gold-audit" element={<GoldAudit />} />
          <Route path="/rera-audit" element={<ReraAudit />} />
          <Route path="/sales-tax-audit" element={<SalesTaxAudit />} />
          <Route path="/system-tax-audit" element={<SystemTaxAudit />} />

          <Route path="/transaction-audit" element={<TransactionAudit />} />
          <Route path="/restructuring" element={<Restructuring />} />

          <Route path="/value-advisory" element={<ValuationAdvisory />} />
          <Route path="/special-audit" element={<SpecialAuditService />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
};

export default App;
