import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import company from "./images/gold_audit1.jpeg";
import company1 from "./images/gold_audit2.jpeg";
import company2 from "./images/gold_audit3.jpeg";
import bgImage from "../Home/Images/homepage2.jpeg";
import bgImage1 from "../Home/Images/homepage3.jpg";
import bgImage2 from "../Home/Images/homepage4.jpg";
import bgImage3 from "../Home/Images/homepage5.jpg";
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md";
import Query from "../Components/Query/Query";

const GoldAudit = () => {
  const [image, setImage] = useState("");
  const [count, setCount] = useState(0);
  const scollToRef = useRef();
  useEffect(() => {
    scollToRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);
  const Imagesdata = [
    {
      images: bgImage,
      id: 1,
    },
    {
      images: bgImage1,
      id: 2,
    },
    {
      images: bgImage2,
      id: 3,
    },
    {
      images: bgImage3,
      id: 4,
    },
  ];
  useEffect(() => {
    setImage(Imagesdata[0].images);
  }, []);
  // Function is called everytime increment button is clicked
  const handleClick1 = () => {
    // Counter state is incremented
    if (count < 3) {
      setImage(Imagesdata[count + 1]?.images || bgImage);
      setCount(count + 1);
    } else {
      setCount(0);
      setImage(Imagesdata[0]?.images);
    }
  };

  // Function is called everytime decrement button is clicked
  const handleClick2 = () => {
    // Counter state is decremented
    if (count > 0) {
      setImage(Imagesdata[count - 1].images);
      setCount(count - 1);
    } else {
      setCount(3);
      setImage(Imagesdata[3].images);
    }
  };
  return (
    <>
      <section className="corporate-sec">
        <div
          className={`${"info"}`}
          style={{ backgroundImage: `url(${image})` }}
        >
          <h1 className="typo5">Gold Audit</h1>
          <div className="rightIcon">
            <div className="rightIconImageBox" onClick={handleClick1}>
              <MdArrowForwardIos color="#ffffff" />
            </div>
          </div>
          <div className="leftIcon" onClick={handleClick2}>
            <div className="leftIconImageBox">
              <MdArrowBackIos color="#ffffff" />
            </div>
          </div>
        </div>
        <div className="corporate-sec-1  ">
          <div className="corporate-img">
            <div class="hexagon">
              <img src={company} width="200" height="200" />
            </div>
            <div className="nextConatainer">
              <div class="hexagon1">
                <img src={company1} width="200" height="200" />
              </div>
            </div>
            <div class="hexagon2">
              <img src={company2} width="200" height="200" />
            </div>

            {/* <img src="./CorporateImage/img1.jpeg" alt="img" width={500} /> */}
          </div>
          <div className="info-paragraphs">
            <h3 ref={scollToRef} className="audit-header">
              Gold Audit
            </h3>
            <p className="text">
              <b>"Gold Audit"</b> typically refers to the process of auditing
              gold and jewelry traders or dealers to ensure compliance with
              relevant regulations and standards due to the significant role of
              the gold and jewelry industry in the country's economy.Our
              experienced team conducts thorough audits to ensure compliance
              with regulations, reporting accuracy, and gold transaction
              transparency
            </p>
            <p>
              Our approach is to understand the importance of accuracy and
              integrity in gold transactions. Our gold audit services are
              designed to provide businesses with assurance regarding the
              authenticity, quality, and compliance of their gold-related
              activities.
            </p>

            <br />
            {/* <h4>
              Detailed accounting and management processes covered by us are as
              follows:
            </h4> */}
            <h3 className="submenu-header ">Why Choose PRISH ?</h3>
            <p className="submenu-sub-header ">
              Transaction Verification:{" "}
              <span className="text">
                We verify gold transactions to ensure accuracy, completeness,
                and compliance with regulatory requirements.
              </span>
            </p>

            <p className="submenu-sub-header ">
              Inventory Inspection:{" "}
              <span className="text">
                We conduct physical inspections of gold inventory to verify
                quantities, qualities, and valuations.
              </span>{" "}
            </p>
            <p className="submenu-sub-header ">
              Compliance Review:{" "}
              <span className="text">
                We review gold-related documentation and records to ensure
                compliance with regulatory standards, including anti-money
                laundering (AML) and Know Your Customer (KYC) regulations.
              </span>
            </p>
            <p className="submenu-sub-header ">
              Risk Assessment:{" "}
              <span className="text">
                We assess potential risks associated with gold transactions and
                provide recommendations to mitigate them effectively.
              </span>
            </p>
            <p className="submenu-sub-header ">
              Fraud Detection:{" "}
              <span className="text">
                We use advanced techniques to detect and prevent fraud in gold
                trading activities, safeguarding our clients' interests.
              </span>
            </p>
          </div>
        </div>
        <div className="accordian-section">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  What is a gold audit, and why is it necessary for businesses?
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  A gold audit is the process of examining and verifying
                  gold-related transactions, inventory, and financial records to
                  ensure compliance, accuracy, and transparency in gold trading
                  activities. Businesses must maintain integrity, mitigate
                  risks, and comply with regulatory requirements.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  What types of businesses can benefit from gold audit services?
                </button>
              </h2>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Gold audit services are beneficial for a wide range of
                  businesses involved in the gold industry, including jewelry
                  manufacturers, bullion dealers, refineries, and mining
                  companies.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  What does a gold audit typically involve?
                </button>
              </h2>
              <div
                id="collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  A gold audit typically involves transaction verification,
                  inventory inspection, compliance review, risk assessment, and
                  fraud detection to ensure the accuracy, transparency, and
                  compliance of gold trading activities.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsefour"
                  aria-expanded="false"
                  aria-controls="collapsefour"
                >
                  How often should businesses conduct gold audits?
                </button>
              </h2>
              <div
                id="collapsefour"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  The frequency of gold audits depends on the size and nature of
                  the business, as well as regulatory requirements. However, it
                  is advisable for businesses to conduct regular audits to
                  maintain compliance and ensure the integrity of their gold
                  trading activities.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsefive"
                  aria-expanded="false"
                  aria-controls="collapsefive"
                >
                  What are the benefits of conducting a gold audit?
                </button>
              </h2>
              <div
                id="collapsefive"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <p className="submenu-sub-header ">
                    Compliance Assurance:
                    <span className="text">
                      {" "}
                      Gold audits help businesses ensure compliance with
                      regulatory requirements, reducing the risk of penalties
                      and legal issues.
                    </span>
                  </p>{" "}
                  <p className="submenu-sub-header ">
                    Accuracy and Transparency:
                    <span className="text">
                      {" "}
                      Audits provide assurance regarding the accuracy and
                      transparency of gold transactions, enhancing stakeholders'
                      confidence.
                    </span>
                  </p>{" "}
                  <p className="submenu-sub-header ">
                    Risk Mitigation:
                    <span className="text">
                      {" "}
                      By identifying and addressing potential risks, audits help
                      businesses mitigate financial and reputational risks
                      associated with gold trading.
                    </span>
                  </p>{" "}
                  <p className="submenu-sub-header ">
                    Operational Efficiency:
                    <span className="text">
                      {" "}
                      Audits streamline gold trading operations, improve
                      internal controls, and enhance overall efficiency.
                    </span>
                  </p>{" "}
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSix"
                  aria-expanded="false"
                  aria-controls="collapseSix"
                >
                  How can businesses prepare for a gold audit?
                </button>
              </h2>
              <div
                id="collapseSix"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Businesses can prepare for a gold audit by maintaining
                  accurate records, implementing robust internal controls, and
                  ensuring compliance with regulatory requirements.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Query />
    </>
  );
};

export default GoldAudit;
