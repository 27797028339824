import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import company from "./images/tax_residency1.jpeg";
import company1 from "./images/tax_residency2.jpeg";
import company2 from "./images/tax_residency3.jpeg";
import bgImage from "../Home/Images/homepage2.jpeg";
import bgImage1 from "../Home/Images/homepage3.jpg";
import bgImage2 from "../Home/Images/homepage4.jpg";
import bgImage3 from "../Home/Images/homepage5.jpg";
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md";
import Query from "../Components/Query/Query";

const TaxResidency = () => {
  const [image, setImage] = useState("");
  const [count, setCount] = useState(0);
  const scollToRef = useRef();
  useEffect(() => {
    scollToRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);
  const Imagesdata = [
    {
      images: bgImage,
      id: 1,
    },
    {
      images: bgImage1,
      id: 2,
    },
    {
      images: bgImage2,
      id: 3,
    },
    {
      images: bgImage3,
      id: 4,
    },
  ];
  useEffect(() => {
    setImage(Imagesdata[0].images);
  }, []);
  // Function is called everytime increment button is clicked
  const handleClick1 = () => {
    // Counter state is incremented
    if (count < 3) {
      setImage(Imagesdata[count + 1]?.images || bgImage);
      setCount(count + 1);
    } else {
      setCount(0);
      setImage(Imagesdata[0]?.images);
    }
  };

  // Function is called everytime decrement button is clicked
  const handleClick2 = () => {
    // Counter state is decremented
    if (count > 0) {
      setImage(Imagesdata[count - 1].images);
      setCount(count - 1);
    } else {
      setCount(3);
      setImage(Imagesdata[3].images);
    }
  };
  return (
    <>
      <section className="corporate-sec">
        <div
          className={`${"info"}`}
          style={{ backgroundImage: `url(${image})` }}
        >
          <h1 className="typo5">Tax Residency Certificate</h1>
          <div className="rightIcon">
            <div className="rightIconImageBox" onClick={handleClick1}>
              <MdArrowForwardIos color="#ffffff" />
            </div>
          </div>
          <div className="leftIcon" onClick={handleClick2}>
            <div className="leftIconImageBox">
              <MdArrowBackIos color="#ffffff" />
            </div>
          </div>
        </div>
        <div className="corporate-sec-1  ">
          <div className="corporate-img">
            <div class="hexagon">
              <img src={company} width="200" height="200" />
            </div>
            <div className="nextConatainer">
              <div class="hexagon1">
                <img src={company1} width="200" height="200" />
              </div>
            </div>
            <div class="hexagon2">
              <img src={company2} width="200" height="200" />
            </div>

            {/* <img src="./CorporateImage/img1.jpeg" alt="img" width={500} /> */}
          </div>
          <div className="info-paragraphs">
            <h3 ref={scollToRef} className="audit-header">
              Tax Residency Certificate
            </h3>
            <p className="text">
              A tax residency (Domicile) certificate in UAE is issued by the
              International Financial Relations and Organizations Department
              which permits eligible government entities, companies, and
              individuals to take advantage of agreements of double taxation
              avoidance. We at PRISH provide professional assistance in
              coordinating with the ministry offices and availing the tax
              residency (domicile) certificate.
            </p>
            <p className="text">
              Our expert team offers tailored solutions to ensure compliance
              with local regulations and facilitate the TRC application process
              efficiently.
            </p>
            <p className="text">
              Our approach is to understand the importance of TRCs for
              individuals and businesses for various purposes such as accessing
              tax treaty benefits, avoiding double taxation, and complying with
              regulatory requirements. It is designed to streamline the
              application process and provide clients with the necessary support
              and guidance.
            </p>
            <br />
            {/* <h4>
              Detailed accounting and management processes covered by us are as
              follows:
            </h4> */}
            <h3 className="submenu-header ">Why Choose PRISH ?</h3>
            <p className="submenu-sub-header ">
              Consultation:{" "}
              <span className="text">
                We provide expert consultation to determine eligibility for
                obtaining a TRC and assess the requirements based on individual
                or business circumstances.
              </span>
            </p>

            <p className="submenu-sub-header ">
              Documentation Assistance:{" "}
              <span className="text">
                Our team assists in preparing the required documentation,
                including application forms, supporting financial documents, and
                any other necessary paperwork.
              </span>{" "}
            </p>
            <p className="submenu-sub-header ">
              Application Submission:{" "}
              <span className="text">
                We handle the entire application process, including submission
                to the relevant tax authorities, ensuring completeness and
                accuracy to expedite the issuance of the TRC.
              </span>
            </p>
            <p className="submenu-sub-header ">
              Follow-up and Support:{" "}
              <span className="text">
                We follow up with the tax authorities on behalf of our clients
                and provide ongoing support throughout the application process,
                addressing any queries or additional requirements that may
                arise.
              </span>
            </p>
          </div>
        </div>
        <div className="accordian-section">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  What is a Tax Residency Certificate (TRC)?
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  A Tax Residency Certificate (TRC) is an official document
                  issued by the tax authorities of a country confirming an
                  individual or entity's tax residency status in that country
                  for a specific period.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  Why is a Tax Residency Certificate (TRC) important?
                </button>
              </h2>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  A TRC is important for individuals and businesses to establish
                  their tax residency status in a particular country, which is
                  essential for accessing tax treaty benefits, avoiding double
                  taxation, and complying with regulatory requirements.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  Who can apply for a Tax Residency Certificate (TRC)?
                </button>
              </h2>
              <div
                id="collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Individuals and businesses that meet the tax residency
                  criteria of a particular country can apply for a TRC. The
                  criteria may vary depending on the country's tax laws and
                  regulations.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsefour"
                  aria-expanded="false"
                  aria-controls="collapsefour"
                >
                  What are the benefits of obtaining a Tax Residency Certificate
                  (TRC)?
                </button>
              </h2>
              <div
                id="collapsefour"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  The benefits of obtaining a TRC include:
                  <ul>
                    <li>
                      Access to tax treaty benefits, such as reduced withholding
                      tax rates on dividends, interest, and royalties.
                    </li>
                    <li>
                      Avoidance of double taxation on income earned in different
                      jurisdictions.
                    </li>
                    <li>
                      Compliance with regulatory requirements, including proof
                      of tax residency for banking and investment purposes.
                    </li>
                    <li>
                      Facilitated business operations and cross-border
                      transactions.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsefive"
                  aria-expanded="false"
                  aria-controls="collapsefive"
                >
                  What documents are required to apply for a Tax Residency
                  Certificate (TRC)?
                </button>
              </h2>
              <div
                id="collapsefive"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  The documents required for TRC application may include proof
                  of identity, proof of address, evidence of tax payments, and
                  supporting financial documents, depending on the country's
                  requirements.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSix"
                  aria-expanded="false"
                  aria-controls="collapseSix"
                >
                  How long does it take to obtain a Tax Residency Certificate
                  (TRC)?
                </button>
              </h2>
              <div
                id="collapseSix"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  The processing time for TRC applications varies depending on
                  the country and the complexity of the application.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSeven"
                  aria-expanded="false"
                  aria-controls="collapseSeven"
                >
                  Can a Tax Residency Certificate (TRC) be used to claim tax
                  treaty benefits?
                </button>
              </h2>
              <div
                id="collapseSeven"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Yes, a TRC serves as proof of tax residency and can be used to
                  claim tax treaty benefits provided under double taxation
                  avoidance agreements between countries.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseEight"
                  aria-expanded="false"
                  aria-controls="collapseEight"
                >
                  Is a Tax Residency Certificate (TRC) valid indefinitely?
                </button>
              </h2>
              <div
                id="collapseEight"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  TRCs are typically valid for a specific period, such as one
                  year, and may need to be renewed annually or as required by
                  the tax authorities.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseNine"
                  aria-expanded="false"
                  aria-controls="collapseNine"
                >
                  How can businesses benefit from obtaining a Tax Residency
                  Certificate (TRC)?
                </button>
              </h2>
              <div
                id="collapseNine"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Businesses can benefit from TRCs by accessing tax treaty
                  benefits, reducing tax liabilities, facilitating cross-border
                  transactions, and ensuring compliance with regulatory
                  requirements in various jurisdictions.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Query />
    </>
  );
};

export default TaxResidency;
