import React, { useEffect, useRef, useState } from "react";
import "./incorporate.css";
import company from "./images/company.jpeg";
import company1 from "./images/company1.jpeg";
import company2 from "./images/company2.jpeg";
import bgImage from "../Home/Images/homepage2.jpeg";
import bgImage1 from "../Home/Images/homepage3.jpg";
import bgImage2 from "../Home/Images/homepage4.jpg";
import bgImage3 from "../Home/Images/homepage5.jpg";
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md";
import Query from "../Components/Query/Query";

const Incorporation = () => {
  const [image, setImage] = useState("");
  const [count, setCount] = useState(0);
  const scollToRef = useRef();
  useEffect(() => {
    scollToRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);
  const Imagesdata = [
    {
      images: bgImage,
      id: 1,
    },
    {
      images: bgImage1,
      id: 2,
    },
    {
      images: bgImage2,
      id: 3,
    },
    {
      images: bgImage3,
      id: 4,
    },
  ];
  useEffect(() => {
    setImage(Imagesdata[0].images);
  }, []);
  // Function is called everytime increment button is clicked
  const handleClick1 = () => {
    // Counter state is incremented
    if (count < 3) {
      setImage(Imagesdata[count + 1]?.images || bgImage);
      setCount(count + 1);
    } else {
      setCount(0);
      setImage(Imagesdata[0]?.images);
    }
  };

  // Function is called everytime decrement button is clicked
  const handleClick2 = () => {
    // Counter state is decremented
    if (count > 0) {
      setImage(Imagesdata[count - 1].images);
      setCount(count - 1);
    } else {
      setCount(3);
      setImage(Imagesdata[3].images);
    }
  };
  return (
    <>
      <section className="corporate-sec">
        <div
          className={`${"info"}`}
          style={{ backgroundImage: `url(${image})` }}
        >
          <h1 className="typo5">Company Incorporation</h1>
          <div className="rightIcon">
            <div className="rightIconImageBox" onClick={handleClick1}>
              <MdArrowForwardIos color="#ffffff" />
            </div>
          </div>
          <div className="leftIcon" onClick={handleClick2}>
            <div className="leftIconImageBox">
              <MdArrowBackIos color="#ffffff" />
            </div>
          </div>
        </div>
        <div className="corporate-sec-1  ">
          <div className="corporate-img">
            <div class="hexagon">
              <img src={company} width="200" height="200" />
            </div>
            <div className="nextConatainer">
              <div class="hexagon1">
                <img src={company1} width="200" height="200" />
              </div>
            </div>
            <div class="hexagon2">
              <img src={company2} width="200" height="200" />
            </div>

            {/* <img src="./CorporateImage/img1.jpeg" alt="img" width={500} /> */}
          </div>
          <div className="info-paragraphs">
            <h3 ref={scollToRef} className="audit-header">
              Company Incorporation Services
            </h3>
            <p className="text">
              Company incorporation services in the United Arab Emirates (UAE)
              involve assisting businesses and entrepreneurs in establishing
              legal entities to conduct business activities within the country.
            </p>
            <p className="text">
              Based on understanding our clients requirements, we at PRISH
              advise clients on the most suitable business structure, ownership
              preferences, operating environments such as freezone, mainland and
              other regulatory requirements.
            </p>
            <p className="text">
              Through our professional expertise team members, we navigate the
              complex legal and regulatory landscape more effectively,
              streamline the incorporation process, and establish a strong legal
              foundation for operations for our various clients in the country.
            </p>
            <br />
            {/* <h4>
              Detailed accounting and management processes covered by us are as
              follows:
            </h4> */}
            <p className="text">
              Business Structure and Ownership Consultation{" "}
            </p>
            <p className="text">Document Preparation and Submission</p>
            <p className="text">Trade Name Reservation and Approval</p>
            <p className="text">License Application and Processing</p>
            <p className="text">Office Space and Facilities</p>
            <p className="text">Visa and Immigration Services</p>
            <p className="text">Bank Account Opening</p>
            <p className="text">
              Ongoing Compliance and Support including trademark
              , copyright etc.
            </p>
          </div>
        </div>
        <div className="accordian-section">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseone"
                  aria-expanded="false"
                  aria-controls="collapseone"
                >
                  What type of UAE companies can I start?
                </button>
              </h2>
              <div
                id="collapseone"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  UAE allows company formation in the free zone, mainland, or
                  offshore. Business setup in the UAE mainland requires a local
                  sponsor and the license is issued by DED.
                  <br />
                  Free zone companies can operate within a limited geographical
                  region and the license is issued by a particular free zone
                  authority.
                  <br />
                  Offshore businesses in UAE allow your company to trade outside
                  the UAE as well as make efforts to safeguard your wealth and
                  assets. Offshore companies get a certificate of incorporation
                  and not a trade license.
                  <br />
                  All types of companies can be started in UAE and more than a
                  thousand activities are allowed by the Economic Department.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  How to renew my business license on the mainland?
                </button>
              </h2>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  If you want to renew your Dubai commercial license, the
                  applicant should submit the DED registered certificate with
                  the necessary approval from the Ministry of Economy and
                  National Media Company. If you want to renew your Dubai trade
                  license, then you should submit a valid Ejari certificate and
                  other lease contracts.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  Why should I start my company in UAE?
                </button>
              </h2>
              <div
                id="collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  UAE is a perfectly located biggest market in the world. It
                  serves as a perfect destination to invest in different kinds
                  of business. The business owners are free from taxes, and they
                  can expect exceptional logistics in all the emirates. You
                  don’t have any capital restrictions while starting your
                  business. Financial freedom is an attractive point that brings
                  in employees from foreign districts.
                  <br />
                  UAE has mind-blowing business opportunities; a business setup
                  firm in UAE can guide you through the entire company formation
                  process.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  How long will it take to set up a free zone company?
                </button>
              </h2>
              <div
                id="collapseFour"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Being a trustworthy and professional business consultant, we
                  ensure fast and reliable business setup in UAE-free zones.
                  Usually, it takes around 3 to 10 days to set up your free zone
                  company; it may differ according to the selected free zone. We
                  make sure that all your documents are legal and that concerned
                  authorities have attested to them.
                  <br />
                  If you’re not consulting a business consultant, it will take
                  up to a few weeks and it becomes difficult to clear all the
                  legal procedures. So, consider PRISH for your free zone
                  business setup.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFive"
                  aria-expanded="false"
                  aria-controls="collapseFive"
                >
                  How to start an LLC company in UAE?
                </button>
              </h2>
              <div
                id="collapseFive"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  To establish your LLC Company in Dubai mainland or anywhere
                  else in UAE, you have to reserve a trade name and other
                  necessary preliminary approvals from concerned authorities.
                  You get an MOA from the company and also, submit the lease
                  contract to the licensing authority. Also, find a local
                  partner and rented office space to start your business.
                  <br />
                  PRISH offer few inclusive and affordable packages according to
                  your business setup requirements.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSix"
                  aria-expanded="false"
                  aria-controls="collapseSix"
                >
                  What kind of company structure should I choose?
                </button>
              </h2>
              <div
                id="collapseSix"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  This depends on your needs, as well as the needs of your
                  business. You could set up your company as a Sole
                  Establishment (this is the same as a Sole Proprietorship), as
                  a Limited Liability Partnership, a Limited Liability Company,
                  a Private Limited Company, or a Professional Services Company.
                  <br />
                  You also have the option of setting up your company on the
                  mainland, in a free zone (only limited liability company
                  structures are permitted here), or offshore.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSeven"
                  aria-expanded="false"
                  aria-controls="collapseSeven"
                >
                  Do I need to rent an office for my business?
                </button>
              </h2>
              <div
                id="collapseSeven"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  A physical presence is mandatory for companies established on
                  the mainland and in free zones. However, an offshore company
                  is not permitted to lease office space in the UAE.
                  <br />
                  The good news is that there is affordable office space for
                  everyone. If you want to keep your initial costs down, you
                  could look at renting an office or any number of dedicated
                  desks in a co-working space on the mainland. These are all
                  fully furnished and ready to move in. In a free zone, you need
                  to rent at least a desk in a shared office space. The added
                  advantage is that some free zones allow you to rent a
                  flexi-desk, that is a desk you will be using for say, ten
                  hours in a week. You still get your dedicated PO Box so that
                  you have a unique address.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Query />
    </>
  );
};

export default Incorporation;
