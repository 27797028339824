import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import s1 from "./images/s1.jpeg";
import s2 from "./images/s2.jpeg";
import s3 from "./images/s3.jpeg";
import bgImage from "../Home/Images/homepage2.jpeg";
import bgImage1 from "../Home/Images/homepage3.jpg";
import bgImage2 from "../Home/Images/homepage4.jpg";
import bgImage3 from "../Home/Images/homepage5.jpg";
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md";
import Query from "../Components/Query/Query";

const SatutoryAudit = () => {
  const [image, setImage] = useState("");
  const [count, setCount] = useState(0);
  const scollToRef = useRef();

  const Imagesdata = [
    {
      images: bgImage,
      id: 1,
    },
    {
      images: bgImage1,
      id: 2,
    },
    {
      images: bgImage2,
      id: 3,
    },
    {
      images: bgImage3,
      id: 4,
    },
  ];
  useEffect(() => {
    setImage(Imagesdata[0].images);
  }, []);
  // Function is called everytime increment button is clicked
  const handleClick1 = () => {
    // Counter state is incremented
    if (count < 3) {
      setImage(Imagesdata[count + 1]?.images || bgImage);
      setCount(count + 1);
    } else {
      setCount(0);
      setImage(Imagesdata[0]?.images);
    }
  };

  // Function is called everytime decrement button is clicked
  const handleClick2 = () => {
    // Counter state is decremented
    if (count > 0) {
      setImage(Imagesdata[count - 1].images);
      setCount(count - 1);
    } else {
      setCount(3);
      setImage(Imagesdata[3].images);
    }
  };
  useEffect(() => {
    scollToRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);
  return (
    <>
      <section className="corporate-sec">
        <div
          className={`${"info"}`}
          style={{ backgroundImage: `url(${image})` }}
        >
          <h1 className="typo5">Statutory Audit</h1>
          <div className="rightIcon">
            <div className="rightIconImageBox" onClick={handleClick1}>
              <MdArrowForwardIos color="#ffffff" />
            </div>
          </div>
          <div className="leftIcon" onClick={handleClick2}>
            <div className="leftIconImageBox">
              <MdArrowBackIos color="#ffffff" />
            </div>
          </div>
        </div>
        <div className="corporate-sec-1  ">
          <div className="corporate-img">
            <div class="hexagon">
              <img src={s1} width="200" height="200" />
            </div>
            <div className="nextConatainer">
              <div class="hexagon1">
                <img src={s2} width="200" height="200" />
              </div>
            </div>
            <div class="hexagon2">
              <img src={s3} width="200" height="200" />
            </div>

            {/* <img src="./CorporateImage/img1.jpeg" alt="img" width={500} /> */}
          </div>
          <div className="info-paragraphs">
            <h3 ref={scollToRef} className="audit-header">
              Statutory Audit
            </h3>
            <p className="text">
              We will be performing a thorough examination of financial records
              and providing assurance on the accuracy and reliability of your
              company’s financial statements. It provides assurance
              tostakeholders, including shareholders, investors, creditors, and
              regulators, about the accuracy and fairness of the company's
              financial statements. They help ensure transparency,
              accountability, and compliance with relevant laws and regulations.
            </p>
            <p className="text">
              Our team of experienced auditors provides comprehensive statutory
              audit services tailored to meet the specific needs of your
              business.
            </p>
            <p className="text">
              Our approach to statutory audits goes beyond mere compliance. We
              delve deep into your financial records, processes, and controls to
              provide valuable insights that can help you improve your financial
              management practices.
            </p>
            <br />
            {/* <h4>
              Detailed accounting and management processes covered by us are as
              follows:
            </h4> */}
            <h3 className="submenu-header ">Why Choose PRISH ?</h3>
            <p className="submenu-sub-header ">
              Experienced Professionals:{" "}
              <span className="text">
                Our team consists of highly skilled and experienced auditors
                with extensive knowledge across various industries.{" "}
              </span>
            </p>

            <p className="submenu-sub-header ">
              Tailored Solutions:{" "}
              <span className="text">
                We understand that every business is unique. That's why we offer
                customized audit solutions designed to address your specific
                requirements.
              </span>{" "}
            </p>
            <p className="submenu-sub-header ">
              Efficiency and Timeliness:{" "}
              <span className="text">
                We strive to complete audits promptly without compromising on
                quality, ensuring minimal disruption to your business
                operations.
              </span>
            </p>
            <p className="submenu-sub-header ">
              Comprehensive Reporting:{" "}
              <span className="text">
                Our audit reports are detailed, transparent, and easy to
                understand, providing you with valuable information to make
                informed decisions.
              </span>
            </p>
          </div>
        </div>
        <div className="accordian-section">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  What is a statutory audit?
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  A statutory audit is a legally mandated examination of a
                  company's financial records, transactions, and statements to
                  ensure accuracy, compliance with accounting standards, and
                  adherence to regulatory requirements.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  Who requires a statutory audit?
                </button>
              </h2>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Statutory audits are typically required for public companies,
                  large private companies, and certain non-profit organizations.
                  The requirement may vary based on the jurisdiction and the
                  company's size and nature of business.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  What are the objectives of a statutory audit?
                </button>
              </h2>
              <div
                id="collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  The main objectives of a statutory audit include verifying the
                  accuracy and reliability of financial statements, ensuring
                  compliance with applicable laws and regulations, and providing
                  assurance to stakeholders about the company's financial health
                  and performance.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsefour"
                  aria-expanded="false"
                  aria-controls="collapsefour"
                >
                  How often does a company need to undergo a statutory audit?
                </button>
              </h2>
              <div
                id="collapsefour"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  The frequency of statutory audits varies depending on legal
                  requirements and the company's size and type. In many
                  jurisdictions, annual audits are required, but smaller
                  businesses may undergo audits less frequently.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsefive"
                  aria-expanded="false"
                  aria-controls="collapsefive"
                >
                  What is the role of the auditor in a statutory audit?
                </button>
              </h2>
              <div
                id="collapsefive"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  The auditor's role is to independently examine the company's
                  financial records, transactions, and internal controls. They
                  assess the reliability of financial reporting, identify any
                  material misstatements or errors, and provide an opinion on
                  the fairness and accuracy of the financial statements.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSix"
                  aria-expanded="false"
                  aria-controls="collapseSix"
                >
                  What are the benefits of a statutory audit for a company?
                </button>
              </h2>
              <div
                id="collapseSix"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Statutory audits provide benefits such as improved credibility
                  with stakeholders, identification of internal control
                  weaknesses, compliance with legal requirements, enhanced
                  transparency, and accountability.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSeven"
                  aria-expanded="false"
                  aria-controls="collapseSeven"
                >
                  How long does a statutory audit typically take?
                </button>
              </h2>
              <div
                id="collapseSeven"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  The duration of a statutory audit depends on factors such as
                  the size and complexity of the company, the quality of its
                  financial records, and the scope of the audit. Generally,
                  audits for smaller companies may take a few weeks, while
                  audits for larger organizations may take several months.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseEight"
                  aria-expanded="false"
                  aria-controls="collapseEight"
                >
                  What is the process of a statutory audit?
                </button>
              </h2>
              <div
                id="collapseEight"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  The process typically involves planning, risk assessment,
                  testing of controls and transactions, substantive procedures,
                  communication of findings, and issuing the audit report. The
                  specific steps may vary depending on the auditor's approach
                  and the company's circumstances.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseNine"
                  aria-expanded="false"
                  aria-controls="collapseNine"
                >
                  What documents are required for a statutory audit?
                </button>
              </h2>
              <div
                id="collapseNine"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Documents required for a statutory audit may include financial
                  statements, bank statements, invoices, receipts, contracts,
                  payroll records, tax returns, and any other relevant financial
                  or accounting records.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTen"
                  aria-expanded="false"
                  aria-controls="collapseTen"
                >
                  Can a company change its auditor for statutory audits?
                </button>
              </h2>
              <div
                id="collapseTen"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Yes, a company can change its auditor if necessary. However,
                  the process may involve notifying relevant authorities and
                  ensuring a smooth transition of audit responsibilities.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Query />
    </>
  );
};

export default SatutoryAudit;
