import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import c1 from "./images/con1.jpeg";
import c2 from "./images/con2.jpeg";
import c3 from "./images/con3.jpeg";
import bgImage from "../Home/Images/homepage2.jpeg";
import bgImage1 from "../Home/Images/homepage3.jpg";
import bgImage2 from "../Home/Images/homepage4.jpg";
import bgImage3 from "../Home/Images/homepage5.jpg";
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md";
import Query from "../Components/Query/Query";

const ConcurrentAudit = () => {
  const [image, setImage] = useState("");
  const [count, setCount] = useState(0);
  const scollToRef = useRef();
  useEffect(() => {
    scollToRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);
  const Imagesdata = [
    {
      images: bgImage,
      id: 1,
    },
    {
      images: bgImage1,
      id: 2,
    },
    {
      images: bgImage2,
      id: 3,
    },
    {
      images: bgImage3,
      id: 4,
    },
  ];
  useEffect(() => {
    setImage(Imagesdata[0].images);
  }, []);
  // Function is called everytime increment button is clicked
  const handleClick1 = () => {
    // Counter state is incremented
    if (count < 3) {
      setImage(Imagesdata[count + 1]?.images || bgImage);
      setCount(count + 1);
    } else {
      setCount(0);
      setImage(Imagesdata[0]?.images);
    }
  };

  // Function is called everytime decrement button is clicked
  const handleClick2 = () => {
    // Counter state is decremented
    if (count > 0) {
      setImage(Imagesdata[count - 1].images);
      setCount(count - 1);
    } else {
      setCount(3);
      setImage(Imagesdata[3].images);
    }
  };
  return (
    <>
      <section className="corporate-sec">
        <div
          className={`${"info"}`}
          style={{ backgroundImage: `url(${image})` }}
        >
          <h1 className="typo5">Concurrent Audit</h1>
          <div className="rightIcon">
            <div className="rightIconImageBox" onClick={handleClick1}>
              <MdArrowForwardIos color="#ffffff" />
            </div>
          </div>
          <div className="leftIcon" onClick={handleClick2}>
            <div className="leftIconImageBox">
              <MdArrowBackIos color="#ffffff" />
            </div>
          </div>
        </div>
        <div className="corporate-sec-1  ">
          <div className="corporate-img">
            <div class="hexagon">
              <img src={c1} width="200" height="200" />
            </div>
            <div className="nextConatainer">
              <div class="hexagon1">
                <img src={c2} width="200" height="200" />
              </div>
            </div>
            <div class="hexagon2">
              <img src={c3} width="200" height="200" />
            </div>

            {/* <img src="./CorporateImage/img1.jpeg" alt="img" width={500} /> */}
          </div>
          <div className="info-paragraphs">
            <h3 ref={scollToRef} className="audit-header">
              Concurrent Audit
            </h3>
            <p className="text">
              It would be a real-time examination of financial transactions and
              processes conducted by us as an internal or external auditor to
              provide you with the timely feedback and ensure the effectiveness
              of internal controls and financial records.Unlike traditional
              audits, which are retrospective, concurrent audits are conducted
              concurrently with the transactions being processed.
            </p>
            <p>
              Our approach to understand the importance of timely and proactive
              oversight of your financial operations. Our experienced auditors
              work closely with your team to perform concurrent audits that are
              tailored to your organization's unique requirements.
            </p>

            <br />

            <h3 className="submenu-header ">Why Choose PRISH ?</h3>
            <p className="submenu-sub-header ">
              Real-time Monitoring:{" "}
              <span className="text">
                we provide real-time monitoring of financial transactions,
                enabling prompt detection of errors, discrepancies, or potential
                fraud.
              </span>
            </p>

            <p className="submenu-sub-header ">
              Risk Assessment:{" "}
              <span className="text">
                We conduct thorough risk assessments to identify areas of
                vulnerability and recommend measures to strengthen internal
                controls and mitigate risks.
              </span>{" "}
            </p>
            <p className="submenu-sub-header ">
              Comprehensive Reporting:{" "}
              <span className="text">
                Our audit reports are detailed, transparent, and easy to
                understand, providing valuable insights and recommendations for
                improving operational efficiency and compliance.
              </span>
            </p>
            <p className="submenu-sub-header ">
              Customized Solutions:{" "}
              <span className="text">
                We recognize that every business is unique, so we tailor our
                audit procedures and recommendations to address your specific
                needs and challenges.
              </span>
            </p>
          </div>
        </div>
        <div className="accordian-section">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  What is a concurrent audit?
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  A concurrent audit is a real-time examination of financial
                  transactions and processes conducted simultaneously with the
                  occurrence of transactions to ensure accuracy, compliance, and
                  effectiveness of internal controls.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  Why is concurrent audit necessary?
                </button>
              </h2>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Concurrent audits help organizations maintain control over
                  their financial transactions, detect errors or irregularities
                  promptly, prevent fraud, ensure compliance with regulations,
                  and improve operational efficiency.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  Who needs concurrent audit services?
                </button>
              </h2>
              <div
                id="collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Concurrent audit services are beneficial for businesses of all
                  sizes and types, particularly those with high transaction
                  volumes or complex financial operations. They are commonly
                  utilized by banks, financial institutions, manufacturing
                  companies, retail chains, and government agencies.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsefour"
                  aria-expanded="false"
                  aria-controls="collapsefour"
                >
                  What are the key objectives of a concurrent audit?
                </button>
              </h2>
              <div
                id="collapsefour"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  The main objectives of a concurrent audit include real-time
                  monitoring of financial transactions, early detection of
                  irregularities or fraud, assessment of compliance with
                  internal policies and regulatory requirements, and improvement
                  of operational efficiency.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsefive"
                  aria-expanded="false"
                  aria-controls="collapsefive"
                >
                  How does concurrent audit differ from other types of audits?
                </button>
              </h2>
              <div
                id="collapsefive"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Concurrent audit differs from other audits, such as internal
                  audit and statutory audit, in that it is conducted
                  simultaneously with the occurrence of transactions, providing
                  real-time monitoring and assessment of controls and processes.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSix"
                  aria-expanded="false"
                  aria-controls="collapseSix"
                >
                  What are the benefits of concurrent audit services?
                </button>
              </h2>
              <div
                id="collapseSix"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  The benefits of concurrent audit services include fraud
                  prevention, early detection of errors or irregularities,
                  improved operational efficiency, compliance assurance,
                  enhanced stakeholder confidence, and proactive risk
                  management.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSeven"
                  aria-expanded="false"
                  aria-controls="collapseSeven"
                >
                  What does the concurrent audit process involve?
                </button>
              </h2>
              <div
                id="collapseSeven"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  The concurrent audit process typically involves planning, risk
                  assessment, real-time monitoring of transactions, examination
                  of controls and processes, detection of irregularities,
                  reporting of findings, and recommendations for improvement.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseEight"
                  aria-expanded="false"
                  aria-controls="collapseEight"
                >
                  How frequently should concurrent audits be conducted?
                </button>
              </h2>
              <div
                id="collapseEight"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  The frequency of concurrent audits depends on the size,
                  nature, and complexity of the organization's operations. In
                  general, they are conducted on a regular basis, often daily or
                  weekly, to ensure timely detection and prevention of financial
                  irregularities.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Query />
    </>
  );
};

export default ConcurrentAudit;
