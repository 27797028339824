import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import company from "./images/financial_budget1.jpeg";
import company1 from "./images/financial_budget2.jpeg";
import company2 from "./images/financial_budget3.jpeg";
import bgImage from "../Home/Images/homepage2.jpeg";
import bgImage1 from "../Home/Images/homepage3.jpg";
import bgImage2 from "../Home/Images/homepage4.jpg";
import bgImage3 from "../Home/Images/homepage5.jpg";
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md";
import Query from "../Components/Query/Query";

const FinancialBudget = () => {
  const [image, setImage] = useState("");
  const [count, setCount] = useState(0);
  const scollToRef = useRef();
  useEffect(() => {
    scollToRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);
  const Imagesdata = [
    {
      images: bgImage,
      id: 1,
    },
    {
      images: bgImage1,
      id: 2,
    },
    {
      images: bgImage2,
      id: 3,
    },
    {
      images: bgImage3,
      id: 4,
    },
  ];
  useEffect(() => {
    setImage(Imagesdata[0].images);
  }, []);
  // Function is called everytime increment button is clicked
  const handleClick1 = () => {
    // Counter state is incremented
    if (count < 3) {
      setImage(Imagesdata[count + 1]?.images || bgImage);
      setCount(count + 1);
    } else {
      setCount(0);
      setImage(Imagesdata[0]?.images);
    }
  };

  // Function is called everytime decrement button is clicked
  const handleClick2 = () => {
    // Counter state is decremented
    if (count > 0) {
      setImage(Imagesdata[count - 1].images);
      setCount(count - 1);
    } else {
      setCount(3);
      setImage(Imagesdata[3].images);
    }
  };
  return (
    <>
      <section className="corporate-sec">
        <div
          className={`${"info"}`}
          style={{ backgroundImage: `url(${image})` }}
        >
          <h1 className="typo5">Financial Budgeting</h1>
          <div className="rightIcon">
            <div className="rightIconImageBox" onClick={handleClick1}>
              <MdArrowForwardIos color="#ffffff" />
            </div>
          </div>
          <div className="leftIcon" onClick={handleClick2}>
            <div className="leftIconImageBox">
              <MdArrowBackIos color="#ffffff" />
            </div>
          </div>
        </div>
        <div className="corporate-sec-1  ">
          <div className="corporate-img">
            <div class="hexagon">
              <img src={company} width="200" height="200" />
            </div>
            <div className="nextConatainer">
              <div class="hexagon1">
                <img src={company1} width="200" height="200" />
              </div>
            </div>
            <div class="hexagon2">
              <img src={company2} width="200" height="200" />
            </div>

            {/* <img src="./CorporateImage/img1.jpeg" alt="img" width={500} /> */}
          </div>
          <div className="info-paragraphs">
            <h3 ref={scollToRef} className="audit-header">
              Financial Budgeting
            </h3>
            <p className="text">
              Developing a comprehensive plan for the allocation and budgeting
              of financial resources over a specific period. The budget serves
              as a roadmap for guiding financial decision-making and performance
              evaluation. Forecasting by predicting future financial outcomes,
              performance, and cash flows based on historical data, market
              trends, and other relevant factors. It helps businesses plan and
              manage their finances effectively.
            </p>
            <p className="text">
              Our expert team provides tailored solutions to assist
              organizations in developing accurate budgets, forecasting future
              financial performance, and making informed strategic decisions.
            </p>
            <p className="text">
              Our approach is to understand that financial budgeting and
              forecasting are critical components of business planning and
              decision-making. It’s collaborative and data-driven, focusing on
              developing realistic budgets and forecasts that align with our
              client's goals and objectives.
            </p>
            <br />
            {/* <h4>
              Detailed accounting and management processes covered by us are as
              follows:
            </h4> */}
            <h3 className="submenu-header ">Why Choose PRISH ?</h3>
            <p className="submenu-sub-header ">
              Budget Preparation:{" "}
              <span className="text">
                We assist businesses in creating detailed budgets that outline
                expected revenues, expenses, and cash flows for the upcoming
                period.
              </span>
            </p>

            <p className="submenu-sub-header ">
              Financial Forecasting:{" "}
              <span className="text">
                Our team develops financial forecasts that project future
                performance based on historical data, market trends, and
                assumptions.
              </span>{" "}
            </p>
            <p className="submenu-sub-header ">
              Scenario Analysis:{" "}
              <span className="text">
                We conduct scenario analysis to assess the potential impact of
                different variables and events on the company's financial
                performance.
              </span>
            </p>
            <p className="submenu-sub-header ">
              Performance Monitoring:
              <span className="text">
                We help businesses monitor their actual financial performance
                against budgeted and forecasted figures, identifying variances
                and making necessary adjustments.
              </span>
            </p>
          </div>
        </div>
        <div className="accordian-section">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  What is financial budgeting?
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Financial budgeting is the process of creating a detailed plan
                  for a company's financial activities, including revenues,
                  expenses, and cash flows, typically for a specific period,
                  such as a fiscal year.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  What is financial forecasting?
                </button>
              </h2>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Financial forecasting involves predicting future financial
                  performance based on historical data, market trends, and
                  assumptions, providing insights into potential revenue,
                  expenses, and cash flow.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  Why is financial budgeting and forecasting important for
                  businesses?
                </button>
              </h2>
              <div
                id="collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Financial budgeting and forecasting are important for
                  businesses because they help in planning and managing finances
                  effectively, making informed decisions, and setting realistic
                  goals and targets.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsefour"
                  aria-expanded="false"
                  aria-controls="collapsefour"
                >
                  What are the key benefits of financial budgeting and
                  forecasting services?
                </button>
              </h2>
              <div
                id="collapsefour"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  The key benefits of financial budgeting and forecasting
                  services include:
                  <ul>
                    <li>
                      Improved decision-making based on accurate financial data
                      and insights
                    </li>
                    <li>
                      Efficient allocation of resources to maximize
                      profitability
                    </li>
                    <li>
                      Identification and mitigation of potential risks through
                      scenario analysis
                    </li>
                    <li>
                      Facilitation of strategic planning by aligning short-term
                      actions with long-term goals
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsefive"
                  aria-expanded="false"
                  aria-controls="collapsefive"
                >
                  What factors are considered during financial forecasting?
                </button>
              </h2>
              <div
                id="collapsefive"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Financial forecasting considers various factors such as
                  historical financial data, market trends, industry benchmarks,
                  economic conditions, and business-specific variables to
                  predict future performance.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSix"
                  aria-expanded="false"
                  aria-controls="collapseSix"
                >
                  How often should businesses update their financial budgets and
                  forecasts?
                </button>
              </h2>
              <div
                id="collapseSix"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Financial budgets and forecasts should be updated regularly,
                  typically on a quarterly or annual basis, to reflect changes
                  in business conditions, market dynamics, and strategic
                  priorities.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSeven"
                  aria-expanded="false"
                  aria-controls="collapseSeven"
                >
                  Can financial budgeting and forecasting help in identifying
                  growth opportunities?
                </button>
              </h2>
              <div
                id="collapseSeven"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Yes, financial budgeting and forecasting can help businesses
                  identify growth opportunities by highlighting areas of
                  potential revenue growth, cost savings, and investment
                  opportunities.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseEight"
                  aria-expanded="false"
                  aria-controls="collapseEight"
                >
                  How does financial budgeting and forecasting contribute to
                  risk management?
                </button>
              </h2>
              <div
                id="collapseEight"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Financial budgeting and forecasting contribute to risk
                  management by identifying potential risks and uncertainties,
                  enabling businesses to develop contingency plans and mitigate
                  adverse impacts on financial performance.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseNine"
                  aria-expanded="false"
                  aria-controls="collapseNine"
                >
                  How do financial budgeting and forecasting services add value
                  to businesses?
                </button>
              </h2>
              <div
                id="collapseNine"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Financial budgeting and forecasting services add value to
                  businesses by providing insights and tools for effective
                  financial planning, decision-making, and performance
                  management, ultimately leading to improved profitability and
                  sustainable growth.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Query />
    </>
  );
};

export default FinancialBudget;
