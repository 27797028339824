import React, { useEffect, useRef, useState } from "react";
import "../Home/Home.css";
import Img3 from "../Images/img3.jpg";
import { useNavigate } from "react-router-dom";
import solution from "../Home/Images/solution.jpg";
import personalized from "../Home/Images/personalized.jpg";
import client from "../Home/Images/client.jpg";
import commitment from "../Home/Images/commitment.jpg";
import integrety1 from "../Home/Images/integrety1.jpg";

import icon1 from "../Images/icon1.png";
import icon3 from "../Images/icon3.png";
import icon4 from "../Images/icon4.png";
import icon5 from "../Images/icon5.png";
import icon2 from "../Images/icon2.png";
import bgImage from "./Images/homepage20.jpg";
import bgImage1 from "./Images/homepage21.jpg";
import bgImage2 from "./Images/homepage22.jpg";
import bgImage3 from "./Images/homepage2.jpeg";
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md";
import PrivacySection from "../PrivacySection/PrivacySection";
import { TeamList } from "../Components/Data";
import TeamData from "./TeamData";
import Member from "../Member/Member";

const Home = () => {
  const navigate = useNavigate();
  const [image, setImage] = useState("");
  const [count, setCount] = useState(0);
  const [isHover, setIsHover] = useState(false);
  const [isHoverPrev, setIsHoverPrev] = useState(false);
  const scollToRef = useRef();
  useEffect(() => {
    scollToRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);
  const isCookieHandler = localStorage.getItem("actionCookie");

  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };
  const handleMouseEnterPrev = () => {
    setIsHoverPrev(true);
  };
  const handleMouseLeavePrev = () => {
    setIsHoverPrev(false);
  };

  const Imagesdata = [
    {
      images: bgImage,
      id: 1,
    },
    {
      images: bgImage1,
      id: 2,
    },
    {
      images: bgImage2,
      id: 3,
    },
    {
      images: bgImage3,
      id: 4,
    },
  ];
  const [currentScreen, setCurrentScreen] = useState("vision");

  const handleNavigation = (screen) => {
    setCurrentScreen(screen);
  };
  useEffect(() => {
    setImage(Imagesdata[0].images);
  }, []);
  // Function is called everytime increment button is clicked
  const handleClick1 = () => {
    // Counter state is incremented
    if (count < 3) {
      setImage(Imagesdata[count]?.images || bgImage);
      setCount(count + 1);
    } else {
      setCount(0);
      setImage(Imagesdata[0]?.images);
    }
  };

  const getRandomIndex = (array) => {
    return Math.floor(Math.random() * array.length);
  };
  useEffect(() => {
    setInterval(() => {
      const index = getRandomIndex(Imagesdata);
      console.log("coutttttt", index);
      setImage(Imagesdata[index]?.images || bgImage);
    }, 5000);
  }, []);

  // Function is called everytime decrement button is clicked
  const handleClick2 = () => {
    // Counter state is decremented
    if (count > 0) {
      setImage(Imagesdata[count - 1].images);
      setCount(count - 1);
    } else {
      setCount(3);
      setImage(Imagesdata[3].images);
    }
  };
  const boxStyle = {
    backgroundImage: isHover
      ? count < 3
        ? `url(${Imagesdata[count + 1]?.images})`
        : `url(${Imagesdata[0]?.images})`
      : "",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    transition: "all 0.3s",
  };

  const boxStylePrev = {
    backgroundImage: isHoverPrev
      ? count > 0
        ? `url(${Imagesdata[count]?.images})`
        : `url(${Imagesdata[3]?.images})`
      : "",

    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    transition: "all 0.3s",
  };
  const homePageImage = {
    backgroundImage: `url(${image})`,
  };
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <div
        class="wp-socializer wpsr-follow-icons sr-fb-rm sr-fb-vl"
        data-sm-width="768"
      >
        <div class="socializer sr-followbar sr-32px sr-circle sr-zoom sr-pad sr-vertical">
          <span class="sr-facebook">
            <a
              data-id="facebook"
              style={{ color: "#ffffff" }}
              rel="nofollow"
              href="https://www.instagram.com/prish_auditor_/"
              target="_blank"
              title="Facebook"
            >
              <img src={icon1} />
            </a>
          </span>
          <span class="sr-instagram">
            <a
              data-id="instagram"
              style={{ color: "#ffffff" }}
              rel="nofollow"
              href="https://api.whatsapp.com/send/?phone=971567244122"
              target="_blank"
              title="Instagram"
            >
              <img src={icon2} />
            </a>
          </span>
          <span class="sr-linkedin">
            <a
              data-id="linkedin"
              style={{ color: "#ffffff" }}
              rel="nofollow"
              href="#"
              target="_blank"
              title="Linkedin"
            >
              <img src={icon3} />
            </a>
          </span>
          <span class="sr-phone">
            <a
              data-id="phone"
              style={{ color: "#ffffff" }}
              rel="nofollow"
              href="https://www.linkedin.com/in/prish-auditors-459031305"
              target="_blank"
              title="Phone"
            >
              <img src={icon4} />
            </a>
          </span>
          <span class="sr-email">
            <a
              data-id="email"
              style={{ color: "#ffffff" }}
              rel="nofollow"
              href="mailto:info@@prishauditors.com"
              target="_blank"
              title="EmailUs"
            >
              <img src={icon5} />
            </a>
          </span>
        </div>
      </div>
      <div className={`${"info"}`} style={homePageImage}>
        {/* social Icons */}

        <h1 ref={scollToRef} className="typo">
          More Than Just Advise!
        </h1>
        <div className="rightIcon">
          <div
            className="rightIconImageBox"
            onClick={handleClick1}
            style={boxStyle}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <MdArrowForwardIos color="#ffffff" />
          </div>
        </div>
        <div
          className="leftIcon"
          onClick={handleClick2}
          onMouseEnter={handleMouseEnterPrev}
          onMouseLeave={handleMouseLeavePrev}
        >
          <div className="leftIconImageBox" style={boxStylePrev}>
            <MdArrowBackIos color="#ffffff" />
          </div>
        </div>
        <span>
          {" "}
          PRISH Accounting and Auditing is an emerging firm of professional
          Chartered Accountants known for their excellence through <br />
          expertise in the field of Accounting, Auditing & Assurance, Taxation,
          Mergers & Acquisitions, Management Consultancy
          <br />
          and Company Formation services.
        </span>

        <button className="btn" onClick={() => navigate("/about")}>
          Explore Now
        </button>
      </div>
      <section className="sec-1">
        <div className="content">
          <div className="home_para">
            <h1 className="about_heading">
              ABOUT PRISH ACCOUNTING AND AUDITING
            </h1>
            <h3 className="intro">
              One Stop Solution for all your Accounting and Business Needs.
            </h3>
            <p>
              Welcome to PRISH Accounting and Auditing, your trusted partner in
              navigating the complexities of financial management and
              compliance. As a distinguished Chartered Accountant firm, we
              specialize in delivering tailored solutions to meet your business
              needs with precision and expertise in the field of Accounting,
              Auditing & Assurance, Taxation, Mergers & Acquisitions, Management
              Consultancy and Company Formation services. At PRISH Accounting
              and Auditing, we understand the pivotal role financial integrity
              plays in the success of your endeavours. Our team of professionals
              brings forth a wealth of experience and a commitment to
              excellence, ensuring that your financial objectives are not only
              met but exceeded.
            </p>
            <button onClick={() => navigate("/about")} className="btn-2">
              Read more
            </button>
          </div>
          <div className="image">
            <div className="corporate-img">
              <div class="home-hexagon">
                <img src={Img3} width="200" height="200" />
              </div>
            </div>
            {/* <img src={Img3} alt="img" /> */}
          </div>
        </div>
      </section>

      {/* for future reference */}
      {/* <section className="sec-3">
        <div className="card-sec">
          <h1 className="about_heading">Our Team Members</h1>
          <div className="cards">
            {TeamList.map((TeamList) => (
              <TeamData
                key={TeamList.id}
                image={TeamList.image}
                name={TeamList.name}
                para={TeamList.para}
              />
            ))}
          </div>
        </div>
      </section> */}

      {/* <section className="sec-1">
        <div className="content">
          <div className="home_para">
            <h1 className="home_heading">Why Choose Us?</h1>

            <p>
              Our team consists of highly skilled and experienced professionals
              with a deep understanding of accounting principles, finance
              regulations, and industry best practices. We understand that every
              client is unique, which is why we offer tailored solutions to meet
              their specific accounting and finance needs. As a client-centric
              firm, we prioritize building strong relationships with our
              clients. We take the time to understand their business objectives,
              challenges, and opportunities, providing them personalized
              attention. We offer competitive pricing without compromising on
              the quality of our services. We prioritize transparency and
              honesty in all our interactions, fostering trust and long-term
              partnerships
            </p>
          </div>
          <div className="image">
            <img src={Img3} alt="img" />
          </div>
        </div>
      </section> */}
      <section className="sec-1">
        <div className="about_content">
          <div className="about_para">
            <h1 className="about_heading">Why Choose PRISH?</h1>

            <p>
              Are you seeking financial guidance that goes beyond mere numbers?
              Look no further than PRISH Accounting and Auditing. We understand
              that selecting the right Chartered Accountants is crucial for the
              success and prosperity of your business. Here's why choosing PRISH
              can be the best decision for you?
            </p>
            <p>
              <b>P</b>erfection beyond imagination
            </p>
            <p>
              <b>R</b>eliability and Consistency in delivering our promises.
            </p>
            <p>
              <b>I</b>nnovative Solutions to meet business needs
            </p>
            <p>
              <b>S</b>olution Driven Approach to all your business challenges
            </p>
            <p>
              <b>H</b>ighly qualified and experienced professionals
            </p>
          </div>
          <div className="about_image">
            <div className="corporate-img">
              <div class="home-prish-hexagon">
                <img src={Img3} width="200" height="200" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <br />
      <br />
      <br />
      <br />
      <section className="sec-3">
        {/* TEMPLATE 1 for Vission Mission */}
        <div className="mission-section">
          <div className="mission-title">
            <h2 className="about_heading">VISION AND MISSION</h2>
            <br />

            <p>
              {" "}
              Prish Auditors, has committed it's experirence ans skill to
              provide high quality professional services to the clients with the
              promise of keeping up highest standard of ethics and integrity
            </p>
            <br />
          </div>

          {/* <div className="mission-cards">
            <div className="mission-card vision">
              <div className="icon">🎯</div>
              <h3>VISION</h3>
              <p>
                Our vision is to lead the evolution of the financial services
                industry, setting new standards of excellence in transparency,
                integrity, and client satisfaction, while championing financial
                literacy and empowerment on a global scale. We vision to be
                premier partner for businesses worldwide, revolutionizing the
                way they approach finance
              </p>
            </div>

            <div className="mission-card mission">
              <div className="icon">⚙️</div>
              <h3>MISSION</h3>
              <p>
                Our mission is to empower businesses with insightful financial
                solutions, guiding them towards sustainable growth, informed
                decision-making, and financial stability. Driven by a passion
                for financial empowerment, our mission is to partner with
                businesses of all sizes, providing them with the tools,
                expertise, and support they need to achieve their financial
                goals and aspirations.
              </p>
            </div>
          </div> */}
        </div>
        <div className="mv-section">
          <div className="mv-item">
            <div className="mv-icon-container">
              <div className="mv-icon mission-icon">🚀</div>{" "}
            </div>
            <h3 className="mv-title mission-title">OUR MISSION</h3>
            <p className="mv-description">
              Our mission is to empower businesses with insightful financial
              solutions, guiding them towards sustainable growth, informed
              decision-making, and financial stability. Driven by a passion for
              financial empowerment, our mission is to partner with businesses
              of all sizes, providing them with the tools, expertise, and
              support they need to achieve their financial goals and
              aspirations.
            </p>
          </div>

          <div className="mv-item">
            <div className="mv-icon-container">
              <div className="mv-icon vision-icon">💡</div>{" "}
            </div>
            <h3 className="mv-title vision-title">OUR VISION</h3>
            <p className="mv-description">
              Our vision is to lead the evolution of the financial services
              industry, setting new standards of excellence in transparency,
              integrity, and client satisfaction, while championing financial
              literacy and empowerment on a global scale. We vision to be
              premier partner for businesses worldwide, revolutionizing the way
              they approach finance
            </p>
          </div>

          <div className="mv-item">
            <div className="mv-icon-container">
              <div className="mv-icon values-icon">⚖️</div>{" "}
            </div>
            <h3 className="mv-title values-title">OUR VALUES</h3>
            <p className="mv-description">
              Prish Auditors, has committed it's experirence ans skill to
              provide high quality professional services to the clients with the
              promise of keeping up highest standard of ethics and integrity
            </p>
          </div>
        </div>
        <br />
      </section>

      {/* <section className="sec4">
        <div className="mission-sec">
          <div class="ribbon">
            VISION AND MISSION STATEMENTS
            <i></i>
            <i></i>
            <i></i>
            <i></i>
          </div>

          <div className="statement">
            <p>
              Prish Auditors, has committed it's experirence ans skill to
              provide high quality professional services to the clients with the
              promise of keeping up highest standard of ethics and integrity
            </p>
          </div>
          <div className="sec4-info">
          =
            <div className="sec4-content">
              <div class="arrow-pointer">
                <span>Vision</span>
                <div className="vision-content">
                  Our vision is to lead the evolution of the financial services
                  industry, setting new standards of excellence in transparency,
                  integrity, and client satisfaction, while championing
                  financial literacy and empowerment on a global scale. We
                  vision to be premier partner for businesses worldwide,
                  revolutionizing the way they approach finance
                </div>
              </div>

              <div class="arrow-pointer-left">
                <span>Mission</span>
                <div className="vision-content-left">
                  Our mission is to empower businesses with insightful financial
                  solutions, guiding them towards sustainable growth, informed
                  decision-making, and financial stability. Driven by a passion
                  for financial empowerment, our mission is to partner with
                  businesses of all sizes, providing them with the tools,
                  expertise, and support they need to achieve their financial
                  goals and aspirations.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <div>
        <div className="team-content">
          <div className="team-content-bg">
            <div className="para">
              <h1 className="heading">Our Team</h1>
              <p>
                PRISH Accounting and Auditing LLC is a congregation of 50+
                highly qualified and experienced professionals in their core
                areas of services through delivering perfection beyond
                imagination. We combine established approaches with innovative
                ideas and creative applications that will help you succeed
                today, tomorrow, and every day.
              </p>
            </div>
          </div>
        </div>
      </div>
      <section className="sec-4 section4-box">
        <div className="core-header">
          <span className="span-text">
            <h1>C</h1>
            <h1>O</h1>
            <h1>R</h1>
            <h1>E</h1>
          </span>

          <span className="span-text-left">
            <h1>V</h1>
            <h1>A</h1>
            <h1>L</h1>
            <h1>U</h1>
            <h1>E</h1>
            <h1>S</h1>
          </span>
        </div>
        <div className="about-info-box">
          <div className="box">
            <div className="box-icons">
              <img className="about-core-img" src={solution} alt="image" />
            </div>
            <div className="box-content">
              <p>Customise Solutions</p>
            </div>
          </div>

          <div className="box">
            <div className="box-icons">
              <img className="about-core-img" src={personalized} alt="image" />
            </div>
            <div className="box-content">
              <p>Personalized Attention</p>
            </div>
          </div>

          <div className="box">
            <div className="box-icons">
              <img className="about-core-img" src={integrety1} alt="image" />
            </div>
            <div className="box-content">
              <p>Integrity and trust</p>
            </div>
          </div>

          <div className="box">
            <div className="box-icons">
              <img className="about-core-img" src={commitment} alt="image" />
            </div>
            <div className="box-content">
              <p>Commitment to Excellence</p>
            </div>
          </div>

          <div className="box">
            <div className="box-icons">
              <img className="about-core-img" src={client} alt="image" />
            </div>
            <div className="box-content">
              <p>Client Satisfaction</p>
            </div>
          </div>
        </div>
      </section>

      <section class="service-five">
        <div class="pattern-layer">
          <div
            class="pattern-layer "
            style={{
              backgroundImage: `url(
                'https://www.stuaham.com/public/images/shape/shape-766.png'
              )`,
            }}
          ></div>
        </div>

        <div class="home-auto-container mt-5">
          <div class="row card-clearfix">
            <div class="col-lg-3 col-md-6 col-sm-12 service-block">
              <div
                class="service-block-five wow slideInUp animated animated"
                data-wow-delay="00ms"
                data-wow-duration="1500ms"
                style={{
                  visibility: "visible",
                  animationDuration: "1500ms",
                  animationDelay: "0ms",
                  animationName: "slideInUp",
                }}
              >
                <div class="inner-box p_relative d_block b_radius_10 pt_35 pr_30 pb_10 pl_30 centred">
                  <div class="icon-box p_relative fs_45 lh_45 mb_25 tran_5">
                    <i class="fal fa-file-signature"></i>
                  </div>
                  <h3 class="d_block lh_30 fw_bold font_family_frank mb_20">
                    <a href="/auditing-assurance-services">
                      Audit &amp; Assurance
                    </a>
                  </h3>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 service-block">
              <div
                class="service-block-five wow slideInUp animated animated"
                data-wow-delay="00ms"
                data-wow-duration="1500ms"
                style={{
                  visibility: "visible",
                  animationDuration: "1500ms",
                  animationDelay: "0ms",
                  animationName: "slideInUp",
                }}
              >
                <div class="inner-box p_relative d_block b_radius_10 pt_35 pr_30 pb_10 pl_30 centred">
                  <div class="icon-box p_relative fs_45 lh_45 mb_25 tran_5">
                    <i class="fal fa-file-signature"></i>
                  </div>
                  <h3 class="d_block lh_30 fw_bold font_family_frank mb_20">
                    <a href="/special-audit">Special Audits</a>
                  </h3>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 service-block">
              <div
                class="service-block-five wow slideInUp animated animated"
                data-wow-delay="200ms"
                data-wow-duration="1500ms"
                style={{
                  visibility: "visible",
                  animationDuration: "1500ms",
                  animationDelay: "200ms",
                  animationName: "slideInUp",
                }}
              >
                <div class="inner-box p_relative d_block b_radius_10 pt_35 pr_30 pb_10 pl_30 centred">
                  <div class="icon-box p_relative fs_45 lh_45 mb_25 tran_5">
                    <i class="fas fa-chart-pie-alt"></i>
                  </div>
                  <h3 class="d_block lh_30 fw_bold font_family_frank mb_20">
                    <a href="/risk-advisory-services">Risk Assurance</a>
                  </h3>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 service-block">
              <div
                class="service-block-five wow slideInUp animated animated"
                data-wow-delay="400ms"
                data-wow-duration="1500ms"
                style={{
                  visibility: "visible",
                  animationDuration: "1500ms",
                  animationDelay: "400ms",
                  animationName: "slideInUp",
                }}
              >
                <div class="inner-box p_relative d_block b_radius_10 pt_35 pr_30 pb_10 pl_30 centred">
                  <div class="icon-box p_relative fs_45 lh_45 mb_25 tran_5">
                    <i class="fas fa-hands-usd"></i>
                  </div>
                  <h3 class="d_block lh_30 fw_bold font_family_frank mb_20">
                    <a href="/tax-services">Taxation</a>
                  </h3>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 service-block">
              <div
                class="service-block-five wow slideInUp animated animated"
                data-wow-delay="400ms"
                data-wow-duration="1500ms"
                style={{
                  visibility: "visible",
                  animationDuration: "1500ms",
                  animationDelay: "400ms",
                  animationName: "slideInUp",
                }}
              >
                <div class="inner-box p_relative d_block b_radius_10 pt_35 pr_30 pb_10 pl_30 centred">
                  <div class="icon-box p_relative fs_45 lh_45 mb_25 tran_5">
                    <i class="fas fa-hands-usd"></i>
                  </div>
                  <h3 class="d_block lh_30 fw_bold font_family_frank mb_20">
                    <a href="/accounting-outsourcing-services">
                      Accounting and Bookkeeping
                    </a>
                  </h3>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 service-block">
              <div
                class="service-block-five wow slideInUp animated animated"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
                style={{
                  visibility: "visible",
                  animationDuration: "1500ms",
                  animationDelay: "0ms",
                  animationName: "slideInUp",
                }}
              >
                <div class="inner-box p_relative d_block b_radius_10 pt_35 pr_30 pb_10 pl_30 centred">
                  <div class="icon-box p_relative fs_45 lh_45 mb_25 tran_5">
                    <i class="fas fa-user-chart"></i>
                  </div>
                  <h3 class="d_block lh_30 fw_bold font_family_frank mb_20">
                    <a href="/anti-money-laundering">
                      Anti Money Laundering (AML) Compliances
                    </a>
                  </h3>
                </div>
              </div>
            </div>
            {/* <div class="col-lg-3 col-md-6 col-sm-12 service-block">
              <div
                class="service-block-five wow slideInUp animated animated"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
                style={{
                  visibility: "visible",
                  animationDuration: "1500ms",
                  animationDelay: "0ms",
                  animationName: "slideInUp",
                }}
              >
                <div class="inner-box p_relative d_block b_radius_10 pt_35 pr_30 pb_10 pl_30 centred">
                  <div class="icon-box p_relative fs_45 lh_45 mb_25 tran_5">
                    <i class="fas fa-handshake"></i>
                  </div>
                  <h3 class="d_block lh_30 fw_bold font_family_frank mb_20">
                    <a href="/tax-services">Taxation</a>
                  </h3>
                </div>
              </div>
            </div> */}
            <div class="col-lg-3 col-md-6 col-sm-12 service-block">
              <div
                class="service-block-five wow slideInUp animated animated"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
                style={{
                  visibility: "visible",
                  animationDuration: "1500ms",
                  animationDelay: "0ms",
                  animationName: "slideInUp",
                }}
              >
                <div class="inner-box p_relative d_block b_radius_10 pt_35 pr_30 pb_10 pl_30 centred">
                  <div class="icon-box p_relative fs_45 lh_45 mb_25 tran_5">
                    <i class="fas fa-calculator"></i>
                  </div>
                  <h3 class="d_block lh_30 fw_bold font_family_frank mb_20">
                    <a href="/compliance-services">Corporate Consultancy</a>
                  </h3>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 service-block">
              <div
                class="service-block-five wow slideInUp animated animated"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
                style={{
                  visibility: "visible",
                  animationDuration: "1500ms",
                  animationDelay: "0ms",
                  animationName: "slideInUp",
                }}
              >
                <div class="inner-box p_relative d_block b_radius_10 pt_35 pr_30 pb_30 pl_30 centred">
                  <div class="icon-box p_relative fs_45 lh_45 mb_25 tran_5">
                    <i class="far fa-laptop-code"></i>
                  </div>
                  <h3 class="d_block lh_30 fw_bold font_family_frank mb_20">
                    <a href="/company-incorporation-services">
                      Company Incorporation Services
                    </a>
                  </h3>
                </div>
              </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-12 service-block">
              <div
                class="service-block-five wow slideInUp animated animated"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
                style={{
                  visibility: "visible",
                  animationDuration: "1500ms",
                  animationDelay: "0ms",
                  animationName: "slideInUp",
                }}
              >
                <div class="inner-box p_relative d_block b_radius_10 pt_35 pr_30 pb_30 pl_30 centred">
                  <div class="icon-box p_relative fs_45 lh_45 mb_25 tran_5">
                    <i class="far fa-globe"></i>
                  </div>
                  <h3 class="d_block lh_30 fw_bold font_family_frank mb_20">
                    <a href="/merger-acquisitions">Mergers & Acquisitions</a>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {!isCookieHandler && <PrivacySection />}

      {/* future purpose */}
      {/* <section className="sec-4">
        <h2 className="tital-heading">Here we do business</h2>
        <div className="info-box">
          <div className="box">
            <div className="home-box-icons">
              <BsFillBuildingsFill size={50} />
            </div>
            <div className="box-content">
              <p>2 Office (Abu dubai & Delhi)</p>
            </div>
          </div>

          <div className="box">
            <div className="home-box-icons">
              <IoLocationSharp size={50} />
            </div>
            <div className="box-content">
              <p>Servicing in Middle East and India </p>
            </div>
          </div>

          <div className="box">
            <div className="home-box-icons">
              <IoCall size={50} />
            </div>
            <div className="box-content">
              <p>Call Us at (+971 567244122)</p>
            </div>
          </div>

          <div className="box">
            <div className="home-box-icons">
              <IoMail size={50} />
            </div>
            <div className="box-content">
              <p>marketing@prishauditors.com</p>
            </div>
          </div>

          <div className="box">
            <div className="home-box-icons">
              <FaPeopleGroup size={50} />
            </div>
            <div className="box-content">
              <p>100+ Clients Globally</p>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
};

export default Home;
