import React, { useEffect, useRef, useState } from "react";
import "../InnerSubMenus/style.css";
import account from "./images/account.jpeg";
import account1 from "./images/account1.jpeg";
import account2 from "./images/account2.jpeg";
import bgImage from "../Home/Images/homepage2.jpeg";
import bgImage1 from "../Home/Images/homepage3.jpg";
import bgImage2 from "../Home/Images/homepage4.jpg";
import bgImage3 from "../Home/Images/homepage5.jpg";
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md";
import Query from "../Components/Query/Query";

const Corporation = () => {
  const [image, setImage] = useState("");
  const [count, setCount] = useState(0);
  const scollToRef = useRef();
  useEffect(() => {
    scollToRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);
  const Imagesdata = [
    {
      images: bgImage,
      id: 1,
    },
    {
      images: bgImage1,
      id: 2,
    },
    {
      images: bgImage2,
      id: 3,
    },
    {
      images: bgImage3,
      id: 4,
    },
  ];
  useEffect(() => {
    setImage(Imagesdata[0].images);
  }, []);
  // Function is called everytime increment button is clicked
  const handleClick1 = () => {
    // Counter state is incremented
    if (count < 3) {
      setImage(Imagesdata[count + 1]?.images || bgImage);
      setCount(count + 1);
    } else {
      setCount(0);
      setImage(Imagesdata[0]?.images);
    }
  };

  // Function is called everytime decrement button is clicked
  const handleClick2 = () => {
    // Counter state is decremented
    if (count > 0) {
      setImage(Imagesdata[count - 1].images);
      setCount(count - 1);
    } else {
      setCount(3);
      setImage(Imagesdata[3].images);
    }
  };
  return (
    <>
      <section className="corporate-sec">
        <div
          className={`${"info"}`}
          style={{ backgroundImage: `url(${image})` }}
        >
          <h1 className="typo5">Accounting and Bookkeeping</h1>
          <div className="rightIcon">
            <div className="rightIconImageBox" onClick={handleClick1}>
              <MdArrowForwardIos color="#ffffff" />
            </div>
          </div>
          <div className="leftIcon" onClick={handleClick2}>
            <div className="leftIconImageBox">
              <MdArrowBackIos color="#ffffff" />
            </div>
          </div>
        </div>
        <div className="corporate-sec-1  ">
          <div className="corporate-img">
            <div class="hexagon">
              <img src={account} width="200" height="200" />
            </div>
            <div className="nextConatainer">
              <div class="hexagon1">
                <img src={account1} width="200" height="200" />
              </div>
            </div>
            <div class="hexagon2">
              <img src={account2} width="200" height="200" />
            </div>

            {/* <img src="./CorporateImage/img1.jpeg" alt="img" width={500} /> */}
          </div>
          <div className="info-paragraphs">
            <h3 ref={scollToRef} className="audit-header">
              Accounting and bookkeeping
            </h3>
            <p className="text">
              Accounting and bookkeeping services play a crucial role in helping
              businesses maintain accurate financial records, comply with
              regulatory requirements, and make informed financial decisions to
              support their growth and success.
            </p>
            <p className="text">
              While providing accounting and bookkeeping services, we ensure
              that financial statements comply with the International Financial
              Reporting Standards (IFRS) and we are committed to providing the
              services with the highest standards of integrity and quality.
            </p>
            <p className="text">
              Our team is fully capable with most of accounting software like
              Tally, Quick books, ZOHO, Xero, Microsoft Dynamics 365, SAP, etc.
              Be it periodic basis accounting or be it backlog accounting; you
              have PRISH to believe for all your accounting-related needs.
            </p>
            <p className="text">
              Our approach is understanding the importance of accurate financial
              records for business success. It is proactive, detail-oriented,
              and focused on delivering high-quality services that meet our
              clients' needs.
            </p>
            <br />
            {/* <h4>
              Detailed accounting and management processes covered by us are as
              follows:
            </h4> */}
            <h3 className="submenu-header ">Why Choose PRISH ?</h3>
            <p className="submenu-sub-header ">
              1- Accounts Receivable Management:{" "}
              <span>
                We ensure accurate and timely invoicing, assess, and manage
                credit risk, develop customized collection strategies, handle
                disputes and discrepancies, and accurately apply payments
                received to the appropriate customer accounts.
              </span>
            </p>
            <p className="submenu-sub-header ">
              2- Accounts Payable Management:{" "}
              <span>
                {" "}
                In this service, we handle the receipt, review of vendor
                invoices, manage payment processing, maintain vendor accounts,
                assist with expense reporting, and lastly reconcile accounts
                payable records with vendor statements.{" "}
              </span>
            </p>

            <p className="submenu-sub-header ">
              3- Bank Reconciliation:{" "}
              <span>
                We carefully match each transaction in the company’s accounting
                records with corresponding bank entries on the bank statement,
                identify discrepancies, diligently resolve the discrepancies,
                and provide the reconciliation reports. (SOPs)
              </span>
            </p>
            <p className="submenu-sub-header ">
              4- General Ledger Maintenance:{" "}
              <span>
                We accurately record all financial transactions in the general
                ledger, do reconciliation with subsidiary ledgers, perform
                month-end closing procedures, and lastly prepare financial
                reports.
              </span>
            </p>
            <p className="submenu-sub-header ">
              5- Payroll and Employee Management:{" "}
              <span>
                We handle all aspects of payroll processing, manage employee
                health insurance programs, ensure compliance with labour laws,
                help in leave and attendance management, and properly facilitate
                the onboarding process for new hires.
              </span>
            </p>
            <p className="submenu-sub-header ">
              6- Financial Analysis:{" "}
              <span>
                We analyse financial statements, calculate, and interpret key
                financial ratios, identify, and analyse financial trends,
                compare budgeted financial data with actual performance, and use
                quantitative techniques and historical data to forecast the
                future.
              </span>
            </p>
            <p className="submenu-sub-header ">
              7- Financial Reporting:{" "}
              <span>
                We prepare the financial statements, provide customized
                management reporting, ensure financial reports comply with
                regulatory requirements, offer consolidated financial reporting
                services to aggregate financial data from multiple entities, and
                lastly analyse financial data and provide insights to help
                stakeholders to understand financial statements.
              </span>
            </p>
          </div>
        </div>
        <div className="accordian-section">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  What is the difference between accounting and bookkeeping?
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Accounting involves interpreting, analysing, and summarizing
                  financial data to provide insights for decision-making, while
                  bookkeeping involves recording financial transactions and
                  maintaining financial records.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  Why do businesses need accounting and bookkeeping services?
                </button>
              </h2>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Accounting and bookkeeping services help businesses track
                  financial transactions, comply with tax regulations, make
                  informed decisions, and monitor financial health.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  What specific tasks are included in accounting and bookkeeping
                  services?
                </button>
              </h2>
              <div
                id="collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Accounting services may include financial statement
                  preparation, tax planning, and analysis of financial data,
                  while bookkeeping services typically involve recording
                  transactions, reconciling accounts, and managing accounts
                  payable and accounts receivable.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsefour"
                  aria-expanded="false"
                  aria-controls="collapsefour"
                >
                  How often should I update my accounting records?
                </button>
              </h2>
              <div
                id="collapsefour"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  It's recommended to update accounting records regularly,
                  ideally on a daily or weekly basis, to ensure accuracy and
                  timely financial reporting.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsefive"
                  aria-expanded="false"
                  aria-controls="collapsefive"
                >
                  Can outsourcing accounting and bookkeeping services save me
                  time and money?
                </button>
              </h2>
              <div
                id="collapsefive"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Yes, outsourcing accounting and bookkeeping services can save
                  time and resources for businesses, allowing them to focus on
                  core activities while ensuring accuracy and compliance in
                  financial management.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSix"
                  aria-expanded="false"
                  aria-controls="collapseSix"
                >
                  How do I choose the right accounting and bookkeeping service
                  provider?
                </button>
              </h2>
              <div
                id="collapseSix"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  When choosing a service provider, consider factors such as
                  expertise, experience, reputation, services offered,
                  technology used, and pricing.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSeven"
                  aria-expanded="false"
                  aria-controls="collapseSeven"
                >
                  What are the benefits of outsourcing accounting and
                  bookkeeping services?
                </button>
              </h2>
              <div
                id="collapseSeven"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Benefits include improved accuracy and compliance, cost
                  savings, access to expertise, streamlined processes, and
                  enhanced financial reporting.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseEight"
                  aria-expanded="false"
                  aria-controls="collapseEight"
                >
                  Can accounting and bookkeeping services help with tax
                  preparation?
                </button>
              </h2>
              <div
                id="collapseEight"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Yes, accounting and bookkeeping services often include tax
                  preparation services, ensuring compliance with tax regulations
                  and optimizing tax efficiency for businesses.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseNine"
                  aria-expanded="false"
                  aria-controls="collapseNine"
                >
                  How do businesses measure the ROI of accounting and
                  bookkeeping services?
                </button>
              </h2>
              <div
                id="collapseNine"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Businesses can measure ROI by evaluating improvements in
                  efficiency, productivity, customer satisfaction, security
                  posture, and cost savings achieved through accounting and
                  bookkeeping services.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Query />
    </>
  );
};

export default Corporation;
