import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import company from "./images/vat_tax1.jpeg";
import company1 from "./images/vat_tax4.jpeg";
import company2 from "./images/vat_tax3.jpeg";
import bgImage from "../Home/Images/homepage2.jpeg";
import bgImage1 from "../Home/Images/homepage3.jpg";
import bgImage2 from "../Home/Images/homepage4.jpg";
import bgImage3 from "../Home/Images/homepage5.jpg";
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md";
import Query from "../Components/Query/Query";

const Vat = () => {
  const [image, setImage] = useState("");
  const [count, setCount] = useState(0);
  const scollToRef = useRef();
  useEffect(() => {
    scollToRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);
  const Imagesdata = [
    {
      images: bgImage,
      id: 1,
    },
    {
      images: bgImage1,
      id: 2,
    },
    {
      images: bgImage2,
      id: 3,
    },
    {
      images: bgImage3,
      id: 4,
    },
  ];
  useEffect(() => {
    setImage(Imagesdata[0].images);
  }, []);
  // Function is called everytime increment button is clicked
  const handleClick1 = () => {
    // Counter state is incremented
    if (count < 3) {
      setImage(Imagesdata[count + 1]?.images || bgImage);
      setCount(count + 1);
    } else {
      setCount(0);
      setImage(Imagesdata[0]?.images);
    }
  };

  // Function is called everytime decrement button is clicked
  const handleClick2 = () => {
    // Counter state is decremented
    if (count > 0) {
      setImage(Imagesdata[count - 1].images);
      setCount(count - 1);
    } else {
      setCount(3);
      setImage(Imagesdata[3].images);
    }
  };
  return (
    <>
      <section className="corporate-sec">
        <div
          className={`${"info"}`}
          style={{ backgroundImage: `url(${image})` }}
        >
          <h1 className="typo5">Value Added Tax (VAT)</h1>
          <div className="rightIcon">
            <div className="rightIconImageBox" onClick={handleClick1}>
              <MdArrowForwardIos color="#ffffff" />
            </div>
          </div>
          <div className="leftIcon" onClick={handleClick2}>
            <div className="leftIconImageBox">
              <MdArrowBackIos color="#ffffff" />
            </div>
          </div>
        </div>
        <div className="corporate-sec-1  ">
          <div className="corporate-img">
            <div class="hexagon">
              <img src={company} width="200" height="200" />
            </div>
            <div className="nextConatainer">
              <div class="hexagon1">
                <img src={company1} width="200" height="200" />
              </div>
            </div>
            <div class="hexagon2">
              <img src={company2} width="200" height="200" />
            </div>

            {/* <img src="./CorporateImage/img1.jpeg" alt="img" width={500} /> */}
          </div>
          <div className="info-paragraphs">
            <h3 ref={scollToRef} className="audit-header">
              Value Added Tax (VAT)
            </h3>
            <p className="text">
              Assisting businesses in the UAE with the VAT registration process,
              providing support and guidance on compliance requirements such as
              filing VAT returns, maintaining VAT records, and implementing VAT
              accounting systems. We also provide VAT Advisory services for the
              application of VAT to specific transactions, products, and
              services, VAT planning, and optimization.UAE VAT services
              encompass a range of activities aimed at helping businesses
              understand, implement, and manage VAT compliance requirements by
              the regulations set by the Federal Tax Authority (FTA) of the UAE.
            </p>
            <p className="text">
              Our experienced team provides expert guidance and support to
              ensure compliance with VAT regulations, optimize tax efficiency,
              and mitigate risks associated with VAT implementation and
              reporting.
            </p>
            <p className="text">
              Our approach is to understand the complexities of VAT regulations
              and the challenges businesses face in complying with them. Our
              approach to UAE VAT services is tailored to meet the specific
              needs and objectives of each client. We provide a comprehensive
              range of services, including VAT registration, advisory,
              compliance, and representation.
            </p>
            <br />
            {/* <h4>
              Detailed accounting and management processes covered by us are as
              follows:
            </h4> */}
            <h3 className="submenu-header ">Why Choose PRISH ?</h3>
            <p className="submenu-sub-header ">
              VAT Registration:{" "}
              <span className="text">
                We assist businesses with VAT registration, ensuring they meet
                all requirements and deadlines set by the FTA.
              </span>
            </p>

            <p className="submenu-sub-header ">
              VAT Advisory:
              <span className="text">
                Our expert advisors guide VAT compliance, including the
                classification of supplies, the VAT treatment of transactions,
                and implications for business operations.
              </span>{" "}
            </p>
            <p className="submenu-sub-header ">
              VAT Compliance:
              <span className="text">
                We help businesses prepare and submit accurate VAT returns,
                maintain proper records, and comply with filing deadlines to
                avoid penalties.
              </span>
            </p>
            <p className="submenu-sub-header ">
              VAT Representation:
              <span className="text">
                Our team can act as a liaison between businesses and the FTA,
                representing clients during tax audits, assessments, and
                disputes.
              </span>
            </p>
          </div>
        </div>
        <div className="accordian-section">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  What is VAT?
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Firstly, VAT (Value Added Tax) is a consumption tax,
                  whichmeans that it is ultimately paid by the end consumer.
                  Although, VAT is charged at each step of the ‘supply chain,’
                  it is the end user who bears the cost of Value Added Tax.
                  <br />
                  VAT is collected by the businesses and then they pay it
                  forward to the government. In case, a business has paid more
                  tax to its suppliers than it has received, it can get a refund
                  from the government. Ultimately, the tax receipts to
                  government show ‘value add’ throughout the supply chain.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  What is UAE VAT Registration?
                </button>
              </h2>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  By VAT Registration, we mean to register the company with the
                  government for submitting Value Added Tax. Upon successful
                  registration, the concerned tax authority in the UAE, namely
                  Federal Tax Authority (FTA) issues a unique VAT identification
                  to the company.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  What is the difference between VAT and Sales Tax?
                </button>
              </h2>
              <div
                id="collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  VAT and sales tax are both consumption taxes; these both are
                  charged from the end-consumer. However, there are a few
                  differences
                  <ul>
                    <li>
                      Sales tax is generally imposed on transactions involving
                      goods, while VAT is applied on goods as well as services.
                    </li>
                    <li>
                      Furthermore, sales tax is imposed only on the final sale
                      to end-consumer. However, VAT is charged on each step of
                      the ‘supply chain.’
                    </li>
                  </ul>
                  In VAT (Value Added Tax) based systems, businesses serve as
                  tax-collectors on behalf of government, which helps reduce
                  tax-evasion and misreporting.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsefour"
                  aria-expanded="false"
                  aria-controls="collapsefour"
                >
                  Which Companies are Required to Register for VAT in the UAE?
                </button>
              </h2>
              <div
                id="collapsefour"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  The businesses having taxable imports and supplies that exceed
                  the amount of 375,000 AED must register for VAT. Besides,
                  companies exceeding the amount of 187,500 AED can also
                  register for VAT voluntarily; it is not mandatory for them to
                  register.
                  <br />
                  In case a company fails to register for VAT, they become
                  liable to pay penalties and face legal consequences.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsefive"
                  aria-expanded="false"
                  aria-controls="collapsefive"
                >
                  How Much VAT is Charged in the UAE?
                </button>
              </h2>
              <div
                id="collapsefive"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  The standard rate of VAT is 5% (Five Percent) in the UAE.
                  However, there are a few sectors that are exempt from VAT.
                  Additionally, there are also zero-rated supplies that are
                  rated at 0% instead of 5%.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSix"
                  aria-expanded="false"
                  aria-controls="collapseSix"
                >
                  Which sectors are exempt from VAT?
                </button>
              </h2>
              <div
                id="collapseSix"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  The following categories of supplies are exempt from VAT.
                  These supplies should be traded within The United Arab
                  Emirates.
                  <ul>
                    <li>Financial Services</li>
                    <li>Residential properties</li>
                    <li>Bare land</li>
                    <li>Local passenger transport</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSeven"
                  aria-expanded="false"
                  aria-controls="collapseSeven"
                >
                  Which are zero-rated sectors?
                </button>
              </h2>
              <div
                id="collapseSeven"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  If you are trading in zero-rated supplies, you will be charged
                  0% VAT. However, it’s important to remember that these
                  supplies must be declared properly in VAT returns submitted
                  during every tax period. The following supplies are charged
                  with 0% VAT.
                  <ul>
                    <li>
                      Precious metals, such as gold and silver. Which are 99%
                      pure and available in tradable form.
                    </li>
                    <li>International transportation, and related supplies.</li>
                    <li>
                      Supplies of certain sea, air, and land means of
                      transportation (such as aircrafts and ships).
                    </li>
                    <li>
                      Certain investment grade precious metals (e.g. gold,
                      silver, of 99% purity).
                    </li>
                    <li>
                      Newly constructed residential properties, that are
                      supplied for the first time within three years of their
                      construction.
                    </li>
                    <li>
                      Supply of certain education services, and the related
                      goods and services.
                    </li>
                    <li>
                      Supply of certain healthcare services, and the of related
                      goods and services.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseEight"
                  aria-expanded="false"
                  aria-controls="collapseEight"
                >
                  How to Register for VAT in UAE?
                </button>
              </h2>
              <div
                id="collapseEight"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  To register for VAT, an application – along with required
                  documents – is forwarded to the Federal Tax Authority (FTA).
                  Upon successful submission of the request, FTA will issue a
                  VAT Registration Certificate, which can be downloaded online
                  from the applicant’s account.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseEight"
                  aria-expanded="false"
                  aria-controls="collapseEight"
                >
                  What are the Required Documents for VAT Registration in the
                  UAE?
                </button>
              </h2>
              <div
                id="collapseEight"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  The companies registering for VAT in the UAE are required to
                  submit the following documents for the registration process:
                  <ul>
                    <li>
                      Passport Copies – of business partners/owners (As
                      mentioned on business license)
                    </li>
                    <li>
                      Emirates ID – of business partners/owners (As mentioned on
                      the license)
                    </li>
                    <li>Business License - (Trade/Commercial License)</li>
                    <li>Complete Address of the company</li>
                    <li>Details of any branches of the company if any</li>
                    <li>Memorandum of Association (MOA)</li>
                    <li>Company’s bank account details – with IBAN letter</li>
                    <li>
                      Contact details of the authorized signatory, including
                      email and phone number
                    </li>
                    <li>Details of any branches, if applicable</li>
                    <li>
                      Expected turnover, revenue and taxable expenses for the
                      next 30 days
                    </li>
                    <li>
                      Turnover Declaration – with stamp and signature by the
                      owner or the manager.
                    </li>
                    <li>
                      Custom code – along with a copy of Dubai Customs Letter.
                    </li>
                    <li>GCC export or import details – if applicable</li>
                    <li>
                      Preference for or against registration as a Tax Group
                    </li>
                  </ul>
                  Depending upon the jurisdictions or business activities, some
                  additional documents may also be required for the VAT
                  Registration process.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Query />
    </>
  );
};

export default Vat;
