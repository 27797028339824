import React from "react";
import ".//bookkeeping.css";
import img from "../Images/img-7.jpeg";
import { GoDotFill } from "react-icons/go";

const Bookkeeping = () => {
  return (
    <>
      <div className="info">
        <h1 className="typo4">Bookkeeping & Accounting</h1>
      </div>
      <section className="bookkeeping-sec">
        <h1>Technology Consulting</h1>
        <div className="bookkeeping-info-container">
          <div className="bookkeeping-img">
            <img src={img} alt="img" width={500} />
          </div>
          <div className="info-paragraphs">
            <p className="text">
              Prish ensures the IT and business project implementations are
              becoming more complex and interwoven with the rapid advancements
              in technology globally. Organizations need help in building their
              capacity to deliver the right projects on time, with the right
              benefits. A substantial hurdle that organizations fail to navigate
              over in their projects is that of on-time delivery, closely
              followed by meeting the planned objectives and aligning them with
              stakeholder expectations.
            </p>
            <br />
            <p className="text">
              Companies need to understand that in order to ensure success, each
              phase of a project requires meticulous attention and planning.
              However, most organizations do not have the specialized resources
              to guarantee this. At JRB, our consultants are experts in all
              areas of IT project management and are driven by a keen desire to
              help our clients achieve optimal output through project efficacy.
            </p>
          </div>
        </div>

        <div className="bookeeping-container-2">
          <div className="bookkeeping-info">
            <h2>ERP & Software Implementations</h2>
            <p className="text">
              Our IT project advisory services are aimed to ensure your projects
              are conceptualized, guided, and delivered on time, every time. Our
              tried and tested methodologies ensure minimal risk of project
              failure through streamlined process definition, helping our
              clients unlock the true potential of their business. If history
              has shown us anything, it has been the unparalleled success of
              those who lead and establish market trends over those who follow
              such pioneers. This is not to say that all businesses have to be
              trendsetters – but the right steps have to be taken at the right
              time, within the parameters of the economic conditions at the
              time, in order to ensure the success of any project.
            </p>
            <p className="text">
              Our team of experts supports organizations by guiding them at each
              stage of an IT project from initiation to closure – so no matter
              which stage of a project you are in, you will be in safe hands.
            </p>
            <br />
          </div>
          <div className="bookkeeping-more-info">
            <div className="bookkeeping-info-box">
              <h3>
                {" "}
                <GoDotFill />
                Identifying and documenting your business requirements
              </h3>
            </div>
            <div className="bookkeeping-info-box">
              <h3>
                <GoDotFill /> Vetting software contracts and negotiating with
                the software service providers
              </h3>
            </div>
            <div className="bookkeeping-info-box">
              <h3>
                <GoDotFill /> Preparing a Request for Proposal (RFP) or Request
                for Information (RFI)
              </h3>
            </div>
            <div className="bookkeeping-info-box">
              <h3>
                {" "}
                <GoDotFill /> Planning your software project
              </h3>
            </div>
            <div className="bookkeeping-info-box">
              <h3>
                {" "}
                <GoDotFill /> Organizing and evaluating software demos
              </h3>
            </div>
            <div className="bookkeeping-info-box">
              <h3>
                {" "}
                <GoDotFill /> IT Project Management
              </h3>
            </div>
            <div className="bookkeeping-info-box">
              <h3>
                {" "}
                <GoDotFill /> Evaluating software and service providers
              </h3>
            </div>
            <div className="bookkeeping-info-box">
              <h3>
                <GoDotFill /> Obtaining support services after an IT Project
                completion
              </h3>
            </div>
            <div className="bookkeeping-info-box">
              <h3>
                {" "}
                <GoDotFill /> Selecting the most suitable vendor for your
                project
              </h3>
            </div>
          </div>
        </div>

        <div className="bookkeeping-container-3">
          <div className="bookkeeping-benefits">
            <h2>AML Screening & Compliance Software</h2>
            <p className="text">
              Through our channel partners, we deliver professional services for
              the Anti money Laundering (AML) compliance screening tool to
              comply with the relevant regulatory requirements and to safeguard
              a business from the risks of money laundering and terrorist
              financing. We understand the risks our clients face and offer
              solutions addressing key elements of compliance, including:
            </p>
          </div>
          <div className="bookkeeping-list">
            <ul>
              <li>
                <p className="text">Screening</p>
              </li>
              <li>
                <p className="text">Risk Based Assessment</p>
              </li>
              <li>
                <p className="text">On-Going Monitoring</p>
              </li>
              <li>
                <p className="text">Reporting</p>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default Bookkeeping;
