import React, { useEffect, useRef, useState } from "react";
import ".//corporate.css";
import audit from "./Images/audit.jpg";
import audit1 from "./Images/audit1.jpg";
import bgImage from "../Home/Images/homepage2.jpeg";
import bgImage1 from "../Home/Images/homepage3.jpg";
import bgImage2 from "../Home/Images/homepage4.jpg";
import bgImage3 from "../Home/Images/homepage5.jpg";
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Query from "../Components/Query/Query";

function TaxationService() {
  const [image, setImage] = useState("");
  const [count, setCount] = useState(0);
  const scollToRef = useRef();
  useEffect(() => {
    scollToRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);
  const navigate = useNavigate();

  const Imagesdata = [
    {
      images: bgImage,
      id: 1,
    },
    {
      images: bgImage1,
      id: 2,
    },
    {
      images: bgImage2,
      id: 3,
    },
    {
      images: bgImage3,
      id: 4,
    },
  ];
  useEffect(() => {
    setImage(Imagesdata[0].images);
  }, []);
  // Function is called everytime increment button is clicked
  const handleClick1 = () => {
    // Counter state is incremented
    if (count < 3) {
      setImage(Imagesdata[count + 1]?.images || bgImage);
      setCount(count + 1);
    } else {
      setCount(0);
      setImage(Imagesdata[0]?.images);
    }
  };

  // Function is called everytime decrement button is clicked
  const handleClick2 = () => {
    // Counter state is decremented
    if (count > 0) {
      setImage(Imagesdata[count - 1].images);
      setCount(count - 1);
    } else {
      setCount(3);
      setImage(Imagesdata[3].images);
    }
  };
  return (
    <>
      <section className="corporate-sec">
        <div
          className={`${"info"}`}
          style={{ backgroundImage: `url(${image})` }}
        >
          <h1 className="typo5">Taxation Services</h1>
          <div className="rightIcon">
            <div className="rightIconImageBox" onClick={handleClick1}>
              <MdArrowForwardIos color="#ffffff" />
            </div>
          </div>
          <div className="leftIcon" onClick={handleClick2}>
            <div className="leftIconImageBox">
              <MdArrowBackIos color="#ffffff" />
            </div>
          </div>
        </div>
        <div className="corporate-sec-1  ">
          <div className="corporate-img">
            <div class="hexagon">
              <img src="./CorporateImage/img1.jpeg" width="200" height="200" />
            </div>
            <div className="nextConatainer">
              <div class="hexagon1">
                <img src={audit} width="200" height="200" />
              </div>
            </div>
            <div class="hexagon2">
              <img src={audit1} width="200" height="200" />
            </div>

            {/* <img src="./CorporateImage/img1.jpeg" alt="img" width={500} /> */}
          </div>
          <div className="info-paragraphs">
            <h3 ref={scollToRef} className="audit-header">
              Taxation Services
            </h3>
            <p className="text">
              Taxation services in the UAE play a crucial role in helping
              individuals and businesses navigate the complexities of tax laws
              and regulations, optimize their tax positions, and ensure
              compliance with tax requirements
            </p>
            <p className="text">
              By leveraging the expertise of tax professionals and consultants
              at PRISH, our clients can effectively manage their taxation risks
              and obligations while maximizing tax efficiency and compliance
              procedure
            </p>
            <br />
            <h4
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/value-added-tax")}
            >
              Value Added Tax (VAT)
            </h4>
            <p className="text">
              Assisting businesses in the UAE with the VAT registration process,
              providing support and guidance on compliance requirements such as
              filing VAT returns, maintaining VAT records, and implementing VAT
              accounting systems. We also provide VAT Advisory services for
              application of VAT to specific transactions, products and
              services, VAT planning and optimization.
              <button
                onClick={() => navigate("/value-added-tax")}
                className="read-more-btn-1"
              >
                Read more
              </button>
            </p>
            <br />
            <h4
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/uae-corporate-tax")}
            >
              UAE Corporate Tax
            </h4>
            <p className="text">
              Corporate Tax has been introduced for the very first time in UAE
              with effect from 1st June 2023 and since the emerging law requires
              a vibrant understanding for our clients, we hereby provide UAE
              Corporate Tax Impact Assessment and Analysis, Registration, Return
              filing, Corporate Tax Consultancy based on the specific businesses
              and their operations
              <button
                onClick={() => navigate("/uae-corporate-tax")}
                className="read-more-btn-1"
              >
                Read more
              </button>
            </p>

            {/*  */}
            <h4
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/transfer-pricing")}
            >
              Transfer Pricing
            </h4>
            <p className="text">
              Assisting corporates in complying with transfer pricing
              regulations in the UAE and other jurisdictions, including
              documentation requirements, transfer pricing adjustments, advising
              businesses on transfer pricing strategies and transfer pricing
              planning.
              <button
                onClick={() => navigate("/transfer-pricing")}
                className="read-more-btn-1"
              >
                Read more
              </button>
            </p>

            <h4
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/tax-residency-certificate")}
            >
              Tax Residency Certificate (TRC)
            </h4>
            <p className="text">
              A tax residency (Domicile) certificate in UAE is issued by the
              International Financial Relations and Organizations Department
              which permits eligible government entities, companies and
              individuals to take advantage of agreements of double taxation
              avoidance. We at PRISH provide professional assistance in
              coordinating with the ministry offices and availing the tax
              residency (domicile) certificate.
              <button
                onClick={() => navigate("/tax-residency-certificate")}
                className="read-more-btn-1"
              >
                Read more
              </button>
            </p>
          </div>
        </div>
      </section>
      <Query />
    </>
  );
}

export default TaxationService;
