import React, { useState } from "react";
import "../Components/style.css";
import logosvg from "./Images/logo5.png";
import Hamburger from "./Hamburger";
import { RiArrowDropDownLine } from "react-icons/ri";

function Header() {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNav = () => {
    console.log("Toggle function called");
    setIsNavOpen(!isNavOpen);
  };

  console.log("Is nav open?", isNavOpen);

  return (
    <>
      {/* // Header */}
      <header id="header" class="header header-style4">
        <div class="container mainBox">
          <div class="home_row">
            <div class="col-md-12">
              <div class="header-wrap clearfix">
                <div
                  id="logo"
                  class="logo"
                  style={{ marginTop: "15px", marginBottom: "0px" }}
                  data-width="350"
                  data-height="50"
                >
                  <a href="/" title="Prish">
                    <img
                      src={logosvg} // Will change later
                    />
                  </a>
                </div>

                <div class="nav-wrap">
                  <div class="btn-menu">
                    <span></span>
                  </div>

                  <nav id="mainnav" class="mainnav" role="navigation">
                    <ul id="menu-trcpamco-menu" class="menu">
                      <li
                        id="menu-item-2231"
                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-5 current_page_item menu-item-2231"
                      >
                        <a href="/" aria-current="page">
                          Home
                        </a>
                      </li>
                      <li
                        id="menu-item-1931"
                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1931"
                      >
                        <a href="/about">About Us</a>
                      </li>

                      <li
                        id="menu-item-7276"
                        class="menu-item menu-item-type-custom menu-item-object-custom menu-item-7276"
                      >
                        <a href="/blog">Blog</a>
                      </li>

                      <li
                        id="menu-item-1933"
                        class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1933"
                      >
                        <a href="/reach">Reach Us</a>
                      </li>
                    </ul>{" "}
                  </nav>
                </div>
              </div>
              <div class="submenu top-search widget_search">
                <form role="search" method="get" class="search-form" action="/">
                  <label>
                    <span class="screen-reader-text">Search for:</span>
                    <input
                      type="search"
                      class="search-field"
                      placeholder="Search …"
                      value=""
                      name="s"
                    />
                  </label>
                  <input type="submit" class="search-submit" value="Search" />
                </form>{" "}
              </div>
            </div>
          </div>
        </div>
        <nav class="ubermenu ubermenu-main ubermenu-menu-37 ubermenu-loc-my-custom-menu ubermenu-responsive ubermenu-responsive-default ubermenu-responsive-collapse ubermenu-horizontal ubermenu-transition-shift ubermenu-trigger-hover ubermenu-skin-none ubermenu-bar-align-full ubermenu-items-align-center ubermenu-bound-inner ubermenu-sub-indicators ubermenu-retractors-responsive ubermenu-notouch">
          <ul id="ubermenu-nav-main-37-my-custom-menu" class="ubermenu-nav">
            <li
              id="menu-item-8610"
              class="ubermenu-item ubermenu-item-type-custom ubermenu-item-object-custom ubermenu-item-has-children ubermenu-item-8610 ubermenu-item-level-0 ubermenu-column ubermenu-column-auto ubermenu-has-submenu-drop ubermenu-has-submenu-mega"
            >
              <a
                class="ubermenu-target ubermenu-item-layout-default ubermenu-item-layout-text_only"
                tabindex="0"
                href="/auditing-assurance-services"
              >
                <span class="ubermenu-target-title ubermenu-target-text">
                  AUDIT &amp; ASSURANCE
                </span>
                <RiArrowDropDownLine />
              </a>
              <ul class="submenu">
                <li class="ubermenu-retractor ubermenu-retractor-mobile">
                  <i class="fa fa-times"></i> Close
                </li>
                <li
                  id="menu-item-5682"
                  class="ubermenu-item ubermenu-item-type-post_type ubermenu-item-object-page ubermenu-item-5682 ubermenu-item-auto ubermenu-item-header ubermenu-item-level-1 ubermenu-column ubermenu-column-auto"
                >
                  <a
                    class="ubermenu-target ubermenu-item-layout-default ubermenu-item-layout-text_only"
                    href="/satutory-audit"
                  >
                    <span class="ubermenu-target-title ubermenu-target-text">
                      Statutory Audit
                    </span>
                  </a>
                </li>
                <li
                  id="menu-item-8276"
                  class="ubermenu-item ubermenu-item-type-post_type ubermenu-item-object-page ubermenu-item-8276 ubermenu-item-auto ubermenu-item-header ubermenu-item-level-1 ubermenu-column ubermenu-column-auto"
                >
                  <a
                    class="ubermenu-target ubermenu-item-layout-default ubermenu-item-layout-text_only"
                    href="/concurrent-audit"
                  >
                    <span class="ubermenu-target-title ubermenu-target-text">
                      Concurrent Audit
                    </span>
                  </a>
                </li>
                <li
                  id="menu-item-5683"
                  class="ubermenu-item ubermenu-item-type-post_type ubermenu-item-object-page ubermenu-item-5683 ubermenu-item-auto ubermenu-item-header ubermenu-item-level-1 ubermenu-column ubermenu-column-auto"
                >
                  <a
                    class="ubermenu-target ubermenu-item-layout-default ubermenu-item-layout-text_only"
                    href="/forensic-audit"
                  >
                    <span class="ubermenu-target-title ubermenu-target-text">
                      Forensic Audit
                    </span>
                  </a>
                </li>
                <li
                  id="menu-item-5684"
                  class="ubermenu-item ubermenu-item-type-post_type ubermenu-item-object-page ubermenu-item-5684 ubermenu-item-auto ubermenu-item-header ubermenu-item-level-1 ubermenu-column ubermenu-column-auto"
                >
                  <a
                    class="ubermenu-target ubermenu-item-layout-default ubermenu-item-layout-text_only"
                    href="/inventory-audit"
                  >
                    <span class="ubermenu-target-title ubermenu-target-text">
                      Inventory Audit and Verification
                    </span>
                  </a>
                </li>
                <li
                  id="menu-item-5685"
                  class="ubermenu-item ubermenu-item-type-post_type ubermenu-item-object-page ubermenu-item-5685 ubermenu-item-auto ubermenu-item-header ubermenu-item-level-1 ubermenu-column ubermenu-column-auto"
                >
                  <a
                    class="ubermenu-target ubermenu-item-layout-default ubermenu-item-layout-text_only"
                    href="/mystery-audit"
                  >
                    <span class="ubermenu-target-title ubermenu-target-text">
                      Mystery Audit
                    </span>
                  </a>
                </li>

                <li class="ubermenu-retractor ubermenu-retractor-mobile">
                  <i class="fa fa-times"></i> Close
                </li>
              </ul>
            </li>

            {/* Special Audit */}
            <li
              id="menu-item-8610"
              class="ubermenu-item ubermenu-item-type-custom ubermenu-item-object-custom ubermenu-item-has-children ubermenu-item-8610 ubermenu-item-level-0 ubermenu-column ubermenu-column-auto ubermenu-has-submenu-drop ubermenu-has-submenu-mega"
            >
              <a
                class="ubermenu-target ubermenu-item-layout-default ubermenu-item-layout-text_only"
                tabindex="0"
                href="/special-audit"
              >
                <span class="ubermenu-target-title ubermenu-target-text">
                  SPECIAL AUDITS
                </span>
                <RiArrowDropDownLine />
              </a>
              <ul class="submenu">
                <li class="ubermenu-retractor ubermenu-retractor-mobile">
                  <i class="fa fa-times"></i> Close
                </li>
                <li
                  id="menu-item-5682"
                  class="ubermenu-item ubermenu-item-type-post_type ubermenu-item-object-page ubermenu-item-5682 ubermenu-item-auto ubermenu-item-header ubermenu-item-level-1 ubermenu-column ubermenu-column-auto"
                >
                  <a
                    class="ubermenu-target ubermenu-item-layout-default ubermenu-item-layout-text_only"
                    href="/gold-audit"
                  >
                    <span class="ubermenu-target-title ubermenu-target-text">
                      Gold Audit
                    </span>
                  </a>
                </li>
                <li
                  id="menu-item-8276"
                  class="ubermenu-item ubermenu-item-type-post_type ubermenu-item-object-page ubermenu-item-8276 ubermenu-item-auto ubermenu-item-header ubermenu-item-level-1 ubermenu-column ubermenu-column-auto"
                >
                  <a
                    class="ubermenu-target ubermenu-item-layout-default ubermenu-item-layout-text_only"
                    href="/system-tax-audit"
                  >
                    <span class="ubermenu-target-title ubermenu-target-text">
                      System Audit
                    </span>
                  </a>
                </li>
                <li
                  id="menu-item-5683"
                  class="ubermenu-item ubermenu-item-type-post_type ubermenu-item-object-page ubermenu-item-5683 ubermenu-item-auto ubermenu-item-header ubermenu-item-level-1 ubermenu-column ubermenu-column-auto"
                >
                  <a
                    class="ubermenu-target ubermenu-item-layout-default ubermenu-item-layout-text_only"
                    href="/transaction-audit"
                  >
                    <span class="ubermenu-target-title ubermenu-target-text">
                      Transaction Audit
                    </span>
                  </a>
                </li>
                <li
                  id="menu-item-5684"
                  class="ubermenu-item ubermenu-item-type-post_type ubermenu-item-object-page ubermenu-item-5684 ubermenu-item-auto ubermenu-item-header ubermenu-item-level-1 ubermenu-column ubermenu-column-auto"
                >
                  <a
                    class="ubermenu-target ubermenu-item-layout-default ubermenu-item-layout-text_only"
                    href="/rera-audit"
                  >
                    <span class="ubermenu-target-title ubermenu-target-text">
                      Rera Audit
                    </span>
                  </a>
                </li>
                <li
                  id="menu-item-5685"
                  class="ubermenu-item ubermenu-item-type-post_type ubermenu-item-object-page ubermenu-item-5685 ubermenu-item-auto ubermenu-item-header ubermenu-item-level-1 ubermenu-column ubermenu-column-auto"
                >
                  <a
                    class="ubermenu-target ubermenu-item-layout-default ubermenu-item-layout-text_only"
                    href="/sales-tax-audit"
                  >
                    <span class="ubermenu-target-title ubermenu-target-text">
                      Sales Tax Certification
                    </span>
                  </a>
                </li>

                <li class="ubermenu-retractor ubermenu-retractor-mobile">
                  <i class="fa fa-times"></i> Close
                </li>
              </ul>
            </li>
            {/* End Special */}
            <li
              id="menu-item-5429"
              class="ubermenu-item ubermenu-item-type-post_type ubermenu-item-object-page ubermenu-item-has-children ubermenu-item-5429 ubermenu-item-level-0 ubermenu-column ubermenu-column-auto ubermenu-has-submenu-drop ubermenu-has-submenu-mega"
            >
              <a
                class="ubermenu-target ubermenu-item-layout-default ubermenu-item-layout-text_only"
                href="/risk-advisory-services"
                tabindex="0"
              >
                <span class="ubermenu-target-title ubermenu-target-text">
                  RISK ASSURANCE
                </span>
                <RiArrowDropDownLine />
              </a>
              <ul class="submenu">
                <li class="ubermenu-retractor ubermenu-retractor-mobile">
                  <i class="fa fa-times"></i> Close
                </li>
                <li
                  id="menu-item-5439"
                  class="ubermenu-item ubermenu-item-type-post_type ubermenu-item-object-page ubermenu-item-5439 ubermenu-item-auto ubermenu-item-header ubermenu-item-level-1 ubermenu-column ubermenu-column-auto"
                >
                  <a
                    class="ubermenu-target ubermenu-item-layout-default ubermenu-item-layout-text_only"
                    href="/internal-audit"
                  >
                    <span class="ubermenu-target-title ubermenu-target-text">
                      Internal Audit
                    </span>
                  </a>
                </li>
                <li
                  id="menu-item-5430"
                  class="ubermenu-item ubermenu-item-type-post_type ubermenu-item-object-page ubermenu-item-5430 ubermenu-item-auto ubermenu-item-header ubermenu-item-level-1 ubermenu-column ubermenu-column-auto"
                >
                  <a
                    class="ubermenu-target ubermenu-item-layout-default ubermenu-item-layout-text_only"
                    href="/standard-operating"
                  >
                    <span class="ubermenu-target-title ubermenu-target-text">
                      Standard Operating Procedures (SOPs)
                    </span>
                  </a>
                </li>
                <li
                  id="menu-item-5430"
                  class="ubermenu-item ubermenu-item-type-post_type ubermenu-item-object-page ubermenu-item-5430 ubermenu-item-auto ubermenu-item-header ubermenu-item-level-1 ubermenu-column ubermenu-column-auto"
                >
                  <a
                    class="ubermenu-target ubermenu-item-layout-default ubermenu-item-layout-text_only"
                    href="/risk-control"
                  >
                    <span class="ubermenu-target-title ubermenu-target-text">
                      Risk Control Matrix (RCM)
                    </span>
                  </a>
                </li>
                <li class="ubermenu-retractor ubermenu-retractor-mobile">
                  <i class="fa fa-times"></i> Close
                </li>
              </ul>
            </li>
            {/* Taxation */}

            <li
              id="menu-item-5429"
              class="ubermenu-item ubermenu-item-type-post_type ubermenu-item-object-page ubermenu-item-has-children ubermenu-item-5429 ubermenu-item-level-0 ubermenu-column ubermenu-column-auto ubermenu-has-submenu-drop ubermenu-has-submenu-mega"
            >
              <a
                class="ubermenu-target ubermenu-item-layout-default ubermenu-item-layout-text_only"
                href="/tax-services"
                tabindex="0"
              >
                <span class="ubermenu-target-title ubermenu-target-text">
                  TAXATION
                </span>
                <RiArrowDropDownLine />
              </a>
              <ul class="submenu">
                <li class="ubermenu-retractor ubermenu-retractor-mobile">
                  <i class="fa fa-times"></i> Close
                </li>
                <li
                  id="menu-item-5439"
                  class="ubermenu-item ubermenu-item-type-post_type ubermenu-item-object-page ubermenu-item-5439 ubermenu-item-auto ubermenu-item-header ubermenu-item-level-1 ubermenu-column ubermenu-column-auto"
                >
                  <a
                    class="ubermenu-target ubermenu-item-layout-default ubermenu-item-layout-text_only"
                    href="/value-added-tax"
                  >
                    <span class="ubermenu-target-title ubermenu-target-text">
                      Value Added Tax (VAT)
                    </span>
                  </a>
                </li>
                <li
                  id="menu-item-5430"
                  class="ubermenu-item ubermenu-item-type-post_type ubermenu-item-object-page ubermenu-item-5430 ubermenu-item-auto ubermenu-item-header ubermenu-item-level-1 ubermenu-column ubermenu-column-auto"
                >
                  <a
                    class="ubermenu-target ubermenu-item-layout-default ubermenu-item-layout-text_only"
                    href="/uae-corporate-tax"
                  >
                    <span class="ubermenu-target-title ubermenu-target-text">
                      UAE Corporate Tax
                    </span>
                  </a>
                </li>
                <li
                  id="menu-item-5430"
                  class="ubermenu-item ubermenu-item-type-post_type ubermenu-item-object-page ubermenu-item-5430 ubermenu-item-auto ubermenu-item-header ubermenu-item-level-1 ubermenu-column ubermenu-column-auto"
                >
                  <a
                    class="ubermenu-target ubermenu-item-layout-default ubermenu-item-layout-text_only"
                    href="/transfer-pricing"
                  >
                    <span class="ubermenu-target-title ubermenu-target-text">
                      Transfer Pricing
                    </span>
                  </a>
                </li>
                <li
                  id="menu-item-5430"
                  class="ubermenu-item ubermenu-item-type-post_type ubermenu-item-object-page ubermenu-item-5430 ubermenu-item-auto ubermenu-item-header ubermenu-item-level-1 ubermenu-column ubermenu-column-auto"
                >
                  <a
                    class="ubermenu-target ubermenu-item-layout-default ubermenu-item-layout-text_only"
                    href="/tax-residency-certificate"
                  >
                    <span class="ubermenu-target-title ubermenu-target-text">
                      Tax Residency Certificate (TRC)
                    </span>
                  </a>
                </li>
                <li class="ubermenu-retractor ubermenu-retractor-mobile">
                  <i class="fa fa-times"></i> Close
                </li>
              </ul>
            </li>
            {/* Enc taxation */}
            <li
              id="menu-item-5337"
              class="ubermenu-item ubermenu-item-type-post_type ubermenu-item-object-page ubermenu-item-5337 ubermenu-item-level-0 ubermenu-column ubermenu-column-auto"
            >
              <a
                class="ubermenu-target ubermenu-item-layout-default ubermenu-item-layout-text_only"
                href="/accounting-outsourcing-services"
                tabindex="0"
              >
                <span class="ubermenu-target-title ubermenu-target-text">
                  ACCOUNTING &amp; BOOKKEEPING
                </span>
              </a>
            </li>
            <li
              id="menu-item-5332"
              class="ubermenu-item ubermenu-item-type-post_type ubermenu-item-object-page ubermenu-item-has-children ubermenu-item-5332 ubermenu-item-level-0 ubermenu-column ubermenu-column-auto ubermenu-has-submenu-drop ubermenu-has-submenu-mega"
            >
              <a
                class="ubermenu-target ubermenu-item-layout-default ubermenu-item-layout-text_only"
                href="/anti-money-laundering"
                tabindex="0"
              >
                <span class="ubermenu-target-title ubermenu-target-text">
                  Anti Money Laundering (AML) Compliances
                </span>
              </a>
            </li>
            <li
              id="menu-item-8611"
              class="ubermenu-item ubermenu-item-type-custom ubermenu-item-object-custom ubermenu-item-has-children ubermenu-item-8611 ubermenu-item-level-0 ubermenu-column ubermenu-column-auto ubermenu-has-submenu-drop ubermenu-has-submenu-mega"
            >
              <a
                class="ubermenu-target ubermenu-item-layout-default ubermenu-item-layout-text_only"
                href="/compliance-services"
                tabindex="0"
              >
                <span class="ubermenu-target-title ubermenu-target-text">
                  Corporate Consultancy
                </span>
                <RiArrowDropDownLine />
              </a>
              <ul class="submenu">
                <li class="ubermenu-retractor ubermenu-retractor-mobile">
                  <i class="fa fa-times"></i> Close
                </li>
                <li
                  id="menu-item-5706"
                  class="ubermenu-item ubermenu-item-type-post_type ubermenu-item-object-page ubermenu-item-5706 ubermenu-item-auto ubermenu-item-header ubermenu-item-level-1 ubermenu-column ubermenu-column-auto"
                >
                  <a
                    class="ubermenu-target ubermenu-item-layout-default ubermenu-item-layout-text_only"
                    href="/strategic-planning"
                  >
                    <span class="ubermenu-target-title ubermenu-target-text">
                      Strategic Planning
                    </span>
                  </a>
                </li>
                <li
                  id="menu-item-5707"
                  class="ubermenu-item ubermenu-item-type-post_type ubermenu-item-object-page ubermenu-item-5707 ubermenu-item-auto ubermenu-item-header ubermenu-item-level-1 ubermenu-column ubermenu-column-auto"
                >
                  <a
                    class="ubermenu-target ubermenu-item-layout-default ubermenu-item-layout-text_only"
                    href="/financial-budgeting-forecasting"
                  >
                    <span class="ubermenu-target-title ubermenu-target-text">
                      Financial Budgeting and Forecasting
                    </span>
                  </a>
                </li>
                <li
                  id="menu-item-5708"
                  class="ubermenu-item ubermenu-item-type-post_type ubermenu-item-object-page ubermenu-item-5708 ubermenu-item-auto ubermenu-item-header ubermenu-item-level-1 ubermenu-column ubermenu-column-auto"
                >
                  <a
                    class="ubermenu-target ubermenu-item-layout-default ubermenu-item-layout-text_only"
                    href="/fixed-assets-management"
                  >
                    <span class="ubermenu-target-title ubermenu-target-text">
                      Fixed Assets Management
                    </span>
                  </a>
                </li>
                <li
                  id="menu-item-5709"
                  class="ubermenu-item ubermenu-item-type-post_type ubermenu-item-object-page ubermenu-item-5709 ubermenu-item-auto ubermenu-item-header ubermenu-item-level-1 ubermenu-column ubermenu-column-auto"
                >
                  <a
                    class="ubermenu-target ubermenu-item-layout-default ubermenu-item-layout-text_only"
                    href="/technology-consulting-services"
                  >
                    <span class="ubermenu-target-title ubermenu-target-text">
                      Technology Consulting
                    </span>
                  </a>
                </li>
                <li
                  id="menu-item-5710"
                  class="ubermenu-item ubermenu-item-type-post_type ubermenu-item-object-page ubermenu-item-5710 ubermenu-item-auto ubermenu-item-header ubermenu-item-level-1 ubermenu-column ubermenu-column-auto"
                >
                  <a
                    class="ubermenu-target ubermenu-item-layout-default ubermenu-item-layout-text_only"
                    href="/cfo-services"
                  >
                    <span class="ubermenu-target-title ubermenu-target-text">
                      Virtual CFO
                    </span>
                  </a>
                </li>

                <li class="ubermenu-retractor ubermenu-retractor-mobile">
                  <i class="fa fa-times"></i> Close
                </li>
              </ul>
            </li>
            <li
              id="menu-item-8612"
              class="ubermenu-item ubermenu-item-type-custom ubermenu-item-object-custom ubermenu-item-has-children ubermenu-item-8612 ubermenu-item-level-0 ubermenu-column ubermenu-column-auto ubermenu-has-submenu-drop ubermenu-has-submenu-mega"
            >
              <a
                class="ubermenu-target ubermenu-item-layout-default ubermenu-item-layout-text_only"
                tabindex="0"
                href="/company-incorporation-services"
              >
                <span class="ubermenu-target-title ubermenu-target-text">
                  Company Incorporation Services
                </span>
              </a>
            </li>
            <li
              id="menu-item-8616"
              class="ubermenu-item ubermenu-item-type-custom ubermenu-item-object-custom ubermenu-item-has-children ubermenu-item-8616 ubermenu-item-level-0 ubermenu-column ubermenu-column-auto ubermenu-has-submenu-drop ubermenu-has-submenu-mega"
            >
              <a
                class="ubermenu-target ubermenu-item-layout-default ubermenu-item-layout-text_only"
                tabindex="0"
                href="/merger-acquisitions"
              >
                <span class="ubermenu-target-title ubermenu-target-text">
                  Mergers & Acquisitions
                </span>
                <RiArrowDropDownLine />
              </a>
              <ul class="submenu">
                <li class="ubermenu-retractor ubermenu-retractor-mobile">
                  <i class="fa fa-times"></i> Close
                </li>
                <li
                  id="menu-item-5655"
                  class="ubermenu-item ubermenu-item-type-post_type ubermenu-item-object-page ubermenu-item-5655 ubermenu-item-auto ubermenu-item-header ubermenu-item-level-1 ubermenu-column ubermenu-column-auto"
                >
                  <a
                    class="ubermenu-target ubermenu-item-layout-default ubermenu-item-layout-text_only"
                    href="/due-diligence"
                  >
                    <span class="ubermenu-target-title ubermenu-target-text">
                      Due Diligence
                    </span>
                  </a>
                </li>
                <li
                  id="menu-item-5652"
                  class="ubermenu-item ubermenu-item-type-post_type ubermenu-item-object-page ubermenu-item-5652 ubermenu-item-auto ubermenu-item-header ubermenu-item-level-1 ubermenu-column ubermenu-column-auto"
                >
                  <a
                    class="ubermenu-target ubermenu-item-layout-default ubermenu-item-layout-text_only"
                    href="/value-advisory"
                  >
                    <span class="ubermenu-target-title ubermenu-target-text">
                      Valuation Advisory Services
                    </span>
                  </a>
                </li>
                <li
                  id="menu-item-5653"
                  class="ubermenu-item ubermenu-item-type-post_type ubermenu-item-object-page ubermenu-item-5653 ubermenu-item-auto ubermenu-item-header ubermenu-item-level-1 ubermenu-column ubermenu-column-auto"
                >
                  <a
                    class="ubermenu-target ubermenu-item-layout-default ubermenu-item-layout-text_only"
                    href="/restructuring"
                  >
                    <span class="ubermenu-target-title ubermenu-target-text">
                      Restructuring and Reorganization services
                    </span>
                  </a>
                </li>

                <li class="ubermenu-retractor ubermenu-retractor-mobile">
                  <i class="fa fa-times"></i> Close
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </header>

      {/* // sub Header */}
      {/* Mobile Header */}
      <nav class="navbar navbar-expand-lg navbar-light bg-light ismobilenav">
        <div
          id="logo"
          class="logo"
          style={{ marginTop: "15px", marginBottom: "0px" }}
          data-width="350"
          data-height="50"
        >
          <a href="/" title="Prish">
            <img
              src={logosvg} // Will change later
            />
          </a>
        </div>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarTogglerDemo02"
          aria-controls="navbarTogglerDemo02"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
          <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
            <li class="nav-item active">
              <a class="nav-link" href="/">
                Home <span class="sr-only">(current)</span>
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/about">
                About Us
              </a>
            </li>

            <li class="nav-item">
              <a class="nav-link " href="/blog">
                Blog
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link " href="/reach">
                Reach Us
              </a>
            </li>
          </ul>
        </div>
      </nav>

      {/* Mobile Nav 2 */}
      <nav
        class="navbar navbar-expand-lg navbar-dark ismobilenav2"
        style={{ backgroundColor: "#29307b" }}
      >
        <div class="container-fluid-navbar">
          <button
            class="navbar-toggler second-nav-button"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
              <li class="nav-item-2 dropdown">
                <a
                  class="nav-link dropdown-toggle item-name"
                  href="/auditing-assurance-services"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  AUDIT &amp; ASSURANCE
                </a>
                <ul class="dropdown-menu " style={{ width: "100%" }}>
                  <li>
                    <a class="dropdown-item-1" href="/satutory-audit">
                      Statutory Audit
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item-1" href="/concurrent-audit">
                      Concurrent Audit
                    </a>
                  </li>{" "}
                  <li>
                    <a class="dropdown-item-1" href="/forensic-audit">
                      Forensic Audit
                    </a>
                  </li>{" "}
                  <li>
                    <a class="dropdown-item-1" href="/inventory-audit">
                      Inventory Audit and Verification
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item-1" href="/mystery-audit">
                      Mystery Audit
                    </a>
                  </li>
                </ul>
              </li>
              <li class="nav-item-2 dropdown">
                <a
                  class="nav-link dropdown-toggle item-name"
                  href="/special-audit"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  SPECIAL AUDITS
                </a>
                <ul class="dropdown-menu " style={{ width: "100%" }}>
                  <li>
                    <a class="dropdown-item-1" href="/gold-audit">
                      Gold Audit
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item-1" href="/system-tax-audit">
                      System Audit
                    </a>
                  </li>{" "}
                  <li>
                    <a class="dropdown-item-1" href="/transaction-audit">
                      Transaction Audit
                    </a>
                  </li>{" "}
                  <li>
                    <a class="dropdown-item-1" href="/rera-audit">
                      Rera Audit
                    </a>
                  </li>{" "}
                  <li>
                    <a class="dropdown-item-1" href="/sales-tax-audit">
                      Sales Tax Certification
                    </a>
                  </li>{" "}
                </ul>
              </li>
              <li class="nav-item-2 dropdown">
                <a
                  class="nav-link dropdown-toggle item-name"
                  href="/risk-advisory-services"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  RISK ASSURANCE
                </a>
                <ul class="dropdown-menu " style={{ width: "100%" }}>
                  <li>
                    <a class="dropdown-item-1" href="/internal-audit">
                      Internal Audit
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item-1" href="/standard-operating">
                      Standard Operating Procedures (SOPs)
                    </a>
                  </li>{" "}
                  <li>
                    <a class="dropdown-item-1" href="/risk-control">
                      Risk Control Matrix (RCM)
                    </a>
                  </li>{" "}
                </ul>
              </li>
              <li class="nav-item-2 dropdown">
                <a
                  class="nav-link dropdown-toggle item-name"
                  href="/tax-services"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  TAXATION
                </a>
                <ul class="dropdown-menu " style={{ width: "100%" }}>
                  <li>
                    <a class="dropdown-item-1" href="/value-added-tax">
                      Value Added Tax (VAT)
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item-1" href="/uae-corporate-tax">
                      UAE Corporate Tax
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item-1" href="/transfer-pricing">
                      Transfer Pricing
                    </a>
                  </li>{" "}
                  <li>
                    <a
                      class="dropdown-item-1"
                      href="/tax-residency-certificate"
                    >
                      Tax Residency Certificate (TRC)
                    </a>
                  </li>{" "}
                </ul>
              </li>
              <li class="nav-item-2">
                <a
                  class="nav-link item-name"
                  href="/accounting-outsourcing-services"
                >
                  ACCOUNTING &amp; BOOKKEEPING
                </a>
              </li>
              <li class="nav-item-2">
                <a class="nav-link item-name" href="/anti-money-laundering">
                  Anti Money Laundering (AML) Compliances
                </a>
              </li>{" "}
              <li class="nav-item-2 dropdown">
                <a
                  class="nav-link dropdown-toggle item-name"
                  href="/compliance-services"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Corporate Consultancy
                </a>
                <ul class="dropdown-menu " style={{ width: "100%" }}>
                  <li>
                    <a class="dropdown-item-1" href="/strategic-planning">
                      Strategic Planning
                    </a>
                  </li>
                  <li>
                    <a
                      class="dropdown-item-1"
                      href="/financial-budgeting-forecasting"
                    >
                      Financial Budgeting and Forecasting
                    </a>
                  </li>{" "}
                  <li>
                    <a class="dropdown-item-1" href="/fixed-assets-management">
                      Fixed Assets Management
                    </a>
                  </li>{" "}
                  <li>
                    <a class="dropdown-item-1" href="/technology-consulting">
                      Technology Consulting
                    </a>
                  </li>{" "}
                  <li>
                    <a class="dropdown-item-1" href="/cfo-services">
                      Virtual CFO
                    </a>
                  </li>{" "}
                </ul>
              </li>
              <li class="nav-item-2">
                <a
                  class="nav-link item-name"
                  href="/company-incorporation-services"
                >
                  Company Incorporation Services
                </a>
              </li>{" "}
              <li class="nav-item-2 dropdown">
                <a
                  class="nav-link dropdown-toggle item-name"
                  href="/merger-acquisitions"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Mergers & Acquisitions
                </a>
                <ul class="dropdown-menu " style={{ width: "100%" }}>
                  <li>
                    <a class="dropdown-item-1" href="/due-diligence">
                      Due Diligence
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item-1" href="/value-advisory">
                      Valuation Advisory Services
                    </a>
                  </li>{" "}
                  <li>
                    <a class="dropdown-item-1" href="/restructuring">
                      Restructuring and Reorganization services
                    </a>
                  </li>{" "}
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Header;
