import React from "react";
import ".//audit.css";
import { GoDotFill } from "react-icons/go";
import CorporationCards from "../CorporationPages/CorporationCards";
import { CorporateData } from "./CorporateData";

const CorporateAdvisory = () => {
  return (
    <>
      <section className="audit-sec-1">
        <div className="info">
          <h1 className="typo3">Corporate Advisory</h1>
        </div>

        <div className="bookeeping-container-2">
          <div className="corporate-advisory-info">
            <h2>Corporate Advisory</h2>
            <p className="text">
              Our Corporate Finance practice comprises a dedicated team of
              accomplished professionals with strong technical knowledge
              combined with relevant industry expertise. Assist you with
              impairment testing services to help you compare the carrying
              amount with the fair value of the assets in order to comply with
              International Accounting requirements.
            </p>

            <br />
          </div>
          <div className="corporate-advisory-info">
            <h2>Transaction Advisory Services</h2>
            <p className="text">
              Transaction Advisory involves working with high-stake business
              transactions and requires a certain level of experience and
              rationality. Businesses face opportunities and risks daily, and
              the ability to act at the right time can be the deciding factor
              towards determining its success or failure.
            </p>
            <br />
            <p className="text">
              At JRB, our qualified team of advisors can help you navigate
              through your domestic and international transactions with ease and
              confidence. Whether you are an investor or a target company, you
              can be assured that we will perform the required due diligence to
              bring you a clear perspective of the transaction.
            </p>
            <br />
            <h2>Business Valuations</h2>
            <p className="text">Business Valuation services in Dubai, UAE.</p>
            <p className="text">
              Businesses today face multiple complexities and unknowns. To add
              to that, the business landscape itself is ever-evolving, with the
              adoption of agile practices and new-age technology. In such a
              scenario, the long-term success of any business entity is grossly
              dependent on its future profits and associated risks. For a
              business entity to be able to attain its potential and unlock its
              desired valuation denomination, it needs to incorporate
              specialized financial modeling and forecasting tools and
              techniques. At JRB, we offer business valuation services that are
              based on extracted historic information and forecasting models
              that mirror future outcomes. We help business owners to understand
              future financial projections based on analytical data helping them
              with a reasonable expectation of their business’s fair value.
            </p>
            <p className="text">
              Our expertise in business valuation offer assurances and advice to
              adopt appropriate methodologies that allow our clients reach
              accurate, reliable estimates of their fair market value both
              presently and in the future.
            </p>
            <h2>We provide</h2>
          </div>
          <div className="corporate-advisory-more-info">
            <div className="corporate-advisory-info-box">
              <h3>
                <GoDotFill /> Business valuation services based on specialized
                analytics
              </h3>
            </div>
            <div className="corporate-advisory-info-box">
              <h3>
                <GoDotFill /> In-depth analysis of financial modeling and
                forecasting
              </h3>
            </div>
            <div className="corporate-advisory-info-box">
              <h3>
                <GoDotFill /> Advisory services to allow clients to reach
                accurate and reliable fair value estimates
              </h3>
            </div>
            <div className="corporate-advisory-info-box">
              <h3>
                <GoDotFill />
                An experienced team of experts that offers adequate mirroring of
                future financial projections amid all variables
              </h3>
            </div>
          </div>
          <br />
          <br />
          <div className="corporation-container-2">
            <div className="corporation-cards">
              {CorporateData.map((Data) => (
                <CorporationCards
                  key={Data.id}
                  image={Data.image}
                  heading={Data.heading}
                  paragraph={Data.paragraph}
                />
              ))}
            </div>
          </div>

          <div className="corporate-advisory-info">
            <br />
            <h2>Financial Due Diligence</h2>
            <p className="text">
              During a merger or takeover, a comprehensive analysis of the
              financial outlook of a business is of utmost importance, but the
              financial statements and records of a company may not always
              depict the complete picture. At JRB, we assist our clients by
              helping them review their target company’s offering through the
              detailed due diligence of its financial information, to identify
              any potential red flags that could affect the deal.
            </p>
            <br />
            <p className="text">
              Our experts in core finance deep-dive into and consider the
              current financials as well as its future projections. In addition
              to the strengths of the target company, we also aim to identify
              any risks and place the acquirer in a stronger position during
              sales negotiations. With our Financial Due Diligence services, we
              help our clients understand the futuristic financial position of
              the target.
            </p>
            <br />
            <h3>Our experts at JRB can help you by</h3>
          </div>
          <div className="corporate-advisory-more-info">
            <div className="corporate-advisory-info-box">
              <h3>
                <GoDotFill /> Providing a detailed assessment of the financial
                statement of accounts
              </h3>
            </div>
            <div className="corporate-advisory-info-box">
              <h3>
                <GoDotFill /> Identifying future potential earning capabilities
              </h3>
            </div>
            <div className="corporate-advisory-info-box">
              <h3>
                <GoDotFill /> Recognizing any undisclosed risks
              </h3>
            </div>
            <div className="corporate-advisory-info-box">
              <h3>
                <GoDotFill /> Gathering independent opinions from industry
                experts who understand the intricacies involved in financial due
                diligence
              </h3>
            </div>
            <div className="corporate-advisory-info-box">
              <h3>
                <GoDotFill /> Giving you the liberty to plan the transaction
                while we handle the financial due diligence
              </h3>
            </div>
          </div>
          {/* aaaaaaaaaa */}

          <div className="corporate-advisory-info">
            <br />
            <h2>Business Plans and Investor Pitch Deck</h2>
            <p className="text">
              Formulating a thorough business plan and pitch deck is a core
              component to ensure that you obtain the buy-in of your
              stakeholder.
            </p>
            <br />
            <h3>Here are some areas JRB can help you with</h3>
            <br />
          </div>
          <div className="corporate-advisory-more-info">
            <div className="corporate-advisory-info-box">
              <h3>
                <GoDotFill /> Identifying the problem or opportunity
              </h3>
            </div>
            <div className="corporate-advisory-info-box">
              <h3>
                <GoDotFill /> Persuading stakeholders why you have/are the ideal
                option
              </h3>
            </div>
            <div className="corporate-advisory-info-box">
              <h3>
                <GoDotFill /> Describing your Business Model
              </h3>
            </div>
            <div className="corporate-advisory-info-box">
              <h3>
                <GoDotFill /> Discussing your funding requirements
              </h3>
            </div>
            <div className="corporate-advisory-info-box">
              <h3>
                <GoDotFill /> Showcasing your exit strategy
              </h3>
            </div>
          </div>
          {/* merger */}
          <div className="corporate-advisory-info">
            <br />
            <h2>Mergers & Acquisitions</h2>
            <p className="text">
              A Mergers, Acquisitions, and Divestitures are important events
              that can take your enterprise to the next level of organic growth.
              It starts with identifying your requirements for a creative M&A
              strategy and ends with the successful completion of the
              transaction. At each step during the process, decisions that could
              impact the future of the transaction are taken. Looking at all the
              options, identifying the risks, and anticipating what each
              decision holds in the future of the enterprise are key factors
              which a business owner should bear in mind during the M&A
              transaction. JRB provides expert advice and consulting to closely
              held business owners with goals of buying or selling a business.
              Our consultants can assist Business Owners in laying out your
              options, identifying the risks & rewards and advising you on the
              anticipated results in future for every decision you take during a
              M&A transaction. We can provide both Sell-side and Buy-side
              advisory services.
            </p>
            <br />
            <h3>Sell-side M&A Advisory:</h3>
            <span>
              We are well-positioned to represent sellers and manage
              confidential auction processes from start to finish. Our sell-side
              advisory services include:
            </span>
          </div>
          <br />
          <div className="corporate-advisory-more-info">
            <div className="corporate-advisory-info-box">
              <h3>
                <GoDotFill /> Valuation analysis of the business
              </h3>
            </div>
            <div className="corporate-advisory-info-box">
              <h3>
                <GoDotFill /> Development of an Investment Teaser
              </h3>
            </div>
            <div className="corporate-advisory-info-box">
              <h3>
                <GoDotFill /> Preparation of a Confidential Information
                Memorandum
              </h3>
            </div>
            <div className="corporate-advisory-info-box">
              <h3>
                <GoDotFill /> Identification of buyer prospects
              </h3>
            </div>
            <div className="corporate-advisory-info-box">
              <h3>
                <GoDotFill /> Assistance in structuring the transaction
              </h3>
            </div>
            <div className="corporate-advisory-info-box">
              <h3>
                <GoDotFill /> Negotiations of the initial letter of intent and
                the final purchase agreement
              </h3>
            </div>
          </div>
          <br />
          <div className="corporate-advisory-info">
            <h3>Buy-side M&A Advisory:</h3>
            <span>
              We also provide one-off and retainer-based buy-side advisory
              services to businesses that seek to grow by way of strategic
              acquisitions. Our buy-side services include:
            </span>
          </div>
          <br />
          <div className="corporate-advisory-more-info">
            <div className="corporate-advisory-info-box">
              <h3>
                <GoDotFill /> Research and identification of acquisition targets
              </h3>
            </div>
            <div className="corporate-advisory-info-box">
              <h3>
                <GoDotFill /> Assistance in valuing acquisition candidates
              </h3>
            </div>
            <div className="corporate-advisory-info-box">
              <h3>
                <GoDotFill /> Assistance in structuring the transaction
              </h3>
            </div>
            <div className="corporate-advisory-info-box">
              <h3>
                <GoDotFill /> Negotiation of the initial offer and final
                purchase agreement
              </h3>
            </div>
            <div className="corporate-advisory-info-box">
              <h3>
                <GoDotFill /> Advice on issues throughout the transaction until
                closing
              </h3>
            </div>
          </div>
          {/* feasiility */}

          <div className="corporate-advisory-info">
            <br />
            <h2>Feasibility Studies & Business plan</h2>
            <p className="text">
              It lays down a blueprint for the success and growth of the
              business and help the management to take informed investment
              decisions. The first step to a successful company is the
              comprehensive feasibility study and a business plan. Our business
              plans and feasibility study services in Dubai, UAE have following
              key elements:
            </p>
            <br />
            <h3>The core elements of a professional feasibility study:</h3>
          </div>
          <br />
          <div className="corporate-advisory-more-info">
            <div className="corporate-advisory-info-box">
              <h3>
                <GoDotFill /> Technical – Equipment, capacity, utilization,
                products/ services specifications, etc.
              </h3>
            </div>
            <div className="corporate-advisory-info-box">
              <h3>
                <GoDotFill /> Financial – Project cost, source of funds, return
                on investment (ROI), payback, etc.
              </h3>
            </div>
            <div className="corporate-advisory-info-box">
              <h3>
                <GoDotFill /> Legal – Legal structure, partnerships, taxation,
                etc.
              </h3>
            </div>
            <div className="corporate-advisory-info-box">
              <h3>
                <GoDotFill /> Operational – Product/ service mix, sourcing,
                resource requirements, etc.
              </h3>
            </div>
            <div className="corporate-advisory-info-box">
              <h3>
                <GoDotFill /> Market – Market size, compound annual growth rate
                (CAGR), competition, market share, etc.
              </h3>
            </div>
            <div className="corporate-advisory-info-box">
              <h3>
                <GoDotFill />
                Planning – Project timelines, key stages, etc.
              </h3>
            </div>
          </div>
          <br />
          <div className="corporate-advisory-info">
            <h3>Why do you need a feasibility study?</h3>
          </div>
          <br />
          <div className="corporate-advisory-more-info">
            <div className="corporate-advisory-info-box">
              <h3>
                <GoDotFill /> It provides objective information for
                decision-making.
              </h3>
            </div>
            <div className="corporate-advisory-info-box">
              <h3>
                <GoDotFill /> It gives focus to the project and outlines other
                plausible alternatives.
              </h3>
            </div>
            <div className="corporate-advisory-info-box">
              <h3>
                <GoDotFill /> It boosts the probability of success by addressing
                and mitigating risks that could affect the project early on.
              </h3>
            </div>
            <div className="corporate-advisory-info-box">
              <h3>
                <GoDotFill /> It can help you to attract equity participation
                opportunities.
              </h3>
            </div>
            <div className="corporate-advisory-info-box">
              <h3>
                <GoDotFill /> It provides documented evidence that the venture
                or project was thoroughly investigated.
              </h3>
            </div>
            <div className="corporate-advisory-info-box">
              <h3>
                <GoDotFill /> It could identify new business opportunities due
                to the exploratory nature of the study.
              </h3>
            </div>
            <div className="corporate-advisory-info-box">
              <h3>
                <GoDotFill /> It can highlight probable reasons not to proceed
                if that is the case.
              </h3>
            </div>
            <div className="corporate-advisory-info-box">
              <h3>
                <GoDotFill /> It can also help in securing funding from banks,
                investors, lending institutions, and other monetary sources.
              </h3>
            </div>
          </div>
          <br />

          {/* Purchase price */}
          <div className="corporate-advisory-info">
            <h2>Purchase Price Allocation</h2>
            <p className="text">
              In acquisition accounting, purchase price allocation is a practice
              in which an acquirer allocates the purchase price into the assets
              and liabilities of the target company acquired in the transaction.
              Purchase price allocation is an important step in accounting
              reporting after the completion of a merger or acquisition.
            </p>
            <p className="text">
              Our team at JRB, assisting you in allocating the consideration
              paid for an acquisition into tangible and intangible assets as of
              the acquisition date in order to comply with IFRS.
            </p>
            <br />
          </div>
          {/* Impairment */}
          <div className="corporate-advisory-info">
            <h2>Impairment Testing</h2>
            <p className="text">
              Assist you with impairment testing services to help you compare
              the carrying amount with the fair value of the assets in order to
              comply with International Accounting requirements.
            </p>
            <br />
          </div>
        </div>
      </section>
    </>
  );
};

export default CorporateAdvisory;
