import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import inv3 from "./images/inv3.jpeg";
import inv1 from "./images/inv1.jpeg";
import inv2 from "./images/inv2.jpeg";
import bgImage from "../Home/Images/homepage2.jpeg";
import bgImage1 from "../Home/Images/homepage3.jpg";
import bgImage2 from "../Home/Images/homepage4.jpg";
import bgImage3 from "../Home/Images/homepage5.jpg";
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md";
import Query from "../Components/Query/Query";

const InventoryAudit = () => {
  const [image, setImage] = useState("");
  const [count, setCount] = useState(0);
  const scollToRef = useRef();
  useEffect(() => {
    scollToRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);
  const Imagesdata = [
    {
      images: bgImage,
      id: 1,
    },
    {
      images: bgImage1,
      id: 2,
    },
    {
      images: bgImage2,
      id: 3,
    },
    {
      images: bgImage3,
      id: 4,
    },
  ];
  useEffect(() => {
    setImage(Imagesdata[0].images);
  }, []);
  // Function is called everytime increment button is clicked
  const handleClick1 = () => {
    // Counter state is incremented
    if (count < 3) {
      setImage(Imagesdata[count + 1]?.images || bgImage);
      setCount(count + 1);
    } else {
      setCount(0);
      setImage(Imagesdata[0]?.images);
    }
  };

  // Function is called everytime decrement button is clicked
  const handleClick2 = () => {
    // Counter state is decremented
    if (count > 0) {
      setImage(Imagesdata[count - 1].images);
      setCount(count - 1);
    } else {
      setCount(3);
      setImage(Imagesdata[3].images);
    }
  };
  return (
    <>
      <section className="corporate-sec">
        <div
          className={`${"info"}`}
          style={{ backgroundImage: `url(${image})` }}
        >
          <h1 className="typo5">Inventory Audit & Verification</h1>
          <div className="rightIcon">
            <div className="rightIconImageBox" onClick={handleClick1}>
              <MdArrowForwardIos color="#ffffff" />
            </div>
          </div>
          <div className="leftIcon" onClick={handleClick2}>
            <div className="leftIconImageBox">
              <MdArrowBackIos color="#ffffff" />
            </div>
          </div>
        </div>
        <div className="corporate-sec-1  ">
          <div className="corporate-img">
            <div class="hexagon">
              <img src={inv3} width="200" height="200" />
            </div>
            <div className="nextConatainer">
              <div class="hexagon1">
                <img src={inv1} width="200" height="200" />
              </div>
            </div>
            <div class="hexagon2">
              <img src={inv2} width="200" height="200" />
            </div>

            {/* <img src="./CorporateImage/img1.jpeg" alt="img" width={500} /> */}
          </div>
          <div className="info-paragraphs">
            <h3 ref={scollToRef} className="audit-header">
              Inventory Audit & Verification
            </h3>
            <p className="text">
              Confirming the existence, completeness, and accuracy of inventory
              records and assessing the effectiveness of internal controls over
              the inventory management process and suggesting the process
              improvement measure. It ensures that the quantity, condition, and
              value of inventory items are accurately recorded and reported in
              the financial statements.
            </p>
            <p>
              Our experienced auditors utilize advanced techniques and
              methodologies to conduct thorough audits that provide valuable
              insights and recommendations for improving inventory management
              practices.
            </p>
            <p>
              Our approach is understanding the importance of maintaining
              accurate inventory records to support informed decision-making and
              financial reporting. We work closely with your team to develop a
              customized audit plan that addresses key areas of concern and
              identifies opportunities for improvement.
            </p>
            <br />
            {/* <h4>
              Detailed accounting and management processes covered by us are as
              follows:
            </h4> */}
            <h3 className="submenu-header ">Why Choose PRISH ?</h3>
            <p className="submenu-sub-header ">
              Physical Inventory Counts:{" "}
              <span className="text">
                We conduct physical inventory counts to verify the existence,
                condition, and location of inventory items.
              </span>
            </p>

            <p className="submenu-sub-header ">
              Inventory Valuation:{" "}
              <span className="text">
                Our auditors review inventory valuation methods to ensure
                compliance with accounting standards and assess the accuracy of
                inventory values.
              </span>{" "}
            </p>
            <p className="submenu-sub-header ">
              Inventory Control Procedures:{" "}
              <span className="text">
                We evaluate inventory control procedures and internal controls
                to identify weaknesses and recommend enhancements to mitigate
                risks of errors, theft, or fraud.
              </span>
            </p>
            <p className="submenu-sub-header ">
              Documentation Review:{" "}
              <span className="text">
                We review inventory documentation, including purchase orders,
                receiving reports, and sales records, to ensure completeness and
                accuracy.
              </span>
            </p>
          </div>
        </div>
        <div className="accordian-section">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  What is an inventory audit and verification?
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  An inventory audit and verification examine and assesses a
                  company's physical inventory and related accounting records to
                  ensure accuracy, completeness, and reliability.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  Why is inventory audit and verification necessary?
                </button>
              </h2>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Inventory audit and verification are necessary to ensure that
                  the quantity, condition, and value of inventory items are
                  accurately recorded and reported in the financial statements.
                  It helps prevent errors, fraud, and discrepancies in inventory
                  management.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  What are the key objectives of inventory audit and
                  verification?
                </button>
              </h2>
              <div
                id="collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  The main objectives of inventory audit and verification
                  include verifying the existence and condition of inventory
                  items, ensuring accurate valuation of inventory, identifying
                  discrepancies or errors, and improving inventory management
                  practices.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsenine"
                  aria-expanded="false"
                  aria-controls="collapsenine"
                >
                  How often should inventory audit and verification be
                  conducted?
                </button>
              </h2>
              <div
                id="collapsenine"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  The frequency of inventory audits and verification depends on
                  factors such as the industry, the business size, and the risk
                  of inventory-related issues. Typically, it is conducted
                  annually or quarterly.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTen"
                  aria-expanded="false"
                  aria-controls="collapseTen"
                >
                  What industries can benefit from inventory audit and
                  verification services?
                </button>
              </h2>
              <div
                id="collapseTen"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Inventory audit and verification services are beneficial to
                  businesses across various industries including retail,
                  manufacturing, distribution, wholesale, and logistics.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTen-1"
                  aria-expanded="false"
                  aria-controls="collapseTen-1"
                >
                  How does inventory audit and verification differ from regular
                  audits?
                </button>
              </h2>
              <div
                id="collapseTen-1"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  While regular audits focus on verifying financial statements
                  for accuracy and compliance, inventory audits and verification
                  specifically focus on the accuracy and reliability of
                  inventory records, physical counts, and valuation methods.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTen-2"
                  aria-expanded="false"
                  aria-controls="collapseTen-2"
                >
                  What are some common methods used in inventory audit and
                  verification?
                </button>
              </h2>
              <div
                id="collapseTen-2"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Common methods used in inventory audit and verification
                  include physical inventory counts, examination of supporting
                  documentation such as purchase orders and receiving reports,
                  review of inventory control procedures, and testing of
                  inventory valuation methods.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTen-3"
                  aria-expanded="false"
                  aria-controls="collapseTen-3"
                >
                  What are the benefits of inventory audit and verification?
                </button>
              </h2>
              <div
                id="collapseTen-3"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <p className="submenu-sub-header ">
                    Accurate Financial Reporting:
                    <span className="text">
                      Ensures accurate reporting of inventory values in
                      financial statements.
                    </span>{" "}
                  </p>

                  <p className="submenu-sub-header ">
                    Risk Mitigation:
                    <span className="text">
                      Helps identify and mitigate risks of inventory shrinkage,
                      obsolescence, and misstatement.{" "}
                    </span>{" "}
                  </p>

                  <p className="submenu-sub-header ">
                    Compliance Assurance:
                    <span className="text">
                      Ensures compliance with regulatory requirements and
                      accounting standards.{" "}
                    </span>{" "}
                  </p>

                  <p className="submenu-sub-header ">
                    Operational Efficiency:
                    <span className="text">
                      Identifies opportunities for process improvement and
                      efficiency gains in inventory management.{" "}
                    </span>{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Query />
    </>
  );
};

export default InventoryAudit;
