import React, { useEffect, useRef, useState } from "react";
import ".//audit.css";
import corporate from "./images/corporate.jpeg";
import corporate1 from "./images/corporate1.jpeg";
import bgImage from "../Home/Images/homepage2.jpeg";
import bgImage1 from "../Home/Images/homepage3.jpg";
import bgImage2 from "../Home/Images/homepage4.jpg";
import bgImage3 from "../Home/Images/homepage5.jpg";
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Query from "../Components/Query/Query";
const Audit = () => {
  const [image, setImage] = useState("");
  const [count, setCount] = useState(0);
  const scollToRef = useRef();
  useEffect(() => {
    scollToRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);
  const navigate = useNavigate();

  const Imagesdata = [
    {
      images: bgImage,
      id: 1,
    },
    {
      images: bgImage1,
      id: 2,
    },
    {
      images: bgImage2,
      id: 3,
    },
    {
      images: bgImage3,
      id: 4,
    },
  ];
  useEffect(() => {
    setImage(Imagesdata[0].images);
  }, []);
  // Function is called everytime increment button is clicked
  const handleClick1 = () => {
    // Counter state is incremented
    if (count < 3) {
      setImage(Imagesdata[count + 1]?.images || bgImage);
      setCount(count + 1);
    } else {
      setCount(0);
      setImage(Imagesdata[0]?.images);
    }
  };

  // Function is called everytime decrement button is clicked
  const handleClick2 = () => {
    // Counter state is decremented
    if (count > 0) {
      setImage(Imagesdata[count - 1].images);
      setCount(count - 1);
    } else {
      setCount(3);
      setImage(Imagesdata[3].images);
    }
  };
  return (
    <>
      <section className="corporate-sec">
        <div
          className={`${"info"}`}
          style={{ backgroundImage: `url(${image})` }}
        >
          <h1 className="typo5">Corporate Consultancy</h1>
          <div className="rightIcon">
            <div className="rightIconImageBox" onClick={handleClick1}>
              <MdArrowForwardIos color="#ffffff" />
            </div>
          </div>
          <div className="leftIcon" onClick={handleClick2}>
            <div className="leftIconImageBox">
              <MdArrowBackIos color="#ffffff" />
            </div>
          </div>
        </div>
        <div className="corporate-sec-1  ">
          <div className="corporate-img">
            <div class="hexagon">
              <img src="./CorporateImage/img1.jpeg" width="200" height="200" />
            </div>
            <div className="nextConatainer">
              <div class="hexagon1">
                <img src={corporate} width="200" height="200" />
              </div>
            </div>
            <div class="hexagon2">
              <img src={corporate1} width="200" height="200" />
            </div>

            {/* <img src="./CorporateImage/img1.jpeg" alt="img" width={500} /> */}
          </div>
          <div className="info-paragraphs">
            <h3 ref={scollToRef} className="audit-header">
              Corporate Consultancy
            </h3>
            <p className="text">
              Corporate consultancy provided by us at PRISH involves providing
              professional advice and guidance to businesses across various
              aspects of their operations, strategies, and management to
              outperform with immense effectiveness and efficiency in their
              operations.
            </p>
            <p className="text">
              Corporate consultancy services are tailored to the specific needs
              and objectives of each client, helping businesses address
              challenges, seize opportunities, and achieve sustainable growth
              and success.
            </p>
            <br />
            <h4
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/strategic-planning")}
            >
              Strategic Planning
            </h4>
            <p className="text">
              Developing strategic plans for businesses to achieve long-term
              growth and competitiveness, identifying opportunities for
              expansion, diversification, or market entry, implementing
              organizational changes to improve efficiency and effectiveness
              <button
                onClick={() => navigate("/strategic-planning")}
                className="read-more-btn-1"
              >
                Read more
              </button>
            </p>
            <br />
            <h4
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/financial-budgeting-forecasting")}
            >
              Financial Budgeting and Forecasting
            </h4>
            <p className="text">
              Developing a comprehensive plan for the allocation and budgeting
              of financial resources over a specific period. The budget serves
              as a roadmap for guiding financial decision-making and performance
              evaluation. Forecasting by predicting future financial outcomes,
              performance, and cash flows based on historical data, market
              trends, and other relevant factors.
              <button
                onClick={() => navigate("/financial-budgeting-forecasting")}
                className="read-more-btn-1"
              >
                Read more
              </button>
            </p>

            {/*  */}
            <h4
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/fixed-assets-management")}
            >
              Fixed Assets Management
            </h4>
            <p className="text">
              Management and optimization of a company's fixed assets throughout
              their lifecycle, from acquisition to disposal. Fixed assets SOP
              review, physical verification, accounting entries and treatment
              for fixed assets are provided.
              <button
                onClick={() => navigate("/fixed-assets-management")}
                className="read-more-btn-1"
              >
                Read more
              </button>
            </p>

            <h4
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/technology-consulting-services")}
            >
              Technology Consulting
            </h4>
            <p className="text">
              Digital transformation strategy development, Technology assessment
              and implementation based on the business operations and
              requirements to ease the compliances. Our core experience in IT
              helps our clients to reduce extensive manual efforts and
              generating reports within a click of seconds.
              <button
                onClick={() => navigate("/technology-consulting-services")}
                className="read-more-btn-1"
              >
                Read more
              </button>
            </p>
            <h4
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/cfo-services")}
            >
              CFO Services
            </h4>
            <p className="text">
              Our seasoned finance professional are members of IIM and ICAI who
              helps you in running your finance function with his expert advice
              and hands on involvement. Their niche experience with MNCs can
              help your business to a better future through getting them as a
              virtual CFO to revolutionize the finance on your behalf.
              <button
                onClick={() => navigate("/cfo-services")}
                className="read-more-btn-1"
              >
                Read more
              </button>
            </p>
          </div>
        </div>
      </section>
      <Query />
    </>
  );
};

export default Audit;
