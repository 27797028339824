import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import for1 from "./images/for1.jpeg";
import for2 from "./images/for2.jpeg";
import for3 from "./images/for3.jpeg";
import bgImage from "../Home/Images/homepage2.jpeg";
import bgImage1 from "../Home/Images/homepage3.jpg";
import bgImage2 from "../Home/Images/homepage4.jpg";
import bgImage3 from "../Home/Images/homepage5.jpg";
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md";
import Query from "../Components/Query/Query";

const ForensicAudit = () => {
  const [image, setImage] = useState("");
  const [count, setCount] = useState(0);
  const scollToRef = useRef();
  useEffect(() => {
    scollToRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);
  const Imagesdata = [
    {
      images: bgImage,
      id: 1,
    },
    {
      images: bgImage1,
      id: 2,
    },
    {
      images: bgImage2,
      id: 3,
    },
    {
      images: bgImage3,
      id: 4,
    },
  ];
  useEffect(() => {
    setImage(Imagesdata[0].images);
  }, []);
  // Function is called everytime increment button is clicked
  const handleClick1 = () => {
    // Counter state is incremented
    if (count < 3) {
      setImage(Imagesdata[count + 1]?.images || bgImage);
      setCount(count + 1);
    } else {
      setCount(0);
      setImage(Imagesdata[0]?.images);
    }
  };

  // Function is called everytime decrement button is clicked
  const handleClick2 = () => {
    // Counter state is decremented
    if (count > 0) {
      setImage(Imagesdata[count - 1].images);
      setCount(count - 1);
    } else {
      setCount(3);
      setImage(Imagesdata[3].images);
    }
  };
  return (
    <>
      <section className="corporate-sec">
        <div
          className={`${"info"}`}
          style={{ backgroundImage: `url(${image})` }}
        >
          <h1 className="typo5">Forensic Audit</h1>
          <div className="rightIcon">
            <div className="rightIconImageBox" onClick={handleClick1}>
              <MdArrowForwardIos color="#ffffff" />
            </div>
          </div>
          <div className="leftIcon" onClick={handleClick2}>
            <div className="leftIconImageBox">
              <MdArrowBackIos color="#ffffff" />
            </div>
          </div>
        </div>
        <div className="corporate-sec-1  ">
          <div className="corporate-img">
            <div class="hexagon">
              <img src={for3} width="200" height="200" />
            </div>
            <div className="nextConatainer">
              <div class="hexagon1">
                <img src={for1} width="200" height="200" />
              </div>
            </div>
            <div class="hexagon2">
              <img src={for2} width="200" height="200" />
            </div>

            {/* <img src="./CorporateImage/img1.jpeg" alt="img" width={500} /> */}
          </div>
          <div className="info-paragraphs">
            <h3 ref={scollToRef} className="audit-header">
              Forensic Audit
            </h3>
            <p className="text">
              It will be a specialized examination of financial records,
              transactions, and documents conducted to identify uncovering
              fraud, financial misconduct, or irregularities through gathering
              evidence, analyzing data, and identifying perpetrators of
              fraudulent activities.It involves detailed analysis, data tracing,
              and evidence gathering to support legal proceedings or internal
              investigations.
            </p>
            <p>
              Our team of experienced forensic auditors employs advanced
              techniques and methodologies to investigate complex financial
              transactions and uncover hidden risks.
            </p>
            <p>
              Our approach to understanding the critical importance of thorough
              and accurate forensic audits. It combines technical expertise with
              investigative skills to uncover discrepancies, identify
              perpetrators, and provide actionable insights to mitigate future
              risks.
            </p>
            <br />
            {/* <h4>
              Detailed accounting and management processes covered by us are as
              follows:
            </h4> */}
            <h3 className="submenu-header ">Why Choose PRISH ?</h3>
            <p className="submenu-sub-header ">
              Fraud Detection:{" "}
              <span className="text">
                We utilize advanced data analysis techniques to identify red
                flags and anomalies that may indicate fraudulent activities
                within your organization.
              </span>
            </p>

            <p className="submenu-sub-header ">
              Evidence Collection:{" "}
              <span className="text">
                Our forensic auditors gather and analyze evidence meticulously,
                ensuring its admissibility and reliability for legal purposes.
              </span>{" "}
            </p>
            <p className="submenu-sub-header ">
              Interviews and Interrogations:{" "}
              <span className="text">
                We conduct interviews and interrogations to gather additional
                information and insights from relevant personnel, witnesses, and
                suspects.
              </span>
            </p>
            <p className="submenu-sub-header ">
              Expert Testimony:{" "}
              <span className="text">
                Our team provides expert testimony and support during legal
                proceedings, arbitration, or dispute resolution processes.
              </span>
            </p>
          </div>
        </div>
        <div className="accordian-section">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  What is a forensic audit?
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  A forensic audit is an examination and analysis of financial
                  records, transactions, and documentation to uncover fraud,
                  financial irregularities, or misconduct. It involves gathering
                  evidence that can be used in legal proceedings or internal
                  investigations.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  When is a forensic audit necessary?
                </button>
              </h2>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Forensic audits are necessary when there are suspicions or
                  allegations of fraud, embezzlement, corruption, or other
                  financial misconduct within an organization. They are often
                  conducted in response to specific incidents or as a proactive
                  measure to detect and prevent fraud.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  What are the key objectives of a forensic audit?
                </button>
              </h2>
              <div
                id="collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  The primary objectives of a forensic audit are to uncover
                  fraudulent activities, gather evidence for legal proceedings,
                  identify the perpetrators, quantify financial losses, and
                  recommend measures to prevent future occurrences.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsefour"
                  aria-expanded="false"
                  aria-controls="collapsefour"
                >
                  How does a forensic audit differ from a regular audit?
                </button>
              </h2>
              <div
                id="collapsefour"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  While regular audits focus on verifying financial statements
                  for accuracy and compliance, forensic audits are more
                  investigative in nature. Forensic audits delve deeper into
                  financial records, transactions, and documentation to uncover
                  fraudulent activities or misconduct.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsefive"
                  aria-expanded="false"
                  aria-controls="collapsefive"
                >
                  What are some common red flags that may indicate the need for
                  a forensic audit?
                </button>
              </h2>
              <div
                id="collapsefive"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Common red flags that may warrant a forensic audit include
                  unusual or unexplained fluctuations in financial performance,
                  discrepancies in accounting records, missing documentation,
                  suspicious transactions, and whistleblower complaints.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSix"
                  aria-expanded="false"
                  aria-controls="collapseSix"
                >
                  How long does a forensic audit typically take?
                </button>
              </h2>
              <div
                id="collapseSix"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  The duration of a forensic audit depends on the complexity of
                  the case, the volume of data to be analyzed, and the scope of
                  the investigation. It can range from a few weeks to several
                  months, or even longer for highly complex cases.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSeven"
                  aria-expanded="false"
                  aria-controls="collapseSeven"
                >
                  What industries can benefit from forensic audit services?
                </button>
              </h2>
              <div
                id="collapseSeven"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Forensic audit services are valuable to businesses and
                  organizations across various industries, including banking,
                  finance, insurance, healthcare, manufacturing, government
                  agencies, and non-profit organizations.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseEight"
                  aria-expanded="false"
                  aria-controls="collapseEight"
                >
                  What are the benefits of a forensic audit?
                </button>
              </h2>
              <div
                id="collapseEight"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <p className="submenu-sub-header ">
                    Fraud Detection and Prevention:
                    <span className="text">
                      {" "}
                      Forensic audits help detect and prevent fraud, protecting
                      the organization from financial losses and reputational
                      damage.
                    </span>
                  </p>
                  <p className="submenu-sub-header ">
                    Evidence Gathering:
                    <span className="text">
                      {" "}
                      They gather evidence that can be used in legal proceedings
                      or internal disciplinary actions against perpetrators.
                    </span>
                  </p>
                  <p className="submenu-sub-header ">
                    Compliance Assurance:
                    <span className="text">
                      {" "}
                      Forensic audits ensure compliance with legal and
                      regulatory requirements, reducing the risk of penalties
                      and sanctions.
                    </span>
                  </p>
                  <p className="submenu-sub-header ">
                    Stakeholder Confidence:
                    <span className="text">
                      {" "}
                      By demonstrating a commitment to integrity and
                      transparency, forensic audits enhance stakeholder
                      confidence in the organization.
                    </span>
                  </p>
                  <p className="submenu-sub-header ">
                    Operational Improvement:
                    <span className="text">
                      {" "}
                      They identify weaknesses in internal controls and
                      operational processes, leading to improvements in
                      efficiency and effectiveness.
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Query />
    </>
  );
};

export default ForensicAudit;
