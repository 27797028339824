import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import company from "./images/transfer_pricing1.jpeg";
import company1 from "./images/transfer_pricing2.jpeg";
import company2 from "./images/transfer_pricing3.jpeg";
import bgImage from "../Home/Images/homepage2.jpeg";
import bgImage1 from "../Home/Images/homepage3.jpg";
import bgImage2 from "../Home/Images/homepage4.jpg";
import bgImage3 from "../Home/Images/homepage5.jpg";
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md";
import Query from "../Components/Query/Query";

const TransferPricing = () => {
  const [image, setImage] = useState("");
  const [count, setCount] = useState(0);
  const scollToRef = useRef();
  useEffect(() => {
    scollToRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);
  const Imagesdata = [
    {
      images: bgImage,
      id: 1,
    },
    {
      images: bgImage1,
      id: 2,
    },
    {
      images: bgImage2,
      id: 3,
    },
    {
      images: bgImage3,
      id: 4,
    },
  ];
  useEffect(() => {
    setImage(Imagesdata[0].images);
  }, []);
  // Function is called everytime increment button is clicked
  const handleClick1 = () => {
    // Counter state is incremented
    if (count < 3) {
      setImage(Imagesdata[count + 1]?.images || bgImage);
      setCount(count + 1);
    } else {
      setCount(0);
      setImage(Imagesdata[0]?.images);
    }
  };

  // Function is called everytime decrement button is clicked
  const handleClick2 = () => {
    // Counter state is decremented
    if (count > 0) {
      setImage(Imagesdata[count - 1].images);
      setCount(count - 1);
    } else {
      setCount(3);
      setImage(Imagesdata[3].images);
    }
  };
  return (
    <>
      <section className="corporate-sec">
        <div
          className={`${"info"}`}
          style={{ backgroundImage: `url(${image})` }}
        >
          <h1 className="typo5">Transfer Pricing</h1>
          <div className="rightIcon">
            <div className="rightIconImageBox" onClick={handleClick1}>
              <MdArrowForwardIos color="#ffffff" />
            </div>
          </div>
          <div className="leftIcon" onClick={handleClick2}>
            <div className="leftIconImageBox">
              <MdArrowBackIos color="#ffffff" />
            </div>
          </div>
        </div>
        <div className="corporate-sec-1  ">
          <div className="corporate-img">
            <div class="hexagon">
              <img src={company} width="200" height="200" />
            </div>
            <div className="nextConatainer">
              <div class="hexagon1">
                <img src={company1} width="200" height="200" />
              </div>
            </div>
            <div class="hexagon2">
              <img src={company2} width="200" height="200" />
            </div>

            {/* <img src="./CorporateImage/img1.jpeg" alt="img" width={500} /> */}
          </div>
          <div className="info-paragraphs">
            <h3 ref={scollToRef} className="audit-header">
              Transfer Pricing
            </h3>
            <p className="text">
              Assisting corporates in complying with transfer pricing
              regulations in the UAE and other jurisdictions, including
              documentation requirements, transfer pricing adjustments, advising
              businesses on transfer pricing strategies, and transfer pricing
              planning. Weensure that transactions between related parties are
              conducted at arm's length as if they were between independent
              parties
            </p>
            <p className="text">
              Our experienced team provides tailored solutions to ensure that
              your company's transfer pricing practices are aligned with
              regulatory requirements and best practices.
            </p>
            <p className="text">
              Our approach is to understand the importance of transfer pricing
              compliance and its impact on a company's tax position and
              financial performance. Our services are customized to meet the
              specific needs of each client, focusing on risk management,
              compliance, and value creation.
            </p>
            <br />
            {/* <h4>
              Detailed accounting and management processes covered by us are as
              follows:
            </h4> */}
            <h3 className="submenu-header ">Why Choose PRISH ?</h3>
            <p className="submenu-sub-header ">
              Transfer Pricing Documentation:{" "}
              <span className="text">
                We prepare comprehensive transfer pricing documentation to
                support the arm's length nature of intercompany transactions,
                including master files, local files, and country-by-country
                reports.
              </span>
            </p>

            <p className="submenu-sub-header ">
              Transfer Pricing Policy Development:{" "}
              <span className="text">
                We assist in developing and implementing transfer pricing
                policies that align with your business objectives, mitigate
                risks, and ensure compliance with local regulations.
              </span>{" "}
            </p>
            <p className="submenu-sub-header ">
              Transfer Pricing Planning:{" "}
              <span className="text">
                Our team provides strategic transfer pricing planning to
                optimize your company's global tax position, minimize disputes
                with tax authorities, and maximize operational efficiencies.
              </span>
            </p>
            <p className="submenu-sub-header ">
              Transfer Pricing Audits and Dispute Resolution:{" "}
              <span className="text">
                We represent clients in transfer pricing audits and assist in
                resolving disputes with tax authorities, including negotiating
                advanced pricing agreements (APAs) and mutual agreement
                procedures (MAPs).
              </span>
            </p>
          </div>
        </div>
        <div className="accordian-section">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  What is transfer pricing?
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Transfer pricing refers to the pricing of goods, services, and
                  intangible assets transferred between related entities within
                  a multinational corporation.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  Why is transfer pricing important?
                </button>
              </h2>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Transfer pricing is important because it ensures that
                  transactions between related parties are conducted at arm's
                  length, as if they were between independent parties. This
                  helps to prevent tax evasion, ensure fair taxation, and comply
                  with regulations.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  What are the risks associated with transfer pricing?
                </button>
              </h2>
              <div
                id="collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  The risks associated with transfer pricing include double
                  taxation, penalties for non-compliance, reputational damage,
                  and disputes with tax authorities.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsefour"
                  aria-expanded="false"
                  aria-controls="collapsefour"
                >
                  What is the arm's length principle?
                </button>
              </h2>
              <div
                id="collapsefour"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  The arm's length principle is the international standard for
                  determining transfer prices, which requires that the price
                  charged for a transaction between related parties be the same
                  as it would be between unrelated parties in an open market
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsefive"
                  aria-expanded="false"
                  aria-controls="collapsefive"
                >
                  What documentation is required for transfer pricing
                  compliance?
                </button>
              </h2>
              <div
                id="collapsefive"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Documentation typically includes a master file, local file,
                  and country-by-country report, detailing the company's
                  transfer pricing policies, intercompany transactions, and
                  related financial information.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSix"
                  aria-expanded="false"
                  aria-controls="collapseSix"
                >
                  Are there any transfer pricing methods used for determining
                  arm's length prices?
                </button>
              </h2>
              <div
                id="collapseSix"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Yes, common transfer pricing methods include the comparable
                  uncontrolled price (CUP) method, cost-plus method, resale
                  price method, and transactional net margin method (TNMM),
                  among others.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSeven"
                  aria-expanded="false"
                  aria-controls="collapseSeven"
                >
                  What is the process for implementing a transfer pricing
                  policy?
                </button>
              </h2>
              <div
                id="collapseSeven"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  The process typically involves conducting a transfer pricing
                  study to assess intercompany transactions, determining
                  appropriate transfer pricing methods, documenting policies and
                  procedures, and monitoring compliance.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseEight"
                  aria-expanded="false"
                  aria-controls="collapseEight"
                >
                  What are the benefits of implementing a transfer pricing
                  policy?
                </button>
              </h2>
              <div
                id="collapseEight"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Benefits include reducing the risk of penalties and
                  assessments from tax authorities, optimizing the company's tax
                  position, improving operational efficiency, and ensuring
                  compliance with regulations.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseNine"
                  aria-expanded="false"
                  aria-controls="collapseNine"
                >
                  How often should transfer pricing policies be reviewed and
                  updated?
                </button>
              </h2>
              <div
                id="collapseNine"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Transfer pricing policies should be reviewed and updated
                  regularly to reflect changes in the company's business
                  operations, regulatory requirements, and market conditions.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Query />
    </>
  );
};

export default TransferPricing;
