import React, { useState } from "react";
import "./PrivacySection.css";
const PrivacySection = () => {
  const [isClicked, setIsclicked] = useState("block");

  const handleClick = () => {
    setIsclicked("none");
    localStorage.setItem("actionCookie", true);
  };
  return (
    <div
      class="cky-consent-container cky-banner-bottom"
      style={{ display: `${isClicked}` }}
    >
      <div
        class="cky-consent-bar"
        data-cky-tag="notice"
        style={{
          backgroundColor: "#FFFFFF",
          borderColor: "#f4f4f4",
          color: "#212121",
        }}
      >
        <div class="cky-notice">
          <p
            class="cky-title"
            data-cky-tag="title"
            style={{ color: "#212121" }}
          >
            We value your privacy
          </p>
          <div class="cky-notice-group">
            <div
              class="cky-notice-des"
              data-cky-tag="description"
              style={{ color: "#212121" }}
            >
              <div
                class="cky-notice-des"
                data-cky-tag="description"
                style={{ color: "#212121" }}
              >
                <div
                  class="cky-notice-des"
                  data-cky-tag="description"
                  style={{ color: "#212121" }}
                >
                  <p>
                    We use cookies to make PRISH website a better place. Cookies
                    help to provide a more personalized experience and relevant
                    advertising for you, and web analytics for us. By clicking
                    "Accept All", you consent to our use of cookies.
                  </p>
                </div>
              </div>
              <div class="cky-notice-btn-wrapper" data-cky-tag="notice-buttons">
                &nbsp;
              </div>
            </div>
            <div class="cky-notice-btn-wrapper" data-cky-tag="notice-buttons">
              <button
                class="cky-btn cky-btn-reject"
                aria-label="Reject All"
                data-cky-tag="reject-button"
                style={{
                  color: "#1863dc",
                  backgroundColor: "transparent",
                  borderColor: "#1863dc",
                }}
                onClick={handleClick}
              >
                Reject All
              </button>
              <button
                class="cky-btn cky-btn-accept"
                aria-label="Accept All"
                data-cky-tag="accept-button"
                style={{
                  color: "#FFFFFF",
                  backgroundColor: "#1863dc",
                  borderColor: "#1863dc",
                }}
                onClick={handleClick}
              >
                Accept All
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacySection;
