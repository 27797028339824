import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import company from "./images/rera_audit1.jpeg";
import company1 from "./images/rera_audit2.jpeg";
import company2 from "./images/rera_audit3.jpeg";
import bgImage from "../Home/Images/homepage2.jpeg";
import bgImage1 from "../Home/Images/homepage3.jpg";
import bgImage2 from "../Home/Images/homepage4.jpg";
import bgImage3 from "../Home/Images/homepage5.jpg";
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md";
import Query from "../Components/Query/Query";

const ReraAudit = () => {
  const [image, setImage] = useState("");
  const [count, setCount] = useState(0);
  const scollToRef = useRef();
  useEffect(() => {
    scollToRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);
  const Imagesdata = [
    {
      images: bgImage,
      id: 1,
    },
    {
      images: bgImage1,
      id: 2,
    },
    {
      images: bgImage2,
      id: 3,
    },
    {
      images: bgImage3,
      id: 4,
    },
  ];
  useEffect(() => {
    setImage(Imagesdata[0].images);
  }, []);
  // Function is called everytime increment button is clicked
  const handleClick1 = () => {
    // Counter state is incremented
    if (count < 3) {
      setImage(Imagesdata[count + 1]?.images || bgImage);
      setCount(count + 1);
    } else {
      setCount(0);
      setImage(Imagesdata[0]?.images);
    }
  };

  // Function is called everytime decrement button is clicked
  const handleClick2 = () => {
    // Counter state is decremented
    if (count > 0) {
      setImage(Imagesdata[count - 1].images);
      setCount(count - 1);
    } else {
      setCount(3);
      setImage(Imagesdata[3].images);
    }
  };
  return (
    <>
      <section className="corporate-sec">
        <div
          className={`${"info"}`}
          style={{ backgroundImage: `url(${image})` }}
        >
          <h1 className="typo5">Rera Audit</h1>
          <div className="rightIcon">
            <div className="rightIconImageBox" onClick={handleClick1}>
              <MdArrowForwardIos color="#ffffff" />
            </div>
          </div>
          <div className="leftIcon" onClick={handleClick2}>
            <div className="leftIconImageBox">
              <MdArrowBackIos color="#ffffff" />
            </div>
          </div>
        </div>
        <div className="corporate-sec-1  ">
          <div className="corporate-img">
            <div class="hexagon">
              <img src={company} width="200" height="200" />
            </div>
            <div className="nextConatainer">
              <div class="hexagon1">
                <img src={company1} width="200" height="200" />
              </div>
            </div>
            <div class="hexagon2">
              <img src={company2} width="200" height="200" />
            </div>

            {/* <img src="./CorporateImage/img1.jpeg" alt="img" width={500} /> */}
          </div>
          <div className="info-paragraphs">
            <h3 ref={scollToRef} className="audit-header">
              Rera Audit
            </h3>
            <p className="text">
              Real Estate Regulatory Authority (RERA) is responsible for
              regulating the real estate sector in the UAE, especially in Dubai.
              RERA audits are conducted to ensure compliance with regulations,
              transparency, and fairness in real estate transactions by
              promoting confidence and trust among investors, buyers, and other
              stakeholders.
            </p>
            <p className="text">
              It examines and verifies real estate transactions, financial
              records, and operational processes to ensure compliance with the
              regulations set forth by the Real Estate Regulatory Authority.Our
              experienced team conducts thorough audits to assess regulatory
              compliance, financial transparency, and operational efficiency.
            </p>
            <p className="text">
              Our approach is to understand the complexities of RERA regulations
              and the importance of compliance for real estate businesses which
              is comprehensive, detail-oriented, and aimed at providing
              actionable insights to our clients.
            </p>
            <p className="text">
              Our real estate developer and project services include the
              following:
            </p>
            <ul>
              <li>Accounting for management</li>
              <li>Ensure that all service entity criteria are met.</li>
              <li>Ensure that all applicable tax rules are followed.</li>
              <li>Ensure compliance with trust accounts.</li>
              <li>Conducting an audit of the ESCROW account</li>
              <li>Audit of the entire community</li>
              <li>Internal Controls</li>
              <li>Service fee audit</li>
              <li>Verification of unit balance</li>
              <li>Review of the Special Levy</li>
              <li>Budget audits for municipalities and organizations</li>
              <li>Mollak service</li>
            </ul>
            <br />
            {/* <h4>
              Detailed accounting and management processes covered by us are as
              follows:
            </h4> */}
            <h3 className="submenu-header ">Why Choose PRISH ?</h3>
            <p className="submenu-sub-header ">
              Regulatory Compliance Assessment:{" "}
              <span className="text">
                We assess the compliance of real estate transactions, contracts,
                and operations with RERA regulations, including registration,
                escrow account management, project approvals, and sales
                agreements.
              </span>
            </p>

            <p className="submenu-sub-header ">
              Financial Transparency Review:{" "}
              <span className="text">
                We examine financial records, including income statements,
                balance sheets, and cash flow statements, to ensure transparency
                and accuracy in financial reporting.
              </span>{" "}
            </p>
            <p className="submenu-sub-header ">
              Project Documentation Verification:{" "}
              <span className="text">
                We review project documentation, including contracts, permits,
                licenses, and approvals, to ensure completeness and compliance
                with RERA requirements.
              </span>
            </p>
            <p className="submenu-sub-header ">
              Operational Process Evaluation:{" "}
              <span className="text">
                We evaluate operational processes, such as project management,
                sales and marketing practices, customer service, and dispute
                resolution mechanisms, to identify areas for improvement and
                compliance enhancement.
              </span>
            </p>
            <p className="submenu-sub-header ">
              Risk Assessment and Mitigation:{" "}
              <span className="text">
                We assess potential risks associated with RERA non-compliance
                and provide recommendations to mitigate them effectively.
              </span>
            </p>
          </div>
        </div>
        <div className="accordian-section">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  What is a RERA Audit, and why is it important for real estate
                  businesses in the UAE?
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  A RERA Audit is an examination of real estate transactions,
                  financial records, and operational processes to ensure
                  compliance with regulations set forth by the Real Estate
                  Regulatory Authority (RERA) in the UAE. It is essential for
                  real estate businesses to maintain transparency, mitigate
                  risks, and comply with regulatory requirements to avoid
                  penalties and legal issues.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  Who needs to conduct a RERA Audit in the UAE?
                </button>
              </h2>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Real estate developers, brokers, investors, and property
                  management firms in the UAE are required to conduct RERA
                  Audits to ensure compliance with RERA regulations.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  What are the key areas covered in a RERA Audit in the UAE?
                </button>
              </h2>
              <div
                id="collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  A RERA Audit in the UAE typically covers regulatory
                  compliance, financial transparency, project documentation
                  verification, operational process evaluation, and risk
                  assessment.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsefour"
                  aria-expanded="false"
                  aria-controls="collapsefour"
                >
                  How often should RERA Audits be conducted in the UAE?
                </button>
              </h2>
              <div
                id="collapsefour"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  The frequency of RERA Audits in the UAE depends on various
                  factors such as the size of the real estate project, the scope
                  of operations, and regulatory requirements. However, it is
                  advisable for real estate businesses to conduct RERA Audits
                  regularly to maintain compliance and transparency.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsefive"
                  aria-expanded="false"
                  aria-controls="collapsefive"
                >
                  What are the benefits of conducting a RERA Audit in the UAE?
                </button>
              </h2>
              <div
                id="collapsefive"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <p className="submenu-sub-header ">
                    Compliance Assurance:
                    <span className="text">
                      {" "}
                      RERA Audits help real estate businesses ensure compliance
                      with regulatory requirements, reducing the risk of
                      penalties and legal issues.
                    </span>
                  </p>{" "}
                  <p className="submenu-sub-header ">
                    Accuracy and Transparency:
                    <span className="text">
                      {" "}
                      Audits provide assurance regarding the accuracy and
                      transparency of gold transactions, enhancing stakeholders'
                      confidence.
                    </span>
                  </p>{" "}
                  <p className="submenu-sub-header ">
                    Financial Transparency:
                    <span className="text">
                      {" "}
                      Audits promote financial transparency and accountability
                      by ensuring accurate reporting and management of funds in
                      compliance with RERA requirements.
                    </span>
                  </p>{" "}
                  <p className="submenu-sub-header ">
                    Operational Efficiency:
                    <span className="text">
                      {" "}
                      Audits help real estate businesses streamline operations,
                      improve internal controls, and enhance overall efficiency.
                    </span>
                  </p>{" "}
                  <p className="submenu-sub-header ">
                    Stakeholder Confidence:
                    <span className="text">
                      {" "}
                      Compliance with RERA regulations enhances the confidence
                      and trust of investors, buyers, and other stakeholders in
                      the UAE real estate market.
                    </span>
                  </p>{" "}
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSix"
                  aria-expanded="false"
                  aria-controls="collapseSix"
                >
                  How long does a RERA Audit take in the UAE?
                </button>
              </h2>
              <div
                id="collapseSix"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  The duration of a RERA Audit in the UAE depends on the size
                  and complexity of the real estate project, as well as the
                  scope of the audit.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Query />
    </>
  );
};

export default ReraAudit;
