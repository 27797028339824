import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import company from "./images/standard_operating1.jpeg";
import company1 from "./images/standard_operating2.jpeg";
import company2 from "./images/standard_operating3.jpeg";
import bgImage from "../Home/Images/homepage2.jpeg";
import bgImage1 from "../Home/Images/homepage3.jpg";
import bgImage2 from "../Home/Images/homepage4.jpg";
import bgImage3 from "../Home/Images/homepage5.jpg";
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md";
import Query from "../Components/Query/Query";

const StandardAudit = () => {
  const [image, setImage] = useState("");
  const [count, setCount] = useState(0);
  const scollToRef = useRef();
  useEffect(() => {
    scollToRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);
  const Imagesdata = [
    {
      images: bgImage,
      id: 1,
    },
    {
      images: bgImage1,
      id: 2,
    },
    {
      images: bgImage2,
      id: 3,
    },
    {
      images: bgImage3,
      id: 4,
    },
  ];
  useEffect(() => {
    setImage(Imagesdata[0].images);
  }, []);
  // Function is called everytime increment button is clicked
  const handleClick1 = () => {
    // Counter state is incremented
    if (count < 3) {
      setImage(Imagesdata[count + 1]?.images || bgImage);
      setCount(count + 1);
    } else {
      setCount(0);
      setImage(Imagesdata[0]?.images);
    }
  };

  // Function is called everytime decrement button is clicked
  const handleClick2 = () => {
    // Counter state is decremented
    if (count > 0) {
      setImage(Imagesdata[count - 1].images);
      setCount(count - 1);
    } else {
      setCount(3);
      setImage(Imagesdata[3].images);
    }
  };
  return (
    <>
      <section className="corporate-sec">
        <div
          className={`${"info"}`}
          style={{ backgroundImage: `url(${image})` }}
        >
          <h1 className="typo5">Standard Operating Procedures (SOPs)</h1>
          <div className="rightIcon">
            <div className="rightIconImageBox" onClick={handleClick1}>
              <MdArrowForwardIos color="#ffffff" />
            </div>
          </div>
          <div className="leftIcon" onClick={handleClick2}>
            <div className="leftIconImageBox">
              <MdArrowBackIos color="#ffffff" />
            </div>
          </div>
        </div>
        <div className="corporate-sec-1  ">
          <div className="corporate-img">
            <div class="hexagon">
              <img src={company} width="200" height="200" />
            </div>
            <div className="nextConatainer">
              <div class="hexagon1">
                <img src={company1} width="200" height="200" />
              </div>
            </div>
            <div class="hexagon2">
              <img src={company2} width="200" height="200" />
            </div>

            {/* <img src="./CorporateImage/img1.jpeg" alt="img" width={500} /> */}
          </div>
          <div className="info-paragraphs">
            <h3 ref={scollToRef} className="audit-header">
              Standard Operating Procedures (SOPs)
            </h3>
            <p className="text">
              This would include a comprehensive range of services including
              preparation and development of detailed process SOPs with process
              flowcharts and narratives with Delegation of Authority (DOA)
              Matrix, review of established SOPs, implementation of established
              SOPs by providing employee training with an objective of overall
              organizational effectiveness.Standard Operating Procedures (SOPs)
              are documented step-by-step instructions that outline how specific
              tasks or processes should be performed within an organization
              which ensure consistency, efficiency, and compliance with
              established standards
            </p>
            <p className="text">
              Our experienced team works closely with clients to develop
              customized SOPs that align with industry best practices and meet
              regulatory requirements.
            </p>
            <p className="text">
              Our approach is to understand the importance of having
              well-defined SOPs to streamline operations and ensure quality and
              compliance. We work closely with your team to understand your
              processes, identify areas for improvement, and develop SOPs that
              are practical, clear, and easy to follow.
            </p>
            <br />
            {/* <h4>
              Detailed accounting and management processes covered by us are as
              follows:
            </h4> */}
            <h3 className="submenu-header ">Why Choose PRISH ?</h3>
            <p className="submenu-sub-header ">
              Process Analysis:{" "}
              <span className="text">
                We conduct a thorough analysis of your existing processes to
                identify gaps, inefficiencies, and areas for improvement.
              </span>
            </p>

            <p className="submenu-sub-header ">
              SOP Development:{" "}
              <span className="text">
                Based on our analysis, we develop customized SOPs that outline
                the steps, responsibilities, and requirements for each process,
                ensuring clarity and consistency.
              </span>{" "}
            </p>
            <p className="submenu-sub-header ">
              Training and Implementation Support:{" "}
              <span className="text">
                We provide training and support to help your team understand and
                implement the new SOPs effectively.
              </span>
            </p>
            <p className="submenu-sub-header ">
              Review and Revision:{" "}
              <span className="text">
                We regularly review and update the SOPs to ensure they remain
                current and reflect any changes in your processes or regulatory
                requirements.
              </span>
            </p>
          </div>
        </div>
        <div className="accordian-section">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  What are Standard Operating Procedures (SOPs)?
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Standard Operating Procedures (SOPs) are documented
                  instructions that outline the steps, responsibilities, and
                  requirements for performing specific tasks or processes within
                  an organization.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  Why are SOPs important for businesses?
                </button>
              </h2>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  SOPs are important for businesses because they ensure
                  consistency, efficiency, and compliance with established
                  standards. They help streamline operations, reduce errors, and
                  improve overall performance.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  What is the process for developing SOPs with PRISH
                  Consultancy?
                </button>
              </h2>
              <div
                id="collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  PRISH Consultancy follows a structured process for developing
                  SOPs, which includes process analysis, SOP development,
                  training and implementation support, and regular review and
                  revision.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsefour"
                  aria-expanded="false"
                  aria-controls="collapsefour"
                >
                  What industries can benefit from SOP preparation and
                  development services?
                </button>
              </h2>
              <div
                id="collapsefour"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  SOP preparation and development services are beneficial to
                  businesses across various industries, including manufacturing,
                  healthcare, hospitality, retail, and more.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsefive"
                  aria-expanded="false"
                  aria-controls="collapsefive"
                >
                  How can SOPs benefit my organization?
                </button>
              </h2>
              <div
                id="collapsefive"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <p className="submenu-sub-header ">
                    Consistency:
                    <span className="text">
                      {" "}
                      Ensure consistent execution of tasks and processes.
                    </span>
                  </p>{" "}
                  <p className="submenu-sub-header ">
                    Efficiency:
                    <span className="text">
                      Streamline operations and reduce errors.
                    </span>
                  </p>{" "}
                  <p className="submenu-sub-header ">
                    Compliance:
                    <span className="text">
                      {" "}
                      Ensure compliance with regulatory requirements and
                      industry standards.
                    </span>
                  </p>{" "}
                  <p className="submenu-sub-header ">
                    Training and Onboarding:
                    <span className="text">
                      {" "}
                      Facilitate training and onboarding of new employees.
                    </span>
                  </p>{" "}
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSix"
                  aria-expanded="false"
                  aria-controls="collapseSix"
                >
                  How often should SOPs be reviewed and updated?
                </button>
              </h2>
              <div
                id="collapseSix"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  SOPs should be reviewed and updated regularly to ensure they
                  remain current and reflect any changes in processes or
                  regulatory requirements. This could be annually or as needed
                  based on changes in the organization.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSeven"
                  aria-expanded="false"
                  aria-controls="collapseSeven"
                >
                  Can SOPs help improve employee performance?
                </button>
              </h2>
              <div
                id="collapseSeven"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Yes, SOPs can help improve employee performance by providing
                  clear instructions and guidelines for performing tasks,
                  reducing errors, and ensuring consistency in execution.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseEight"
                  aria-expanded="false"
                  aria-controls="collapseEight"
                >
                  What is the difference between SOPs and work instructions?
                </button>
              </h2>
              <div
                id="collapseEight"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  SOPs provide a comprehensive overview of a process, including
                  the steps, responsibilities, and requirements, while work
                  instructions provide detailed guidance on how to perform
                  specific tasks within a process.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Query />
    </>
  );
};

export default StandardAudit;
