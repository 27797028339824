import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import company from "./images/salesCertification1.jpeg";
import company1 from "./images/salesCertification2.jpeg";
import company2 from "./images/salesCertification3.jpeg";
import bgImage from "../Home/Images/homepage2.jpeg";
import bgImage1 from "../Home/Images/homepage3.jpg";
import bgImage2 from "../Home/Images/homepage4.jpg";
import bgImage3 from "../Home/Images/homepage5.jpg";
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md";
import Query from "../Components/Query/Query";

const SalesTaxAudit = () => {
  const [image, setImage] = useState("");
  const [count, setCount] = useState(0);
  const scollToRef = useRef();
  useEffect(() => {
    scollToRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);
  const Imagesdata = [
    {
      images: bgImage,
      id: 1,
    },
    {
      images: bgImage1,
      id: 2,
    },
    {
      images: bgImage2,
      id: 3,
    },
    {
      images: bgImage3,
      id: 4,
    },
  ];
  useEffect(() => {
    setImage(Imagesdata[0].images);
  }, []);
  // Function is called everytime increment button is clicked
  const handleClick1 = () => {
    // Counter state is incremented
    if (count < 3) {
      setImage(Imagesdata[count + 1]?.images || bgImage);
      setCount(count + 1);
    } else {
      setCount(0);
      setImage(Imagesdata[0]?.images);
    }
  };

  // Function is called everytime decrement button is clicked
  const handleClick2 = () => {
    // Counter state is decremented
    if (count > 0) {
      setImage(Imagesdata[count - 1].images);
      setCount(count - 1);
    } else {
      setCount(3);
      setImage(Imagesdata[3].images);
    }
  };
  return (
    <>
      <section className="corporate-sec">
        <div
          className={`${"info"}`}
          style={{ backgroundImage: `url(${image})` }}
        >
          <h1 className="typo5">Sales Tax Audit</h1>
          <div className="rightIcon">
            <div className="rightIconImageBox" onClick={handleClick1}>
              <MdArrowForwardIos color="#ffffff" />
            </div>
          </div>
          <div className="leftIcon" onClick={handleClick2}>
            <div className="leftIconImageBox">
              <MdArrowBackIos color="#ffffff" />
            </div>
          </div>
        </div>
        <div className="corporate-sec-1  ">
          <div className="corporate-img">
            <div class="hexagon">
              <img src={company} width="200" height="200" />
            </div>
            <div className="nextConatainer">
              <div class="hexagon1">
                <img src={company1} width="200" height="200" />
              </div>
            </div>
            <div class="hexagon2">
              <img src={company2} width="200" height="200" />
            </div>

            {/* <img src="./CorporateImage/img1.jpeg" alt="img" width={500} /> */}
          </div>
          <div className="info-paragraphs">
            <h3 ref={scollToRef} className="audit-header">
              Sales Tax Audit
            </h3>
            <p className="text">
              Sales Certification refers to a process whereby companies obtain
              accreditation to engage in sales activities within specific
              industries. Obtaining sales certification demonstrates competence,
              professionalism, and compliance with regulatory standards,
              enhancing the credibility and trustworthiness of the
              organization's activities. Our experienced team conducts thorough
              audits to assess sales processes, documentation, and reporting,
              ensuring accuracy and compliance.
            </p>
            <p className="text">
              Our process involves evaluating sales transactions, reviewing
              documentation, and assessing internal controls to ensure
              compliance and identify areas for improvement.
            </p>

            <br />
            {/* <h4>
              Detailed accounting and management processes covered by us are as
              follows:
            </h4> */}
            <h3 className="submenu-header ">Why Choose PRISH ?</h3>
            <p className="submenu-sub-header ">
              Process Evaluation:{" "}
              <span className="text">
                We assess sales processes, including invoicing, recording, and
                reporting, to ensure compliance with sales tax regulations.
              </span>
            </p>

            <p className="submenu-sub-header ">
              Documentation Review:{" "}
              <span className="text">
                We examine sales tax documentation such as invoices, receipts,
                and tax returns to verify accuracy and completeness.
              </span>{" "}
            </p>
            <p className="submenu-sub-header ">
              Compliance Verification:{" "}
              <span className="text">
                We verify compliance with sales tax regulations, including tax
                rates, exemptions, and filing requirements, to ensure accurate
                reporting and remittance.
              </span>
            </p>
            <p className="submenu-sub-header ">
              Internal Control Assessment:{" "}
              <span className="text">
                We evaluate internal controls related to sales tax processes to
                identify weaknesses and recommend enhancements to prevent errors
                and fraud.
              </span>
            </p>
            <p className="submenu-sub-header ">
              Audit Report and Recommendations:{" "}
              <span className="text">
                We provide a comprehensive audit report with findings and
                recommendations to help businesses improve their sales tax
                processes and achieve certification from tax authorities.
              </span>
            </p>
          </div>
        </div>
        <div className="accordian-section">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  What is a Sales Certification Audit?
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  A Sales Certification Audit is an examination of sales
                  processes, documentation, and reporting to ensure compliance
                  with sales tax regulations and obtain certification from tax
                  authorities.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  Why is a Sales Certification Audit important for businesses?
                </button>
              </h2>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  A Sales Certification Audit is important for businesses to
                  ensure compliance with sales tax regulations, avoid penalties,
                  and gain credibility with customers, suppliers, and investors.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  What does a Sales Certification Audit involve?
                </button>
              </h2>
              <div
                id="collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  A Sales Certification Audit involves evaluating sales
                  transactions, reviewing documentation, assessing internal
                  controls, and ensuring compliance with sales tax regulations.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsefour"
                  aria-expanded="false"
                  aria-controls="collapsefour"
                >
                  How often should businesses conduct Sales Certification
                  Audits?
                </button>
              </h2>
              <div
                id="collapsefour"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  The frequency of Sales Certification Audits depends on the
                  size of the business, the volume of sales, and regulatory
                  requirements. However, it is recommended to conduct audits
                  regularly to maintain compliance.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsefive"
                  aria-expanded="false"
                  aria-controls="collapsefive"
                >
                  What are the benefits of conducting a Sales Certification
                  Audit?
                </button>
              </h2>
              <div
                id="collapsefive"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <p className="submenu-sub-header ">
                    Compliance Assurance:
                    <span className="text">
                      {" "}
                      Audits help ensure compliance with sales tax regulations,
                      reducing the risk of penalties and legal issues.
                    </span>
                  </p>{" "}
                  <p className="submenu-sub-header ">
                    Accuracy and Efficiency:
                    <span className="text">
                      {" "}
                      By identifying errors and inefficiencies, audits improve
                      accuracy and efficiency in sales tax reporting.
                    </span>
                  </p>{" "}
                  <p className="submenu-sub-header ">
                    Cost Savings:
                    <span className="text">
                      {" "}
                      Audits help businesses avoid overpayment or underpayment
                      of sales taxes, leading to cost savings.
                    </span>
                  </p>{" "}
                  <p className="submenu-sub-header ">
                    Certification Readiness:
                    <span className="text">
                      {" "}
                      Audits prepare businesses for sales tax certification,
                      enhancing credibility with tax authorities and
                      stakeholders.
                    </span>
                  </p>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Query />
    </>
  );
};

export default SalesTaxAudit;
