import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import company from "./images/company.jpeg";
import company1 from "./images/company1.jpeg";
import company2 from "./images/company2.jpeg";
import bgImage from "../Home/Images/homepage2.jpeg";
import bgImage1 from "../Home/Images/homepage3.jpg";
import bgImage2 from "../Home/Images/homepage4.jpg";
import bgImage3 from "../Home/Images/homepage5.jpg";
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md";
import Query from "../Components/Query/Query";

const TransactionAudit = () => {
  const [image, setImage] = useState("");
  const [count, setCount] = useState(0);
  const scollToRef = useRef();
  useEffect(() => {
    scollToRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);
  const Imagesdata = [
    {
      images: bgImage,
      id: 1,
    },
    {
      images: bgImage1,
      id: 2,
    },
    {
      images: bgImage2,
      id: 3,
    },
    {
      images: bgImage3,
      id: 4,
    },
  ];
  useEffect(() => {
    setImage(Imagesdata[0].images);
  }, []);
  // Function is called everytime increment button is clicked
  const handleClick1 = () => {
    // Counter state is incremented
    if (count < 3) {
      setImage(Imagesdata[count + 1]?.images || bgImage);
      setCount(count + 1);
    } else {
      setCount(0);
      setImage(Imagesdata[0]?.images);
    }
  };

  // Function is called everytime decrement button is clicked
  const handleClick2 = () => {
    // Counter state is decremented
    if (count > 0) {
      setImage(Imagesdata[count - 1].images);
      setCount(count - 1);
    } else {
      setCount(3);
      setImage(Imagesdata[3].images);
    }
  };
  return (
    <>
      <section className="corporate-sec">
        <div
          className={`${"info"}`}
          style={{ backgroundImage: `url(${image})` }}
        >
          <h1 className="typo5">Transaction Audit</h1>
          <div className="rightIcon">
            <div className="rightIconImageBox" onClick={handleClick1}>
              <MdArrowForwardIos color="#ffffff" />
            </div>
          </div>
          <div className="leftIcon" onClick={handleClick2}>
            <div className="leftIconImageBox">
              <MdArrowBackIos color="#ffffff" />
            </div>
          </div>
        </div>
        <div className="corporate-sec-1  ">
          <div className="corporate-img">
            <div class="hexagon">
              <img src={company} width="200" height="200" />
            </div>
            <div className="nextConatainer">
              <div class="hexagon1">
                <img src={company1} width="200" height="200" />
              </div>
            </div>
            <div class="hexagon2">
              <img src={company2} width="200" height="200" />
            </div>

            {/* <img src="./CorporateImage/img1.jpeg" alt="img" width={500} /> */}
          </div>
          <div className="info-paragraphs">
            <h3 ref={scollToRef} className="audit-header">
              Transaction Audit
            </h3>
            <p className="text">
              Transaction Audits will involve the examination and review of
              specific financial transactions within an organization to ensure
              accuracy, compliance, and integrity. In this, we will focus on
              verifying the completeness, validity, and appropriateness of
              individual transactions recorded in the organization's financial
              records. It aims to ensure the integrity of financial data and
              processes.
            </p>
            <p className="text">
              Our experienced team conducts thorough audits to identify
              discrepancies, mitigate risks, and improve financial controls.
            </p>
            <p className="text">
              Our approach is to take a systematic approach to Transaction
              Audits. Our process involves reviewing individual transactions,
              analysing patterns and trends, and identifying areas for
              improvement. We focus on enhancing financial controls, reducing
              risks, and optimizing processes to add value to your organization.
            </p>

            <br />
            {/* <h4>
              Detailed accounting and management processes covered by us are as
              follows:
            </h4> */}
            <h3 className="submenu-header ">Why Choose PRISH ?</h3>
            <p className="submenu-sub-header ">
              Transaction Review:{" "}
              <span className="text">
                We analyse individual transactions, including purchases, sales,
                expenses, and payments, to ensure accuracy and completeness.
              </span>
            </p>

            <p className="submenu-sub-header ">
              Compliance Assessment:{" "}
              <span className="text">
                We verify transactions against relevant laws, regulations, and
                internal policies to ensure compliance and mitigate the risk of
                non-compliance.
              </span>{" "}
            </p>
            <p className="submenu-sub-header ">
              Risk Identification:{" "}
              <span className="text">
                We identify potential risks associated with transactions, such
                as fraud, errors, and inefficiencies, and provide
                recommendations for mitigation.
              </span>
            </p>
            <p className="submenu-sub-header ">
              Process Improvement:{" "}
              <span className="text">
                We evaluate transaction processes and controls to identify areas
                for improvement and recommend enhancements to streamline
                operations and reduce costs.
              </span>
            </p>
            <p className="submenu-sub-header ">
              Documentation Analysis:{" "}
              <span className="text">
                We review supporting documentation, such as invoices, receipts,
                and contracts, to ensure proper documentation and record-keeping
                practices.
              </span>
            </p>
          </div>
        </div>
        <div className="accordian-section">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  What is a Transaction Audit?
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  A Transaction Audit is an examination of financial
                  transactions within an organization to ensure accuracy,
                  compliance, and efficiency. It involves reviewing individual
                  transactions to verify their integrity and adherence to
                  internal controls and policies.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  Why are Transaction Audits important for businesses?
                </button>
              </h2>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Transaction Audits are essential for businesses to ensure the
                  integrity of financial data, identify errors and
                  irregularities, mitigate risks, and maintain compliance with
                  regulations and internal policies.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  What types of transactions are typically reviewed in a
                  Transaction Audit?
                </button>
              </h2>
              <div
                id="collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  In a Transaction Audit, various types of financial
                  transactions are reviewed, including purchases, sales,
                  expenses, payroll transactions, investments, and other
                  financial activities relevant to the organization's
                  operations.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsefour"
                  aria-expanded="false"
                  aria-controls="collapsefour"
                >
                  How often should businesses conduct Transaction Audits?
                </button>
              </h2>
              <div
                id="collapsefour"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  The frequency of Transaction Audits depends on factors such as
                  the size of the organization, the complexity of its
                  operations, and regulatory requirements. However, it is
                  generally advisable to conduct audits regularly, such as
                  annually or semi-annually, to ensure ongoing compliance and
                  risk management.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsefive"
                  aria-expanded="false"
                  aria-controls="collapsefive"
                >
                  Can Transaction Audits be customized to meet specific business
                  needs?
                </button>
              </h2>
              <div
                id="collapsefive"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Yes, Transaction Audits can be tailored to the specific needs
                  and requirements of each business. This serviceincludes
                  addressing the unique challenges and objectives of our
                  clients, ensuring maximum value and effectiveness.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSix"
                  aria-expanded="false"
                  aria-controls="collapseSix"
                >
                  How are Transaction Audits conducted?
                </button>
              </h2>
              <div
                id="collapseSix"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Transaction Audits are conducted through a systematic process
                  that involves planning, data collection, analysis, reporting,
                  and follow-up. The audit team reviews financial records,
                  analyzes transactions, identifies anomalies or discrepancies,
                  and provides recommendations for improvement.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSeven"
                  aria-expanded="false"
                  aria-controls="collapseSeven"
                >
                  How can businesses benefit from transaction Audit services?
                </button>
              </h2>
              <div
                id="collapseSeven"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  PRISH Consultancy offers comprehensive Transaction Audit
                  services designed to help businesses:
                  <ul>
                    <li>
                      Ensure the accuracy, compliance, and efficiency of
                      financial transactions.
                    </li>
                    <li>
                      Identify and mitigate risks associated with financial
                      activities.
                    </li>
                    <li>Enhance internal controls and process efficiency.</li>
                    <li>Maintain stakeholder trust and confidence.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Query />
    </>
  );
};

export default TransactionAudit;
