import React, { useRef, useState } from "react";
import "./Query.css";
import emailjs from "@emailjs/browser";
import Swal from "sweetalert2";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";

const Query = () => {
  const handleChange = (e, isphone = false) => {
    if (isphone) {
      setPassword((prev) => {
        return {
          ...prev,
          phone: e,
        };
      });
    } else {
      const { name, value } = e.target;
      setPassword((prev) => {
        return {
          ...prev,
          [name]: value,
        };
      });
      setErrors((prev) => {
        return { ...prev, [name]: "" };
      });
    }
  };
  const form = useRef();
  const [password, setPassword] = useState({
    user_name: "",
    phone: "",
    user_email: "",
    message: "",
  });
  const [errors, setErrors] = useState({
    user_name: "",
    phone: "",
    user_email: "",
    message: "",
  });
  const [disabled, isDisabled] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    const newErrors = {};
    const phoneRegex = /^\+?\d{1,3}\s?\d{6,14}$/;
    console.log(
      "form.currentform.current",
      form.current,
      !phoneRegex.test(password.phone.trim())
    );

    if (!password.user_name.trim()) {
      newErrors.user_name = "Name is required";
    }
    if (!password.phone.trim()) {
      newErrors.phone = "Phone is required";
    }
    if (!phoneRegex.test(password.phone.trim())) {
      newErrors.phone = "Please Enter Valid Phone Number";
    }
    if (!password.user_email.trim()) {
      newErrors.user_email = "Email is required";
    }
    if (!password.message.trim()) {
      newErrors.message = "Message is required";
    }
    setErrors(newErrors);
    isDisabled(true);

    emailjs
      .sendForm("service_cuef4a9", "template_2tvvgf7", form.current, {
        publicKey: "D1TUXJ99gyiQaetHB",
      })
      .then(
        () => {
          Swal.fire(
            "Success",
            "Form Submitted sucessfully, team will reach out to you shortly!",
            "success"
          );
          setPassword({
            user_name: "",
            phone: "",
            user_email: "",
            message: "",
          });
          isDisabled(false);
        },
        (error) => {
          Swal.fire(
            "Opsss...",
            "Error Occurred, Please fill the form again.",
            "error"
          );
          isDisabled(false);
        }
      );
  };
  return (
    <section class="ch-section container-fluid ch-contact ch-honeycomb-bg1 query-section">
      <div class="col-12 col-sm-6 ch-data ch-blue-query-bg">
        <div class="ch-form">
          <div class="wpcf7 js" id="wpcf7-f181-o1" lang="en-US" dir="ltr">
            <div class="screen-reader-response">
              <p role="status" aria-live="polite" aria-atomic="true"></p>{" "}
              <ul></ul>
            </div>
            <p className="query_title">Send an Enquiry</p>
            <form ref={form} onSubmit={sendEmail} class="wpcf7-form init">
              <div class="ch-split query-form">
                <p>
                  <span class="wpcf7-form-control-wrap" data-name="fname">
                    <input
                      size="40"
                      class="wpcf7-form-control wpcf7-text wpcf7-validates-as-required"
                      aria-required="true"
                      aria-invalid="false"
                      placeholder="Name"
                      type="text"
                      name="user_name"
                      value={password.user_name}
                      onChange={handleChange}
                    />
                    <div style={{ color: "red", fontSize: "13px" }}>
                      {errors["user_name"]}
                    </div>
                  </span>
                </p>
              </div>
              <div class="ch-phone query-form">
                <p>
                  <span class="wpcf7-form-control-wrap" data-name="phone">
                    <div className="phone-input-query">
                      <PhoneInput
                        defaultCountry="in"
                        placeholder={"Mobile no"}
                        name="phone"
                        onChange={(e) => handleChange(e, true)}
                        value={password.phone}
                      />
                    </div>
                    {/* <input
                      size="40"
                      class="wpcf7-form-control wpcf7-text wpcf7-tel wpcf7-validates-as-required wpcf7-validates-as-tel"
                      aria-required="true"
                      aria-invalid="false"
                      placeholder="Phone"
                      type="tel"
                      name="phone"
                      value={password.phone}
                      onChange={handleChange}
                    /> */}
                    <div style={{ color: "red", fontSize: "13px" }}>
                      {errors["phone"]}
                    </div>
                  </span>
                </p>
              </div>
              <div class="ch-full query-form">
                <p>
                  <span class="wpcf7-form-control-wrap" data-name="email">
                    <input
                      size="40"
                      class="wpcf7-form-control wpcf7-text wpcf7-email wpcf7-validates-as-required wpcf7-validates-as-email"
                      aria-required="true"
                      aria-invalid="false"
                      placeholder="Email"
                      pattern="\S+@\S+\.\S+"
                      type="email"
                      name="user_email"
                      value={password.user_email}
                      onChange={handleChange}
                    />
                    <div style={{ color: "red", fontSize: "13px" }}>
                      {errors["user_email"]}
                    </div>
                  </span>
                </p>
              </div>
              <div class="ch-full query-form">
                <p>
                  <span class="wpcf7-form-control-wrap" data-name="message">
                    <textarea
                      cols="40"
                      rows="3"
                      class="wpcf7-form-control wpcf7-textarea wpcf7-validates-as-required"
                      aria-required="true"
                      aria-invalid="false"
                      placeholder="Message"
                      name="message"
                      value={password.message}
                      onChange={handleChange}
                    ></textarea>
                    <div style={{ color: "red", fontSize: "13px" }}>
                      {errors["message"]}
                    </div>
                  </span>
                </p>
              </div>
              <div class="ch-split query-form">
                <p>
                  <input
                    class="wpcf7-form-control has-spinner wpcf7-submit"
                    type="submit"
                    value="Send"
                    // disabled={disabled}
                  />
                  <span class="wpcf7-spinner" />
                </p>
              </div>

              <div class="wpcf7-response-output" aria-hidden="true"></div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Query;
