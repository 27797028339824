import React, { useEffect, useState } from "react";
import "../Team/team.css";
import bgImage from "../Home/Images/homepage2.jpeg";
import bgImage1 from "../Home/Images/homepage3.jpg";
import bgImage2 from "../Home/Images/homepage4.jpg";
import bgImage3 from "../Home/Images/homepage5.jpg";
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md";

const Team = () => {
  const [image, setImage] = useState("");
  const [count, setCount] = useState(0);

  const Imagesdata = [
    {
      images: bgImage,
      id: 1,
    },
    {
      images: bgImage1,
      id: 2,
    },
    {
      images: bgImage2,
      id: 3,
    },
    {
      images: bgImage3,
      id: 4,
    },
  ];
  useEffect(() => {
    setImage(Imagesdata[0].images);
  }, []);
  // Function is called everytime increment button is clicked
  const handleClick1 = () => {
    // Counter state is incremented
    if (count < 3) {
      setImage(Imagesdata[count + 1]?.images || bgImage);
      setCount(count + 1);
    } else {
      setCount(0);
      setImage(Imagesdata[0]?.images);
    }
  };

  // Function is called everytime decrement button is clicked
  const handleClick2 = () => {
    // Counter state is decremented
    if (count > 0) {
      setImage(Imagesdata[count - 1].images);
      setCount(count - 1);
    } else {
      setCount(3);
      setImage(Imagesdata[3].images);
    }
  };
  return (
    <>
      <div className="info" style={{ backgroundImage: `url(${image})` }}>
        <h1 className="typo8">Team</h1>
        <div className="rightIcon">
          <div className="rightIconImageBox" onClick={handleClick1}>
            <MdArrowForwardIos color="#ffffff" />
          </div>
        </div>
        <div className="leftIcon" onClick={handleClick2}>
          <div className="leftIconImageBox">
            <MdArrowBackIos color="#ffffff" />
          </div>
        </div>
      </div>
      <div>
        <div className="team-content">
          <div className="team-content-bg">
            <div className="para">
              <h1 className="heading">Our Team</h1>
              <p>
                PRISH Accounting and Auditing LLC is a congregation of 50+
                highly qualified and experienced professionals in their core
                areas of services through delivering perfection beyond
                imagination. We combine established approaches with innovative
                ideas and creative applications that will help you succeed
                today, tomorrow, and every day.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Team;
