import React, { useEffect, useRef, useState } from "react";
import "../InnerSubMenus/style.css";
import aml from "./images/aml.jpeg";
import aml1 from "./images/aml1.jpeg";
import aml2 from "./images/aml2.jpeg";
import bgImage from "../Home/Images/homepage2.jpeg";
import bgImage1 from "../Home/Images/homepage3.jpg";
import bgImage2 from "../Home/Images/homepage4.jpg";
import bgImage3 from "../Home/Images/homepage5.jpg";
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md";
import Query from "../Components/Query/Query";

const BusinessServices = () => {
  const [image, setImage] = useState("");
  const [count, setCount] = useState(0);
  const scollToRef = useRef();
  useEffect(() => {
    scollToRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);
  const Imagesdata = [
    {
      images: bgImage,
      id: 1,
    },
    {
      images: bgImage1,
      id: 2,
    },
    {
      images: bgImage2,
      id: 3,
    },
    {
      images: bgImage3,
      id: 4,
    },
  ];
  useEffect(() => {
    setImage(Imagesdata[0].images);
  }, []);
  // Function is called everytime increment button is clicked
  const handleClick1 = () => {
    // Counter state is incremented
    if (count < 3) {
      setImage(Imagesdata[count + 1]?.images || bgImage);
      setCount(count + 1);
    } else {
      setCount(0);
      setImage(Imagesdata[0]?.images);
    }
  };

  // Function is called everytime decrement button is clicked
  const handleClick2 = () => {
    // Counter state is decremented
    if (count > 0) {
      setImage(Imagesdata[count - 1].images);
      setCount(count - 1);
    } else {
      setCount(3);
      setImage(Imagesdata[3].images);
    }
  };
  return (
    <>
      <section className="corporate-sec">
        <div
          className={`${"info"}`}
          style={{ backgroundImage: `url(${image})` }}
        >
          <h1 className="typo5">Anti-Money Laundering (AML) Compliances</h1>
          <div className="rightIcon">
            <div className="rightIconImageBox" onClick={handleClick1}>
              <MdArrowForwardIos color="#ffffff" />
            </div>
          </div>
          <div className="leftIcon" onClick={handleClick2}>
            <div className="leftIconImageBox">
              <MdArrowBackIos color="#ffffff" />
            </div>
          </div>
        </div>
        <div className="corporate-sec-1  ">
          <div className="corporate-img">
            <div class="hexagon">
              <img src={aml} width="200" height="200" />
            </div>
            <div className="nextConatainer">
              <div class="hexagon1">
                <img src={aml1} width="200" height="200" />
              </div>
            </div>
            <div class="hexagon2">
              <img src={aml2} width="200" height="200" />
            </div>

            {/* <img src="./CorporateImage/img1.jpeg" alt="img" width={500} /> */}
          </div>
          <div className="info-paragraphs">
            <h3 ref={scollToRef} className="audit-header">
              AML (Anti-Money Laundering)
            </h3>
            <p className="text">
              AML (Anti-Money Laundering) compliance refers to the set of
              regulations, policies, and procedures that financial institutions
              and certain businesses must implement to prevent and detect money
              laundering, terrorist financing, and other illicit activities.
            </p>
            <p className="text">
              It is another compliance requirement in UAE introduced by Federal
              Decree-Law No. 20 of 2018 and related regulations issued by
              various cabinet and ministerial decisions.
            </p>
            <p className="text">
              The goAML software solution was developed by the United Nations
              Office on Drugs and Crime (UNODC) to support the implementation of
              anti-money laundering (AML) and counter-terrorism financing (CTF)
              measures by financial intelligence units (FIUs) and other
              competent authorities.
            </p>
            <br />
            {/* <h4>
              Detailed accounting and management processes covered by us are as
              follows:
            </h4> */}
            <h3 className="submenu-header ">Why Choose PRISH ?</h3>
            <p className="submenu-sub-header ">
              We provide a full package with AML Compliances as mentioned below:
            </p>
            <p className="text "> 1- AML Registration</p>

            <p className="text ">2- AML Implementation</p>

            <p className="text ">
              3- Development of AML Standard Operating Procedures (SOPs)
            </p>
            <p className="text ">4- Corporate AML Training and Awareness</p>
            <p className="text ">
              5- Impact Assessment and Analysis of AML on Business
            </p>
            <p className="text ">6- AML Auditing</p>
            <p className="text ">7- AML Survey and Reporting</p>
          </div>
        </div>
        <div className="accordian-section">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  What is Money Laundering?
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Money laundering is defined as any financial or banking
                  transaction aimed at concealing or changing the source of
                  illegally obtained funds, by passing it through the financial
                  and banking system to make it appear as originating from
                  legitimate sources, and then re-pumping and investing it
                  illegally.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  What is Terrorist Financing?
                </button>
              </h2>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Terrorism is the unlawful threat of action designed to compel
                  the government or an international organization or intimidate
                  the public or a section of the public to advance a political,
                  religious, or ideological belief or cause. Terrorist Financing
                  (TF), therefore, is where funds are generated through a
                  legitimate source such as donations, or illegal activities
                  such as the drug trade or fraud. These funds are normally used
                  to support the agenda or cause of a terrorist organization.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  What is the difference between Money Laundering and Terrorist
                  Financing?
                </button>
              </h2>
              <div
                id="collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  With TF, the proceeds are used to carry out illegal activities
                  designed to intimidate or cause disruption to public safety
                  and are not necessarily from an illegal source. ML, on the
                  other hand, emphasizes the concealment of the proceeds of
                  criminal activity from detection. With both these crimes,
                  however, the perpetrators seek to mask the nexus between
                  themselves and the sources of their funding.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsefour"
                  aria-expanded="false"
                  aria-controls="collapsefour"
                >
                  What is Money Laundering and How Does It Work?
                </button>
              </h2>
              <div
                id="collapsefour"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Money laundering is the act of concealing the origin of
                  illegally earned money. Let’s say a financial criminal has
                  earned money through drug smuggling, trafficking, etc., and
                  wants to hide the black money from law enforcement bodies. For
                  this, the criminal will devise a strategy and place it in a
                  bank account, or casino or even invest it somewhere. Money
                  laundering works in the following three stages:
                  <p className="submenu-sub-header ">
                    Placement:
                    <span className="text">
                      This is the first stage where black money is segregated
                      into small amounts and placed in a financial
                      infrastructure like a bank.
                    </span>
                  </p>{" "}
                  <p className="submenu-sub-header ">
                    Layering:
                    <span className="text">
                      Now, black money is transacted multiple times through
                      sales and purchases. For instance, the fraudster might
                      convert some amount of money into bank drafts or invest in
                      real estate as well.
                    </span>
                  </p>{" "}
                  <p className="submenu-sub-header ">
                    Integration:
                    <span className="text">
                      This is the final stage when illegal money is brought into
                      the economy. Integration is done by fake loans, shell
                      companies, bogus invoices, etc.
                    </span>
                  </p>{" "}
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsefive"
                  aria-expanded="false"
                  aria-controls="collapsefive"
                >
                  How to monitor money laundering?
                </button>
              </h2>
              <div
                id="collapsefive"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  By monitoring company information and profiles, screening
                  Sanctions, PEP and Watchlists, tracking negative news about
                  all stakeholders, partners, and suppliers, searching legal
                  databases, and deep diving into the backgrounds of individuals
                  working on the project or partnering with your company.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSix"
                  aria-expanded="false"
                  aria-controls="collapseSix"
                >
                  Who is required to comply with the AML/CFT Regulations?
                </button>
              </h2>
              <div
                id="collapseSix"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Both domestic and international companies operating in the UAE
                  need to follow AML-CFT Law. There are three main categories of
                  companies that must comply:
                  <ul>
                    <li>Financial institutions.</li>
                    <li>
                      Designated non-financial businesses and professions.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSeven"
                  aria-expanded="false"
                  aria-controls="collapseSeven"
                >
                  What is Designated Non-Financial Businesses and Professions
                  (DNFBPs)?
                </button>
              </h2>
              <div
                id="collapseSeven"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Following categories of businesses are included in this
                  category:
                  <p className="submenu-sub-header ">
                    Brokers & Real Estate Agents:
                    <span className="text">
                      Real Estate Firm that carries out transactions with
                      customers involving the buying or selling of real property
                      For example, real estate developers, brokers and agents.
                    </span>
                  </p>{" "}
                  <p className="submenu-sub-header ">
                    Dealers in Precious Stones & Metals:
                    <span className="text">
                      Dealer of precious metals or stones who are are involved
                      in the production of precious metals or precious stones,
                      an intermediate buyer or broker, precious stone cutter and
                      polisher, precious metal refiners, and/or jewelry
                      manufacturer who use precious metals and precious stones
                      to retail sellers to the public or buyers and sellers in
                      the secondary and scrap markets. These include gold,
                      silver, platinum, diamonds etc. For example, jewelry
                      stores.
                    </span>
                  </p>{" "}
                  <p className="submenu-sub-header ">
                    Brokers & Real Estate Agents:
                    <span className="text">
                      Now, black money is transacted multiple times through
                      sales and purchases. For instance, the fraudster might
                      convert some amount of money into bank drafts or invest in
                      real estate as well.
                    </span>
                  </p>{" "}
                  <p className="submenu-sub-header ">
                    Independent Legal Professionals & Accountants:
                    <span className="text">
                      Auditing, accounting or tax consultancy Firm that provide
                      professional services and assurance to third parties on
                      the financial and tax soundness of them and/or their
                      business. For example, chartered accountants, accountants,
                      tax specialists etc.
                    </span>
                  </p>{" "}
                  <p className="submenu-sub-header ">
                    Providers of Corporate Service and Trusts:
                    <span className="text">
                      Trust or Company Service Provider who is involved in
                      providing business services and consulting to third
                      parties.
                    </span>
                  </p>{" "}
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseEight"
                  aria-expanded="false"
                  aria-controls="collapseEight"
                >
                  What is the UAE Central Bank’s role in AML / CFT and financial
                  crime prevention?
                </button>
              </h2>
              <div
                id="collapseEight"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  The CBUAE established a dedicated department in August 2020 to
                  handle all Anti-Money Laundering and Combatting the Financing
                  of Terrorism matters (AML/CFT), which the Banking Supervision
                  Department handled previously.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseNine"
                  aria-expanded="false"
                  aria-controls="collapseNine"
                >
                  What is the Financial Action Task Force or FATF?
                </button>
              </h2>
              <div
                id="collapseNine"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  FATF is an inter-governmental body that sets international
                  AML/CFT standards. As a policy-making body, FATF evaluates
                  whether countries’ AML/CFT regimes are sufficient for properly
                  preventing financial crime. Where there are deficiencies
                  cited, FATF may make recommendations to improve a country’s
                  laws and policies, as well as even recommend that certain
                  restrictions are imposed until the country’s laws and
                  regulations, and more importantly, the implementation of them,
                  meet the appropriate standards and are evaluated as effective.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTen"
                  aria-expanded="false"
                  aria-controls="collapseTen"
                >
                  What are the main regulations?
                </button>
              </h2>
              <div
                id="collapseTen"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  There are a variety of laws on AML/CFT activities in the UAE.
                  The most important are:
                  <ul>
                    <li>
                      Federal Decree-Law No. (20) of 2018 On Anti-Money
                      Laundering and Combating the Financing of Terrorism and
                      Financing of Illegal Organizations (the “AML-CFT Law” or
                      “the Law”)
                    </li>
                    <li>
                      Cabinet Decision No. (10) of 2019 Concerning the
                      Implementing Regulation of Decree-Law No. (20) of 2018 On
                      Anti-Money Laundering and Combating the Financing of
                      Terrorism and Illegal Organizations (the “AML-CFT
                      Decision” or “the Cabinet Decision”).
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseEleven"
                  aria-expanded="false"
                  aria-controls="collapseEleven"
                >
                  What am I required to do?
                </button>
              </h2>
              <div
                id="collapseEleven"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <ul>
                    <li>
                      Identify and verify the identity of your customers and of
                      their beneficial owners, and monitor the transactions of
                      and the business relationship with customers.
                    </li>
                    <li>
                      Report suspected money laundering or terrorism financing
                      to the public authorities and take supporting measures,
                      such as ensuring the proper training of personnel and the
                      establishment of appropriate internal preventive policies
                      and procedures.
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwelve"
                  aria-expanded="false"
                  aria-controls="collapseTwelve"
                >
                  What is an Anti-Money Laundering Program?
                </button>
              </h2>
              <div
                id="collapseTwelve"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  An anti-money laundering (AML) program is a set of procedures
                  designed to guard against someone using the firm to facilitate
                  money laundering or terrorist financing. The main components
                  that must be included are:
                  <ul>
                    <li>
                      Internal policies, procedures, and controls reasonably
                      designed to achieve compliance.
                    </li>
                    <li>
                      Appointment of a designated compliance officer to oversee
                      the program’s day-to-day operations.
                    </li>
                    <li>Ongoing training program.</li>
                    <li>Independent audit; and</li>
                    <li>
                      Appropriate risk-based procedures for conducting customer
                      due diligence including, but not limited to:
                      <br />
                      -understanding the nature and the purpose of developing a
                      customer risk profile; and
                    </li>
                    <br />– conducting ongoing monitoring to detect and report
                    suspicious transactions and on a risk basis to maintain and
                    update customer information including identifying and
                    verifying beneficial owners.
                  </ul>
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThirteen"
                  aria-expanded="false"
                  aria-controls="collapseThirteen"
                >
                  What is the monetary limitation for cash transactions under
                  AML/CFT Regulations?
                </button>
              </h2>
              <div
                id="collapseThirteen"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Transactions with monetary value equal to or exceeding AED
                  55,000 (single/related) requires entities to carry out
                  appropriate Customer Due Diligence measures and make
                  appropriate reporting as required by the regulation.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFourteen"
                  aria-expanded="false"
                  aria-controls="collapseFourteen"
                >
                  Who is a Compliance Officer?
                </button>
              </h2>
              <div
                id="collapseFourteen"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  As per the AML/CFT Law, Financial Institutions and DNFBPs
                  should appoint a Compliance officer in house or outsourced
                  with adequate expertise and knowledge who can implement
                  appropriate policies and procedures and make periodic
                  assessments and reports in relation to entity’s compliance
                  with the AML/CFT law.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFifteen"
                  aria-expanded="false"
                  aria-controls="collapseFifteen"
                >
                  What is CDD?
                </button>
              </h2>
              <div
                id="collapseFifteen"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  CDD (Customer Due Diligence) is the process of gathering facts
                  about a customer then checking the details obtained. These
                  measures must involve identifying the customer as well as
                  verifying the identity. The Customer Due Diligence process
                  meets identity verification requirements and further checks
                  can be added to the process to support AML regulatory
                  obligations.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsesixteen"
                  aria-expanded="false"
                  aria-controls="collapsesixteen"
                >
                  What are the Benefits of AML Compliance for Companies?
                </button>
              </h2>
              <div
                id="collapsesixteen"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  For companies, AML compliance brings in customer trust and
                  ensures that all onboarded parties are legitimate. Be it
                  end-users or intermediaries, Anti-Money Laundering compliance
                  allows every company to avoid potential money laundering and
                  terror financing risks. Moreover, it helps in identifying
                  high-risk entities before they become a problem for the
                  business.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSeventeen"
                  aria-expanded="false"
                  aria-controls="collapseSeventeen"
                >
                  How Does AML Compliance Benefit Customers?
                </button>
              </h2>
              <div
                id="collapseSeventeen"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  For customers, it gets a lot easier to trust companies that
                  comply with AML regulations. They can trust the company with
                  the Personally Identifiable Information (PII). Furthermore,
                  end-users know that they are safe from financial criminals.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseEithteen"
                  aria-expanded="false"
                  aria-controls="collapseEithteen"
                >
                  What Happens if a Company Fails to Comply with these Laws?
                </button>
              </h2>
              <div
                id="collapseEithteen"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  under AML/CFT Law in UAE there are both temporary and
                  unalterable punishments prescribed in the law ranging from
                  temporary imprisonment to imprisonment for not exceeding
                  10years and fines ranging from AED 100,000 (one hundred
                  thousand) to AED 50,000,000 (fifty million).
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Query />
    </>
  );
};

export default BusinessServices;
