import React, { useEffect, useRef, useState } from "react";
import "./merger.css";
import merger from "./images/merger.jpeg";
import merger1 from "./images/merger1.jpeg";
import merger2 from "./images/merger2.jpeg";
import bgImage from "../Home/Images/homepage2.jpeg";
import bgImage1 from "../Home/Images/homepage3.jpg";
import bgImage2 from "../Home/Images/homepage4.jpg";
import bgImage3 from "../Home/Images/homepage5.jpg";
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Query from "../Components/Query/Query";
const Merger = () => {
  const [image, setImage] = useState("");
  const [count, setCount] = useState(0);
  const navigate = useNavigate();
  const scollToRef = useRef();
  useEffect(() => {
    scollToRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);
  const Imagesdata = [
    {
      images: bgImage,
      id: 1,
    },
    {
      images: bgImage1,
      id: 2,
    },
    {
      images: bgImage2,
      id: 3,
    },
    {
      images: bgImage3,
      id: 4,
    },
  ];
  useEffect(() => {
    setImage(Imagesdata[0].images);
  }, []);
  // Function is called everytime increment button is clicked
  const handleClick1 = () => {
    // Counter state is incremented
    if (count < 3) {
      setImage(Imagesdata[count + 1]?.images || bgImage);
      setCount(count + 1);
    } else {
      setCount(0);
      setImage(Imagesdata[0]?.images);
    }
  };

  // Function is called everytime decrement button is clicked
  const handleClick2 = () => {
    // Counter state is decremented
    if (count > 0) {
      setImage(Imagesdata[count - 1].images);
      setCount(count - 1);
    } else {
      setCount(3);
      setImage(Imagesdata[3].images);
    }
  };
  return (
    <>
      <section className="corporate-sec">
        <div
          className={`${"info"}`}
          style={{ backgroundImage: `url(${image})` }}
        >
          <h1 className="typo5">Mergers & Acquisitions</h1>
          <div className="rightIcon">
            <div className="rightIconImageBox" onClick={handleClick1}>
              <MdArrowForwardIos color="#ffffff" />
            </div>
          </div>
          <div className="leftIcon" onClick={handleClick2}>
            <div className="leftIconImageBox">
              <MdArrowBackIos color="#ffffff" />
            </div>
          </div>
        </div>
        <div className="corporate-sec-1  ">
          <div className="corporate-img">
            <div class="hexagon">
              <img src={merger} width="200" height="200" />
            </div>
            <div className="nextConatainer">
              <div class="hexagon1">
                <img src={merger1} width="200" height="200" />
              </div>
            </div>
            <div class="hexagon2">
              <img src={merger2} width="200" height="200" />
            </div>

            {/* <img src="./CorporateImage/img1.jpeg" alt="img" width={500} /> */}
          </div>
          <div className="info-paragraphs">
            <h3 ref={scollToRef} className="audit-header">
              Mergers & Acquisitions
            </h3>
            <p className="text">
              Mergers and acquisitions (M&A) in the United Arab Emirates (UAE)
              have been significant in recent years, driven by the country's
              robust economy, favorable business environment, and strategic
              location as a global business hub.
            </p>
            <p className="text">
              We support the companies to get ready for mergers from the buyers
              and sellers’ perspective. Overall M&A process begins with
              identifying and evaluating target company, due diligence,
              negotiation, closing of deal, post deal integration.
            </p>
            <br />
            <h4
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/due-diligence")}
            >
              Due Diligence
            </h4>
            <p className="text">
              Financial, tax, and legal due diligence services are essential
              components of the mergers and acquisitions (M&A) process,
              providing valuable insights and risk assessments to potential
              buyers or investors. Thus, businesses are inclined towards
              engaging experienced professionals and we at PRISH stand beyond
              our clients expectation in conducting a thorough due diligence and
              achieving successful outcomes in M&A transactions.
              <button
                onClick={() => navigate("/due-diligence")}
                className="read-more-btn-1"
              >
                Read more
              </button>
            </p>
            <br />
            <h4
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/value-advisory")}
            >
              Valuation Advisory Services
            </h4>
            <p className="text">
              Valuation Advisory services encompass a range of consulting and
              advisory services related to business and asset valuation. These
              services provided by us includes valuation feasibility studies,
              fairness opinions, transaction advisory, due diligence support,
              valuation modelling, sensitivity analysis, and expert witness
              testimony. We provide independent, objective assessments and
              recommendations to help our clients make informed decisions for
              mergers & acquisitions.
              <button
                onClick={() => navigate("/value-advisory")}
                className="read-more-btn-1"
              >
                Read more
              </button>
            </p>

            {/*  */}
            <h4
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/restructuring")}
            >
              Restructuring and Reorganization services
            </h4>
            <p className="text">
              These are the essential services for businesses facing financial,
              operational, or strategic challenges to adapt, evolve, and thrive
              in dynamic and competitive markets. We have experienced
              restructuring professionals in our team who can help companies to
              navigate complex restructuring processes, unlock value, and
              position themselves for long-term success and sustainability.
              <button
                onClick={() => navigate("/restructuring")}
                className="read-more-btn-1"
              >
                Read more
              </button>
            </p>
          </div>
        </div>
      </section>
      <Query />
    </>
  );
};

export default Merger;
