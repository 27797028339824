import React from "react";
import "../Components/style.css";
import icon1 from "../Images/icon1.png";
import icon3 from "../Images/icon3.png";
import icon4 from "../Images/icon4.png";
import icon5 from "../Images/icon5.png";
import icon6 from "../Images/icon6.png";
import icon2 from "../Images/icon2.png";
import logosvg from "./Images/logo5.png";

import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer-sec-container ks">
      <div className="footer-sec">
        <div className="company">
          <div
            id="logo"
            class="footer-logo"
            style={{ marginTop: "15px", marginBottom: "0px" }}
            data-width="350"
            data-height="50"
          >
            <a href="/" title="Prish">
              <img
                src={logosvg} // Will change later
              />
            </a>
          </div>
          {/* <h3 className="footer-heading">About Company</h3> */}
          <div className="conpany-info">
            <p>
              <b>PRISH</b> Accounting and Auditing is a distinguished
              association of Chartered Accountants providing one stop solutions
              to all your business needs. We excel in Auditing, Accounting,
              Taxation, Consultancy and Corporate services. We understand the
              pivotal role financial integrity plays in the success of your
              endeavors and can provide support in all your finance related
              matters.
            </p>
          </div>
        </div>

        <div className="footer-links">
          <h3 className="footer-heading">USEFUL LINKS</h3>
          <div className="links">
            <Link to="/">Home</Link>
            <Link to="/about">About us</Link>
            <Link to="/blog">Blog</Link>
            <Link to="/reach">Reach us</Link>
          </div>
        </div>
        <div className="footer-links">
          <h3 className="footer-heading">SERVICES</h3>
          <div className="links">
            <Link to="/auditing-assurance-services">Audit &amp; Assurance</Link>
            {/* need to add links */}
            <Link to="/special-audit">Special Audits</Link>
            <Link to="/risk-advisory-services">Risk Assurance</Link>
            {/* need to add links */}
            <Link to="/tax-services">Taxation</Link>
            <Link to="/accounting-outsourcing-services">
              Accounting &amp; Bookkeeping
            </Link>

            <Link to="/anti-money-laundering">
              Anti Money Laundering (AML) Compliances
            </Link>

            <Link to="/compliance-services"> Corporate Consultancy</Link>
            <Link to="/company-incorporation-services">
              Company Incorporation Services
            </Link>
            <Link to="/merger-acquisitions"> Mergers & Acquisitions</Link>
          </div>
        </div>

        <div className="footer-links">
          <h3 className="footer-heading">CONTACT US:</h3>
          <Link className="links">info@prishauditors.com</Link>
          <Link className="links">+971-56 724 4122 (UAE)</Link>
        </div>
      </div>
      <div className="copyright">
        <p>&copy; 2024 PRISH. All rights reserved.</p>
      </div>
    </div>
  );
};

export default Footer;
