import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import company from "./images/corporate_tax1.jpeg";
import company1 from "./images/corporate_tax2.jpeg";
import company2 from "./images/corporate_tax3.jpeg";
import bgImage from "../Home/Images/homepage2.jpeg";
import bgImage1 from "../Home/Images/homepage3.jpg";
import bgImage2 from "../Home/Images/homepage4.jpg";
import bgImage3 from "../Home/Images/homepage5.jpg";
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md";
import Query from "../Components/Query/Query";

const UaeTax = () => {
  const [image, setImage] = useState("");
  const [count, setCount] = useState(0);
  const scollToRef = useRef();
  useEffect(() => {
    scollToRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);
  const Imagesdata = [
    {
      images: bgImage,
      id: 1,
    },
    {
      images: bgImage1,
      id: 2,
    },
    {
      images: bgImage2,
      id: 3,
    },
    {
      images: bgImage3,
      id: 4,
    },
  ];
  useEffect(() => {
    setImage(Imagesdata[0].images);
  }, []);
  // Function is called everytime increment button is clicked
  const handleClick1 = () => {
    // Counter state is incremented
    if (count < 3) {
      setImage(Imagesdata[count + 1]?.images || bgImage);
      setCount(count + 1);
    } else {
      setCount(0);
      setImage(Imagesdata[0]?.images);
    }
  };

  // Function is called everytime decrement button is clicked
  const handleClick2 = () => {
    // Counter state is decremented
    if (count > 0) {
      setImage(Imagesdata[count - 1].images);
      setCount(count - 1);
    } else {
      setCount(3);
      setImage(Imagesdata[3].images);
    }
  };
  return (
    <>
      <section className="corporate-sec">
        <div
          className={`${"info"}`}
          style={{ backgroundImage: `url(${image})` }}
        >
          <h1 className="typo5">UAE Corporate Tax</h1>
          <div className="rightIcon">
            <div className="rightIconImageBox" onClick={handleClick1}>
              <MdArrowForwardIos color="#ffffff" />
            </div>
          </div>
          <div className="leftIcon" onClick={handleClick2}>
            <div className="leftIconImageBox">
              <MdArrowBackIos color="#ffffff" />
            </div>
          </div>
        </div>
        <div className="corporate-sec-1  ">
          <div className="corporate-img">
            <div class="hexagon">
              <img src={company} width="200" height="200" />
            </div>
            <div className="nextConatainer">
              <div class="hexagon1">
                <img src={company1} width="200" height="200" />
              </div>
            </div>
            <div class="hexagon2">
              <img src={company2} width="200" height="200" />
            </div>

            {/* <img src="./CorporateImage/img1.jpeg" alt="img" width={500} /> */}
          </div>
          <div className="info-paragraphs">
            <h3 ref={scollToRef} className="audit-header">
              UAE Corporate Tax
            </h3>
            <p className="text">
              Corporate Tax has been introduced for the very first time in UAE
              with effect from 1st June 2023 and since the emerging law requires
              a vibrant understanding for our clients, we hereby provide UAE
              Corporate Tax Impact Assessment and Analysis, Registration, return
              filing, Corporate Tax Consultancy based on the specific businesses
              and their operations. UAE Corporate Services encompass a variety
              of services designed to assist businesses with company formation,
              compliance, governance, and other corporate matters. These
              services help businesses establish and maintain their presence in
              the UAE while ensuring compliance with local regulations.
            </p>
            <p className="text">
              Our expert team provides comprehensive support to help businesses
              navigate the complex regulatory landscape and achieve their
              corporate objectives efficiently and effectively.
            </p>
            <p className="text">
              Our approach is to understand the unique challenges and
              opportunities that businesses face in the UAE. It's tailored to
              meet the specific needs and objectives of each client. We provide
              personalized assistance, expert advice, and practical solutions to
              help businesses succeed in the UAE market.
            </p>
            <br />
            {/* <h4>
              Detailed accounting and management processes covered by us are as
              follows:
            </h4> */}
            <h3 className="submenu-header ">Why Choose PRISH ?</h3>
            <p className="submenu-sub-header ">
              Company Formation:{" "}
              <span className="text">
                We assist businesses with company setup, including choosing the
                right legal structure, preparing documentation, obtaining
                necessary licenses, and completing registration with relevant
                authorities.
              </span>
            </p>

            <p className="submenu-sub-header ">
              Corporate Governance:
              <span className="text">
                We guide corporate governance best practices and help businesses
                establish effective corporate governance frameworks to ensure
                transparency, accountability, and compliance with regulations.
              </span>{" "}
            </p>
            <p className="submenu-sub-header ">
              Compliance Management:
              <span className="text">
                Our team helps businesses navigate the complex regulatory
                environment in the UAE, ensuring compliance with company laws,
                tax regulations, employment laws, and other applicable
                regulations.
              </span>
            </p>
            <p className="submenu-sub-header ">
              Corporate Restructuring:
              <span className="text">
                We assist businesses with corporate restructuring activities
                such as mergers, acquisitions, reorganizations, and
                liquidations, helping them optimize their corporate structure
                and achieve their strategic objectives.
              </span>
            </p>
          </div>
        </div>
        <div className="accordian-section">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  What is Corporate Tax?
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Corporate Tax is a form of direct tax levied on the net income
                  or profit of corporations and other businesses. Corporate Tax
                  is sometimes also referred to as “Corporate Income Tax” or
                  “Business Profits Tax” in other jurisdictions.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  Why is the UAE introducing Corporate Tax?
                </button>
              </h2>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  A competitive Corporate Tax regime based on international best
                  practices is expected to cement the UAE’s position as a
                  leading global hub for business and investment and accelerate
                  the UAE’s development and transformation to achieve its
                  strategic objectives.
                  <br />
                  Introducing a Corporate Tax regime also reaffirms the UAE’s
                  commitment in meeting international standards for tax
                  transparency and preventing harmful tax practices.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  Is the UAE the first country to introduce Corporate Tax?
                </button>
              </h2>
              <div
                id="collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Most countries in the world have a comprehensive Corporate Tax
                  regime, including most of the countries in the Middle East.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsefour"
                  aria-expanded="false"
                  aria-controls="collapsefour"
                >
                  When is the UAE Corporate Tax regime effective?
                </button>
              </h2>
              <div
                id="collapsefour"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  The UAE Corporate Tax regime is effective for Financial Years
                  starting on or after 1 June 2023. Examples:
                  <ul>
                    <li>
                      A Business that has a Financial Year starting on 1 July
                      2023 and ending on 30 June 2024 is subject to UAE
                      Corporate Tax from 1 July 2023 (which is the beginning of
                      the first Financial Year that starts on or after 1 June
                      2023).
                    </li>
                    <li>
                      A Business that has a Financial Year starting on 1 January
                      2023 and ending on 31 December 2023 will become subject to
                      UAE Corporate Tax from 1 January 2024 (which is the
                      beginning of the first Financial Year that starts on or
                      after 1 June 2023).
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsefive"
                  aria-expanded="false"
                  aria-controls="collapsefive"
                >
                  Who is subject to UAE Corporate Tax?
                </button>
              </h2>
              <div
                id="collapsefive"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  UAE Corporate Tax applies to juridical persons incorporated in
                  the UAE and to foreign juridical persons that are effectively
                  managed and controlled in the UAE (see question 20: ‘Who is
                  considered a Resident Person for UAE Corporate Tax
                  purposes?’). A foreign juridical person that operates in the
                  UAE through a Permanent Establishment or that has a taxable
                  nexus in the UAE would also be subject to Corporate Tax (see
                  Section M “Foreign persons”).
                  <br />
                  Natural persons will be subject to Corporate Tax only if they
                  are engaged in a Business or Business Activity in the UAE,
                  either directly or through an Unincorporated Partnership or
                  sole proprietorship. Cabinet Decision No. 49 of 2023 specifies
                  further information on what would bring a natural person
                  within the scope of UAE Corporate Tax.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSix"
                  aria-expanded="false"
                  aria-controls="collapseSix"
                >
                  Will UAE entities owned by UAE or GCC nationals be subject to
                  UAE Corporate Tax?
                </button>
              </h2>
              <div
                id="collapseSix"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Yes. The application of UAE Corporate Tax does not
                  differentiate between entities that are locally or
                  internationally owned.
                  <br />
                  Juridical persons that are incorporated or resident in the
                  UAE, or foreign entities that have a Permanent Establishment
                  or taxable nexus in the UAE, will be subject to UAE Corporate
                  Tax irrespective of the residence and nationality of the
                  individual founders or (ultimate) owners of the entity.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSeven"
                  aria-expanded="false"
                  aria-controls="collapseSeven"
                >
                  Will UAE Corporate Tax be applicable to businesses in all
                  Emirates?
                </button>
              </h2>
              <div
                id="collapseSeven"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Yes. The UAE Corporate Tax is a federal tax and will therefore
                  apply across all the Emirates.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseEight"
                  aria-expanded="false"
                  aria-controls="collapseEight"
                >
                  Will I have to pay UAE Corporate Tax alongside Emirate level
                  taxes?
                </button>
              </h2>
              <div
                id="collapseEight"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Businesses engaged in the extraction of the UAE’s Natural
                  Resources and in certain non-extractive activities that are
                  subject to Emirate level taxation will be outside the scope of
                  UAE Corporate Tax, subject to meeting certain conditions.
                  <br />
                  Other businesses may be subject to both Corporate Tax and
                  Emirate level taxation. Emirate level taxes paid will not be
                  able to be credited against or otherwise reduce the amount of
                  Corporate Tax Payable.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseNine"
                  aria-expanded="false"
                  aria-controls="collapseNine"
                >
                  Will UAE Corporate Tax replace VAT in the UAE?
                </button>
              </h2>
              <div
                id="collapseNine"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  No, Corporate Tax and VAT are two different types of taxes.
                  Both will apply in the UAE.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTen"
                  aria-expanded="false"
                  aria-controls="collapseTen"
                >
                  Will I have to pay UAE Corporate Tax alongside VAT in the UAE?
                </button>
              </h2>
              <div
                id="collapseTen"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  If you are a registered business for VAT, you will have to pay
                  VAT and Corporate Tax separately. If your business is not
                  registered for VAT you may still have to pay Corporate Tax.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseEleven"
                  aria-expanded="false"
                  aria-controls="collapseEleven"
                >
                  Will UAE Corporate Tax replace Excise Tax in the UAE?
                </button>
              </h2>
              <div
                id="collapseEleven"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  No, Corporate Tax and Excise Tax are two different types of
                  taxes. Both will apply in the UAE.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwelve"
                  aria-expanded="false"
                  aria-controls="collapseTwelve"
                >
                  Will I continue to pay service fees to the Federal and Emirate
                  Governments now that the UAE has introduced Corporate Tax?
                </button>
              </h2>
              <div
                id="collapseTwelve"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Yes. Applicable service fees will continue to be payable to
                  the Federal or relevant Emirate Government.
                  <br />
                  Business set up, licence renewal and other Government fees and
                  charges that are incurred in the ordinary course of business
                  should generally be deductible expenses for UAE Corporate Tax
                  purposes.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThirteen"
                  aria-expanded="false"
                  aria-controls="collapseThirteen"
                >
                  Will I need to consider the UAE’s international agreements for
                  UAE Corporate Tax purposes?
                </button>
              </h2>
              <div
                id="collapseThirteen"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  In-force international agreements (including international
                  agreements for the avoidance of double taxation) to which the
                  UAE is a party should be considered under the UAE Corporate
                  Tax regime.
                  <br />
                  In case of a conflict between the Corporate Tax Law and an
                  international agreement with respect to the right to tax a
                  certain item of income, the relevant international agreement
                  may limit the application of UAE Corporate Tax.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFourteen"
                  aria-expanded="false"
                  aria-controls="collapseFourteen"
                >
                  What will be the role of the Federal Tax Authority?
                </button>
              </h2>
              <div
                id="collapseFourteen"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  The Federal Tax Authority will be responsible for the
                  administration, collection and enforcement of UAE Corporate
                  Tax and other federal taxes. For the purpose of the
                  administration, collection and enforcement of Corporate Tax,
                  the Federal Tax Authority will issue guides, respond to
                  clarifications and provide awareness sessions as required.
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFifteen"
                  aria-expanded="false"
                  aria-controls="collapseFifteen"
                >
                  What will be the role of the Ministry of Finance?
                </button>
              </h2>
              <div
                id="collapseFifteen"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  The Ministry of Finance is the competent authority for the
                  purposes of bilateral/multilateral tax agreements and the
                  international exchange of information for tax purposes. The
                  Ministry of Finance also has the authority to issue further
                  implementing regulations for UAE Corporate Tax and other
                  federal taxes.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Query />
    </>
  );
};

export default UaeTax;
