import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import company from "./images/system_audit1.jpeg";
import company1 from "./images/system_audit2.jpeg";
import company2 from "./images/system_audit3.jpeg";
import bgImage from "../Home/Images/homepage2.jpeg";
import bgImage1 from "../Home/Images/homepage3.jpg";
import bgImage2 from "../Home/Images/homepage4.jpg";
import bgImage3 from "../Home/Images/homepage5.jpg";
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md";
import Query from "../Components/Query/Query";

const SystemTaxAudit = () => {
  const [image, setImage] = useState("");
  const [count, setCount] = useState(0);
  const scollToRef = useRef();
  useEffect(() => {
    scollToRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);
  const Imagesdata = [
    {
      images: bgImage,
      id: 1,
    },
    {
      images: bgImage1,
      id: 2,
    },
    {
      images: bgImage2,
      id: 3,
    },
    {
      images: bgImage3,
      id: 4,
    },
  ];
  useEffect(() => {
    setImage(Imagesdata[0].images);
  }, []);
  // Function is called everytime increment button is clicked
  const handleClick1 = () => {
    // Counter state is incremented
    if (count < 3) {
      setImage(Imagesdata[count + 1]?.images || bgImage);
      setCount(count + 1);
    } else {
      setCount(0);
      setImage(Imagesdata[0]?.images);
    }
  };

  // Function is called everytime decrement button is clicked
  const handleClick2 = () => {
    // Counter state is decremented
    if (count > 0) {
      setImage(Imagesdata[count - 1].images);
      setCount(count - 1);
    } else {
      setCount(3);
      setImage(Imagesdata[3].images);
    }
  };
  return (
    <>
      <section className="corporate-sec">
        <div
          className={`${"info"}`}
          style={{ backgroundImage: `url(${image})` }}
        >
          <h1 className="typo5">System Audit</h1>
          <div className="rightIcon">
            <div className="rightIconImageBox" onClick={handleClick1}>
              <MdArrowForwardIos color="#ffffff" />
            </div>
          </div>
          <div className="leftIcon" onClick={handleClick2}>
            <div className="leftIconImageBox">
              <MdArrowBackIos color="#ffffff" />
            </div>
          </div>
        </div>
        <div className="corporate-sec-1  ">
          <div className="corporate-img">
            <div class="hexagon">
              <img src={company} width="200" height="200" />
            </div>
            <div className="nextConatainer">
              <div class="hexagon1">
                <img src={company1} width="200" height="200" />
              </div>
            </div>
            <div class="hexagon2">
              <img src={company2} width="200" height="200" />
            </div>

            {/* <img src="./CorporateImage/img1.jpeg" alt="img" width={500} /> */}
          </div>
          <div className="info-paragraphs">
            <h3 ref={scollToRef} className="audit-header">
              System Audit
            </h3>
            <p className="text">
              System audits are critical for identifying IT-related risks,
              enhancing security posture, improving operational efficiency, and
              ensuring compliance with regulatory requirements and industry best
              practices through mitigating IT risks, strengthening IT governance
              frameworks, and safeguarding IT assets and data. Our experienced
              team conducts thorough audits to identify vulnerabilities, assess
              controls, and provide recommendations for improvement.
            </p>
            <p className="text">
              Our approach is to understand the critical importance of robust
              information systems in today's digital world. Our System Audit
              approach is thorough, systematic, and tailored to the unique needs
              of each client. We focus on identifying weaknesses, assessing
              risks, and providing actionable recommendations to enhance the
              overall security and performance of your systems.
            </p>

            <br />
            {/* <h4>
              Detailed accounting and management processes covered by us are as
              follows:
            </h4> */}
            <h3 className="submenu-header ">Why Choose PRISH ?</h3>
            <p className="submenu-sub-header ">
              Risk Assessment:{" "}
              <span className="text">
                We conduct a comprehensive risk assessment to identify potential
                vulnerabilities, threats, and weaknesses in your information
                systems.
              </span>
            </p>

            <p className="submenu-sub-header ">
              Controls Evaluation:{" "}
              <span className="text">
                We evaluate the effectiveness of existing controls, including
                access controls, data security measures, and disaster recovery
                plans, to ensure they are adequate for mitigating identified
                risks.
              </span>{" "}
            </p>
            <p className="submenu-sub-header ">
              Compliance Review:{" "}
              <span className="text">
                We review your systems and processes to ensure compliance with
                relevant regulations, industry standards, and best practices,
                such as GDPR, HIPAA, ISO 27001, and PCI DSS.
              </span>
            </p>
            <p className="submenu-sub-header ">
              Performance Analysis:{" "}
              <span className="text">
                We assess the performance of your systems, including response
                times, availability, and scalability, to ensure they meet the
                needs of your organization.
              </span>
            </p>
            <p className="submenu-sub-header ">
              Documentation Review:{" "}
              <span className="text">
                We review documentation related to your information systems,
                including policies, procedures, and incident response plans, to
                ensure they are up-to-date and accurately reflect your
                organization's practices.
              </span>
            </p>
          </div>
        </div>
        <div className="accordian-section">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  What is a System Audit?
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  A System Audit is an evaluation of an organization's
                  information systems, including hardware, software, networks,
                  and procedures, to ensure they are operating effectively,
                  securely, and in compliance with relevant regulations and
                  standards.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  Why are System Audits important for businesses?
                </button>
              </h2>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  System Audits are crucial for businesses to assess the
                  effectiveness, security, and compliance of their information
                  systems. They help identify vulnerabilities, mitigate risks,
                  and ensure the integrity of data and operations
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  What are the key objectives of a System Audit?
                </button>
              </h2>
              <div
                id="collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  The key objectives of a System Audit include:
                  <ul>
                    <li>
                      Identifying security vulnerabilities and weaknesses in the
                      IT infrastructure.
                    </li>
                    <li>
                      Evaluating compliance with regulatory requirements and
                      industry standards.
                    </li>
                    <li>
                      Assessing the effectiveness of internal controls and risk
                      management practices.
                    </li>
                    <li>
                      Ensuring the reliability and availability of critical
                      systems and data.
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsefour"
                  aria-expanded="false"
                  aria-controls="collapsefour"
                >
                  What is the process involved in a System Audit?
                </button>
              </h2>
              <div
                id="collapsefour"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  The process of a System Audit typically includes:
                  <ul>
                    <li>
                      Planning and scoping: Defining the objectives, scope, and
                      methodology of the audit.
                    </li>
                    <li>
                      Data collection: Gathering information about the
                      organization's IT systems, processes, and controls.
                    </li>
                    <li>
                      Analysis: Assessing the collected data to identify
                      vulnerabilities, risks, and compliance issues.
                    </li>
                    <li>
                      Reporting: Documenting audit findings, recommendations,
                      and action plans for improvement.
                    </li>
                    <li>
                      Follow-up: Monitoring the implementation of corrective
                      actions and verifying their effectiveness.
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsefive"
                  aria-expanded="false"
                  aria-controls="collapsefive"
                >
                  What are the benefits of conducting a System Audit?
                </button>
              </h2>
              <div
                id="collapsefive"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <p className="submenu-sub-header ">
                    Enhanced Security:
                    <span className="text">
                      {" "}
                      Identifying and addressing security vulnerabilities to
                      protect against cyber threats and data breaches.
                    </span>
                  </p>{" "}
                  <p className="submenu-sub-header ">
                    Compliance Assurance:
                    <span className="text">
                      {" "}
                      Ensuring compliance with regulations and standards,
                      reducing the risk of penalties and legal issues.
                    </span>
                  </p>{" "}
                  <p className="submenu-sub-header ">
                    Risk Mitigation:
                    <span className="text">
                      {" "}
                      Identifying and mitigating risks to the organization's IT
                      systems and data.
                    </span>
                  </p>{" "}
                  <p className="submenu-sub-header ">
                    Operational Efficiency:
                    <span className="text">
                      {" "}
                      Optimizing IT processes and controls to improve efficiency
                      and productivity.
                    </span>
                  </p>{" "}
                  <p className="submenu-sub-header ">
                    Stakeholder Confidence:
                    <span className="text">
                      {" "}
                      Demonstrating commitment to security and compliance,
                      enhancing trust with customers, partners, and regulators.
                    </span>
                  </p>{" "}
                </div>
              </div>
            </div>

            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSix"
                  aria-expanded="false"
                  aria-controls="collapseSix"
                >
                  How often should a System Audit be conducted?
                </button>
              </h2>
              <div
                id="collapseSix"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  The frequency of System Audits depends on factors such as the
                  organization's industry, regulatory requirements, and risk
                  profile. However, it is generally recommended to conduct
                  audits at least annually or whenever significant changes occur
                  in the IT environment.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Query />
    </>
  );
};

export default SystemTaxAudit;
