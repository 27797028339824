import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import company from "./images/risk_control_metric1.jpeg";
import company1 from "./images/risk_control_metric2.jpeg";
import company2 from "./images/risk_control_metric3.jpeg";
import bgImage from "../Home/Images/homepage2.jpeg";
import bgImage1 from "../Home/Images/homepage3.jpg";
import bgImage2 from "../Home/Images/homepage4.jpg";
import bgImage3 from "../Home/Images/homepage5.jpg";
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md";
import Query from "../Components/Query/Query";

const RiskControl = () => {
  const [image, setImage] = useState("");
  const [count, setCount] = useState(0);
  const scollToRef = useRef();
  useEffect(() => {
    scollToRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);
  const Imagesdata = [
    {
      images: bgImage,
      id: 1,
    },
    {
      images: bgImage1,
      id: 2,
    },
    {
      images: bgImage2,
      id: 3,
    },
    {
      images: bgImage3,
      id: 4,
    },
  ];
  useEffect(() => {
    setImage(Imagesdata[0].images);
  }, []);
  // Function is called everytime increment button is clicked
  const handleClick1 = () => {
    // Counter state is incremented
    if (count < 3) {
      setImage(Imagesdata[count + 1]?.images || bgImage);
      setCount(count + 1);
    } else {
      setCount(0);
      setImage(Imagesdata[0]?.images);
    }
  };

  // Function is called everytime decrement button is clicked
  const handleClick2 = () => {
    // Counter state is decremented
    if (count > 0) {
      setImage(Imagesdata[count - 1].images);
      setCount(count - 1);
    } else {
      setCount(3);
      setImage(Imagesdata[3].images);
    }
  };
  return (
    <>
      <section className="corporate-sec">
        <div
          className={`${"info"}`}
          style={{ backgroundImage: `url(${image})` }}
        >
          <h1 className="typo5">Risk Control Matrix (RCM)</h1>
          <div className="rightIcon">
            <div className="rightIconImageBox" onClick={handleClick1}>
              <MdArrowForwardIos color="#ffffff" />
            </div>
          </div>
          <div className="leftIcon" onClick={handleClick2}>
            <div className="leftIconImageBox">
              <MdArrowBackIos color="#ffffff" />
            </div>
          </div>
        </div>
        <div className="corporate-sec-1  ">
          <div className="corporate-img">
            <div class="hexagon">
              <img src={company} width="200" height="200" />
            </div>
            <div className="nextConatainer">
              <div class="hexagon1">
                <img src={company1} width="200" height="200" />
              </div>
            </div>
            <div class="hexagon2">
              <img src={company2} width="200" height="200" />
            </div>

            {/* <img src="./CorporateImage/img1.jpeg" alt="img" width={500} /> */}
          </div>
          <div className="info-paragraphs">
            <h3 ref={scollToRef} className="audit-header">
              Risk Control Matrix (RCM)
            </h3>
            <p className="text">
              Risk Control Matrix (RCM) is a valuable tool for organizations to
              systematically identify, assess, and manage risks, as well as
              evaluate the effectiveness of control measures in mitigating those
              risks. We will be providing a constant support system in
              developing, reviewing, and monitoring RCM based on your business
              operations for different processes.
            </p>
            <p className="text">
              Our experienced team of risk management professionals works
              closely with clients to develop customized RCMs tailored to their
              specific needs and objectives.
            </p>
            <p className="text">
              Our approach is to understand the importance of effective risk
              management in achieving organizational objectives and protecting
              value and accordinglytailorthe services to meet the unique needs
              and risk profiles of each client. We work closely with your team
              to develop RCMs that are practical, actionable, and aligned with
              your business goals.
            </p>
            <br />
            {/* <h4>
              Detailed accounting and management processes covered by us are as
              follows:
            </h4> */}
            <h3 className="submenu-header ">Why Choose PRISH ?</h3>
            <p className="submenu-sub-header ">
              Risk Identification:{" "}
              <span className="text">
                We work with you to identify and prioritize key risks facing
                your organization across various areas such as operations,
                finance, compliance, and strategic initiatives.
              </span>
            </p>

            <p className="submenu-sub-header ">
              Control Assessment:{" "}
              <span className="text">
                Our team evaluates the effectiveness of existing controls to
                mitigate identified risks and identifies gaps or weaknesses that
                may require remediation.
              </span>{" "}
            </p>
            <p className="submenu-sub-header ">
              Control Design:{" "}
              <span className="text">
                We assist in designing and implementing new controls or
                enhancements to existing controls to address identified risks
                and improve overall risk management.
              </span>
            </p>
            <p className="submenu-sub-header ">
              Monitoring and Reporting:{" "}
              <span className="text">
                We help establish monitoring mechanisms to track the
                effectiveness of controls and provide regular reporting on risk
                management activities to management and stakeholders.
              </span>
            </p>
          </div>
        </div>
        <div className="accordian-section">
          <div class="accordion" id="accordionExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  What is a Risk Control Matrix (RCM)?
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse show"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  A Risk Control Matrix (RCM) is a tool used to document the key
                  risks facing an organization and the controls in place to
                  mitigate those risks. It provides a structured framework for
                  identifying, assessing, and monitoring risks across different
                  areas of the business.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  Why is a Risk Control Matrix (RCM) important?
                </button>
              </h2>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  An RCM is important because it helps organizations identify
                  and assess risks systematically, ensure appropriate controls
                  are in place to mitigate those risks and provide assurance to
                  management and stakeholders that risks are being managed
                  effectively.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  How does an RCM help in risk management?
                </button>
              </h2>
              <div
                id="collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  An RCM helps in risk management by providing a clear overview
                  of key risks and the controls in place to mitigate them. It
                  allows organizations to prioritize risks, allocate resources
                  effectively, and monitor the effectiveness of controls over
                  time.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsefour"
                  aria-expanded="false"
                  aria-controls="collapsefour"
                >
                  How can develop a Risk Control Matrix (RCM)?
                </button>
              </h2>
              <div
                id="collapsefour"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  By identifying key risks, assess existing controls, and design
                  effective risk management strategies.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsefive"
                  aria-expanded="false"
                  aria-controls="collapsefive"
                >
                  What are the benefits of using a Risk Control Matrix (RCM)?
                </button>
              </h2>
              <div
                id="collapsefive"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <p className="submenu-sub-header ">
                    Improved risk management:
                    <span className="text">
                      {" "}
                      Gain a clear understanding of key risks and implement
                      effective controls to mitigate them.
                    </span>
                  </p>{" "}
                  <p className="submenu-sub-header ">
                    Enhanced compliance:
                    <span className="text">
                      Ensure compliance with regulatory requirements and
                      internal policies by implementing appropriate controls.
                    </span>
                  </p>{" "}
                  <p className="submenu-sub-header ">
                    Efficient resource allocation:
                    <span className="text">
                      {" "}
                      Allocate resources more effectively by focusing on
                      high-priority risks.
                    </span>
                  </p>{" "}
                  <p className="submenu-sub-header ">
                    Enhanced decision-making:
                    <span className="text">
                      {" "}
                      Make informed decisions by having timely and accurate
                      information on risks and controls.
                    </span>
                  </p>{" "}
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSix"
                  aria-expanded="false"
                  aria-controls="collapseSix"
                >
                  How often should an organization update its Risk Control
                  Matrix (RCM)?
                </button>
              </h2>
              <div
                id="collapseSix"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  An organization should update its RCM regularly to reflect
                  changes in the business environment, such as new risks,
                  changes in regulations, or updates to control measures. It is
                  typically reviewed and updated annually or as needed.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSeven"
                  aria-expanded="false"
                  aria-controls="collapseSeven"
                >
                  Can an RCM help improve operational efficiency?
                </button>
              </h2>
              <div
                id="collapseSeven"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  Yes, an RCM can help improve operational efficiency by
                  identifying areas where controls can be streamlined or
                  enhanced to reduce costs, improve productivity, and mitigate
                  risks more effectively.
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseEight"
                  aria-expanded="false"
                  aria-controls="collapseEight"
                >
                  Is an RCM only beneficial for large organizations?
                </button>
              </h2>
              <div
                id="collapseEight"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  No, an RCM can benefit organizations of all sizes. Small and
                  medium-sized enterprises (SMEs) can use an RCM to identify and
                  manage risks effectively, ensure compliance with regulations,
                  and enhance operational efficiency.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Query />
    </>
  );
};

export default RiskControl;
