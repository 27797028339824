import React, { useEffect, useRef, useState } from "react";
import ".//corporate.css";
import audit from "./Images/audit.jpg";
import audit1 from "./Images/audit1.jpg";
import bgImage from "../Home/Images/homepage2.jpeg";
import bgImage1 from "../Home/Images/homepage3.jpg";
import bgImage2 from "../Home/Images/homepage4.jpg";
import bgImage3 from "../Home/Images/homepage5.jpg";
import { MdArrowForwardIos, MdArrowBackIos } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Query from "../Components/Query/Query";

function Corporate() {
  const [image, setImage] = useState("");
  const [count, setCount] = useState(0);
  const [isVisible, setIsVisible] = useState(false);

  const scollToRef = useRef();
  // const textToRef = useRef();
  useEffect(() => {
    scollToRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);

  // useEffect(() => {
  //   const observer = new IntersectionObserver(
  //     ([entry]) => {
  //       if (entry.isIntersecting) {
  //         setIsVisible(true);
  //         observer.unobserve(textToRef.current);
  //       }
  //     },
  //     {
  //       threshold: 0.5, // Adjust as needed
  //     }
  //   );

  //   if (textToRef.current) {
  //     observer.observe(textToRef.current);
  //   }

  //   return () => {
  //     if (textToRef.current) {
  //       observer.unobserve(textToRef.current);
  //     }
  //   };
  // }, []);
  const navigate = useNavigate();

  const Imagesdata = [
    {
      images: bgImage,
      id: 1,
    },
    {
      images: bgImage1,
      id: 2,
    },
    {
      images: bgImage2,
      id: 3,
    },
    {
      images: bgImage3,
      id: 4,
    },
  ];
  useEffect(() => {
    setImage(Imagesdata[0].images);
  }, []);
  // Function is called everytime increment button is clicked
  const handleClick1 = () => {
    // Counter state is incremented
    if (count < 3) {
      setImage(Imagesdata[count + 1]?.images || bgImage);
      setCount(count + 1);
    } else {
      setCount(0);
      setImage(Imagesdata[0]?.images);
    }
  };

  // Function is called everytime decrement button is clicked
  const handleClick2 = () => {
    // Counter state is decremented
    if (count > 0) {
      setImage(Imagesdata[count - 1].images);
      setCount(count - 1);
    } else {
      setCount(3);
      setImage(Imagesdata[3].images);
    }
  };
  return (
    <>
      <section className="corporate-sec">
        <div
          className={`${"info"}`}
          style={{ backgroundImage: `url(${image})` }}
        >
          <h1 className="typo5">Auditing & Assurance Services</h1>
          <div className="rightIcon">
            <div className="rightIconImageBox" onClick={handleClick1}>
              <MdArrowForwardIos color="#ffffff" />
            </div>
          </div>
          <div className="leftIcon" onClick={handleClick2}>
            <div className="leftIconImageBox">
              <MdArrowBackIos color="#ffffff" />
            </div>
          </div>
        </div>
        <div className="corporate-sec-1  ">
          <div
            className={`corporate-img ${isVisible ? "visible" : ""}`}
            // ref={textToRef}
          >
            <div class="hexagon">
              <img src="./CorporateImage/img1.jpeg" width="200" height="200" />
            </div>
            <div className="nextConatainer">
              <div class="hexagon1">
                <img src={audit} width="200" height="200" />
              </div>
            </div>
            <div class="hexagon2">
              <img src={audit1} width="200" height="200" />
            </div>

            {/* <img src="./CorporateImage/img1.jpeg" alt="img" width={500} /> */}
          </div>
          <div
            // ref={textToRef}
            className={`info-paragraphs ${isVisible ? "visible" : ""}`}

            // className="info-paragraphs"
          >
            <h3
              ref={scollToRef}
              // className={`audit-header-${isVisible ? "visible" : ""}`}
              className="audit-header"
            >
              AUDITING AND ASSURANCE
            </h3>
            <p className="text">
              We at <b>PRISH</b> have our core competence in the field of Audit
              and Assurance. Each of our audit assignments is customized as per
              audit statutory requirements governed by IFRS/ IAS. We ensure
              efficient and dedicated audit planning, execution and reporting
              supervised and reviewed carefully by our experts.
            </p>
            <br />
            <h4
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/satutory-audit")}
            >
              Statutory Audit
            </h4>
            <p className="text">
              We will be performing a thorough examination of financial records
              and providing assurance on the accuracy and reliability of your
              company’s financial statements.
              <button
                onClick={() => navigate("/satutory-audit")}
                className="read-more-btn-1"
              >
                Read more
              </button>
            </p>
            <br />
            <h4
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/concurrent-audit")}
            >
              Concurrent Audit
            </h4>
            <p className="text">
              It would be a real-time examination of financial transactions and
              processes conducted by us as an internal or external auditor to
              provide you with the timely feedback and ensure the effectiveness
              of internal controls and financial records.
              <button
                onClick={() => navigate("/concurrent-audit")}
                className="read-more-btn-1"
              >
                Read more
              </button>
            </p>

            {/*  */}
            <h4
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/forensic-audit")}
            >
              Forensic Audit
            </h4>
            <p className="text">
              It will be a specialized examination of financial records,
              transactions, and documents conducted with the objective of
              identifying uncovering fraud, financial misconduct, or
              irregularities through gathering evidence, analyzing data, and
              identifying perpetrators of fraudulent activities.
              <button
                onClick={() => navigate("/forensic-audit")}
                className="read-more-btn-1"
              >
                Read more
              </button>
            </p>

            <h4
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/inventory-audit")}
            >
              Inventory audit and verification
            </h4>
            <p className="text">
              Confirming the existence, completeness, and accuracy of inventory
              records and to assess the effectiveness of internal controls over
              inventory management process and suggesting the process
              improvement measures.
              <button
                onClick={() => navigate("/inventory-audit")}
                className="read-more-btn-1"
              >
                Read more
              </button>
            </p>

            <h4
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/mystery-audit")}
            >
              Mystery Audit
            </h4>
            <p className="text">
              Core assessments conducted by us as an independent evaluator to
              measure and evaluate the quality of service, compliance with
              policies and procedures, and overall customer experience with your
              company’s brand.
              <button
                onClick={() => navigate("/mystery-audit")}
                className="read-more-btn-1"
              >
                Read more
              </button>
            </p>
          </div>
        </div>
      </section>
      <Query />
    </>
  );
}

export default Corporate;
